import React from 'react';
import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import { NetworkProvider } from './NetworkContext';
import 'bootstrap/dist/css/bootstrap.min.css';
import './i18n';

const root = createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <NetworkProvider>
            <App />
        </NetworkProvider>
    </React.StrictMode>
);


registerServiceWorker();
