//import logo from "../logo-farmwise.jpg";
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';


export function HomePage() {
    const { t } = useTranslation();

    function ButtonLink({ to, children }) {
        return <Link to={to}><button className="start-survey-btn">{children}</button></Link>;
    }
    return (
        <div className="container">
            <div className="col-lg-9 jumbotron" style={{alignSelf: "center"}}>
                <h2>{t('welcome_h2')}</h2>
                <p>{t('welcome_p1')}</p>
                <p>{t('welcome_p2')}<a href="https://www.farmwise-project.eu/" target="_blank">{t('welcome_p2_1')}</a></p>
                <p>{t('welcome_p3')}</p>
                <p>{t('welcome_p4')}</p>
                <p>{t('welcome_p5')}</p>
                <p>{t('welcome_p6')}</p>
                <div className="signature">{t('signature')}</div>
            </div>
            <div className="start-survey">
                <ButtonLink className="start-survey-btn" to="/survey">{t('startSurvey')}</ButtonLink>
            </div>
            <div className="credits">
                <p>ICESI, “Interdisciplinary Center for Digital Transformation - CITRADI” in Colombia</p>
                <p>INGENIO (CSIC-UPV), Universitat Politècnica de València, Spain</p>
                <p>Darmstadt University of Applied Sciences, Germany</p>
            </div>
        </div>
    );
}
