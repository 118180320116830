import React from "react";
import {useNetworkContext} from '../NetworkContext';
import { useTranslation } from 'react-i18next';


const InfoBox = () => {
    const { selectedNode } = useNetworkContext();
    const { t } = useTranslation();

    return (
        <div className="infobox-container">
            {selectedNode && (
                <>
                    <h2>{t('stakeholderinfo')}</h2>
                    <div className="network--infobox--container">
                        <p><strong>{t('name')}</strong></p>
                        <p>{t(selectedNode.label)}</p>
                        <p><strong>{t('sector')}</strong></p>
                        <p>{t(`sectors.${selectedNode.sector}`)}</p>
                        <p><strong>{t('activity')}</strong></p>
                        <p>{t(`activities.${selectedNode.mainActivity}`)}</p>
                        <p></p>
                        <p>{selectedNode.activities ? t(`activities.${selectedNode.activities}`) : ""}</p>
                        <p><strong>{t('country')}, {t('region')}</strong></p>
                        <p>{selectedNode.country}, {selectedNode.region}</p>
                        <p><strong>{t('numEmp')}</strong></p>
                        <p> {selectedNode.size}</p>
                        <p><strong>{t('years')}</strong></p>
                        <p> {selectedNode.years}</p>
                        <p><strong>{t('website')}</strong></p>
                        <p> {selectedNode.website}</p>
                    </div>
                </>
            )}
        </div>
    )
}

export default InfoBox;