import React, { useState } from 'react';
import {useNetworkContext} from '../NetworkContext';
import { Accordion, Card, Button, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';


const FilterLegend = () =>
{
    const {selectedNodes, selectedSectors, setSelectedSectors, sectorCategories, sectorColors, connectionCategories, valueCategories, toggleCategory} = useNetworkContext();
    const [visibleCategories, setVisibleCategories] = useState(new Set());



    const highlightConnectionCategory =(category) =>{
        return null;
    }
    const highlightValueCategory =(category) =>{
        return null;
    }

    const { t } = useTranslation();

    //TODO!!!
    /*
    const toggleCategory = (category) => {
        setVisibleCategories(prev => {
            const newCategories = new Set(prev);
            if (newCategories.has(category)) {
                newCategories.delete(category); // Hide category
            } else {
                newCategories.add(category); // Show category
            }
            return newCategories;
        });
    };

    Filter nodes based on the selected categories
    const filteredNodes = nodes.filter(node =>
        visibleCategories.size === 0 || visibleCategories.has(node.category)
    );*/
    return (
        <div>
            <div className="container text-left">
                <div className="row">
                    <div className="col-sm-7"><h5>{t('legend')}</h5></div>
                    <div className="col-sm-1"><h5>{t('filter')}</h5></div>
                </div>
                <div className="row">
                    <div>
                        <h5>{t('actor')}</h5>
                    </div>
                </div>
                {sectorCategories.map((category) => (
                    <div className="row" key={category}>
                        <div className="col-sm-1">
                            <span className="color-box" style={{backgroundColor: sectorColors[category]}}></span>
                        </div>
                        <div className="col-sm-6">{t(`sectors.${category}`)}</div>
                        <div className="col-sm-2">
                            <input className="checkBx" type="checkbox" onChange={() => toggleCategory(category)}/>
                        </div>
                    </div>
                ))}

                <div className="row spaceHolder"></div>

                <div className="row">
                    <div className="col-sm-1">
                        <span className="size-icon"></span>
                    </div>
                    <div className="col-sm-6">{t('numEmp')}</div>
                </div>

                <div className="row spaceHolder"></div>

                <div className="row">
                    <div>
                        <h5>{t('connections')}</h5>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-1 formalIcon"></div>
                    <div className="col-sm-6">{t('formal')}</div>
                </div>
                <div className="row">
                    <div className="col-sm-1 informalIcon"></div>
                    <div className="col-sm-6">{t('informal')}</div>
                </div>

                <div className="row spaceHolder"></div>

            </div>
        </div>
    );

}
export default FilterLegend;





/*

<div className="row">
                    <div className="col-sm-7"><h5>Highlight</h5></div>
                </div>
                
<Accordion defaultActiveKey="">
    <Accordion.Item eventKey="0">
        <Accordion.Header>Connections</Accordion.Header>
        <Accordion.Body>
            {connectionCategories.map((category) => (
                <div className="row" key={category}> {}
                    <div className="col-sm-11">{category}</div>
                    <div className="col-sm-1">
                        <input className="checkBx" type="checkbox"
                               onChange={() => highlightConnectionCategory(category)}/>
                    </div>
                </div>
            ))}
        </Accordion.Body>
    </Accordion.Item>
    <Accordion.Item eventKey="1">
        <Accordion.Header>Values</Accordion.Header>
        <Accordion.Body>
            {valueCategories.map((category) => (
                <div className="row" key={category}> {}
                    <div className="col-sm-11">{category}</div>
                    <div className="col-sm-1">
                        <input className="checkBx" type="checkbox"
                               onChange={() => highlightValueCategory(category)}/>
                    </div>
                </div>
            ))}
        </Accordion.Body>
    </Accordion.Item>
</Accordion>
*/