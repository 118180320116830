import React from 'react';
import { useTranslation } from 'react-i18next';

//TODO:  add other languages

const LanguageSwitcher = () => {
    const { i18n } = useTranslation();

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };

    return (
        <div className="language-dropdown">
            <select className="form-select" onChange={(e) => changeLanguage(e.target.value)} defaultValue="en">
                <option value="en">English</option>
                <option value="fi">Suomi</option>
                <option value="it">Italiano</option>
                <option value="nl">Nederlands</option>
                <option value="pl">Polski</option>
                <option value="es">Español</option>
                <option value="sw">Svensk</option>
                <option value="ua">Українська</option>
                {/* Add more languages as needed */}
            </select>
        </div>
    )
        ;
};

export default LanguageSwitcher;