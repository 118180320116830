import React, { useState, useEffect, useRef } from 'react';
import { useNetworkContext } from '../NetworkContext';
import * as d3 from 'd3';
import { useTranslation } from 'react-i18next';


const NodeLinkGraph = ({nodes, edges, colors, coloringAttrib}) => {
    const svgRef = useRef();

    const {setSelectedNode, selectedNodes, selectedSectors, sectorCategories, selectedEdges} = useNetworkContext();
    const [averageDistance, setAverageDistance] = useState(0);
    const [density, setDensity] = useState(0);
    const [betweeners, setBetweeners] = useState(0);


    const { t } = useTranslation();

    useEffect(() => {

        const filteredNodes = selectedSectors && selectedSectors.size > 0
            ? nodes.filter(node => selectedSectors.has(node.sector))
            : selectedNodes;

        const filteredEdges = selectedSectors && selectedSectors.size > 0
            ? edges.filter(edge => filteredNodes.some(node => node.id === edge.source.id) &&
                filteredNodes.some(node => node.id === edge.target.id)) : edges;

        calculateGraphMetrics(filteredNodes, filteredEdges);
        calculateAverageDistance(filteredNodes,filteredEdges);
        //calculateBetweennessCentrality(filteredNodes, filteredEdges);


        const width = window.innerWidth * 0.5
        const height = window.innerHeight * 0.5



        const svg = d3.select(svgRef.current)
            //.attr('width', width)
            //.attr('height', height);
            .attr("viewBox", "0 0 " + width + " " + height )
            .attr("preserveAspectRatio", "xMidYMid meet");


        svg.selectAll("*").remove();


        const simulation = d3.forceSimulation(filteredNodes)
            .force('link', d3.forceLink().id(d => d.id).distance(100))
            .force('charge', d3.forceManyBody().strength(-300))
            .force('center', d3.forceCenter(width / 2, height / 2));

        const link = svg.append('g')
            .attr('class', 'links')
            .selectAll('line')
            .data(filteredEdges)
            .enter().append('line')
            .attr('width', 20)
            .attr('stroke', '#999')
            .attr("stroke-width", 3)
            .attr('stroke-dasharray', d => d.formal ? 0 : 7)
        //.on("click", (event, d) => handleEdgeClick(d));

        const linkText = svg.selectAll(".link-text")
            .data(filteredEdges)
            .enter().append("text")
            .attr("class", "link-text")
            .attr("dy", -5)
            .attr("text-anchor", "middle")
            .attr("fill", "grey")
            .attr("background-color", "red")
            .style("display", "none")
            .text(d => t(`collaborations.${d.label}`));

        const node = svg.append('g')
            .attr('class', 'nodes')
            .selectAll('circle')
            .data(filteredNodes)
            .enter().append('circle')
            .attr('r', d => d.size * 3) // Set node size based on data
            .attr('fill', d => colors[d[coloringAttrib]]) // Set node color based on data
            .attr('stroke', d => "blue")
            .attr('stroke-width', d => betweeners[d.id] ? 2 : 0)
            .on("click", (event, d) => handleNodeClick(d))
            .call(d3.drag()
                .on('start', dragstarted)
                .on('drag', dragged)
                .on('end', dragended));


        const labels = svg.append("g")
            .attr("class", "labels")
            .selectAll("text")
            .data(filteredNodes)
            .enter().append("text")
            .attr("dy", -15) // Position the label above the node
            .attr("text-anchor", "middle")
            .text(d => d.label); // Display the node's name


        simulation
            .nodes(filteredNodes)
            .on('tick', ticked);

        simulation.force('link')
            .links(filteredEdges);

        function ticked() {
            link
                .attr('x1', d => d.source.x)
                .attr('y1', d => d.source.y)
                .attr('x2', d => d.target.x)
                .attr('y2', d => d.target.y);

            node
                .attr('cx', d => d.x)
                .attr('cy', d => d.y)

            labels
                .attr("x", d => d.x)
                .attr("y", d => d.y);

            linkText.attr("x", d => (d.source.x + d.target.x) / 2)
                .attr("y", d => (d.source.y + d.target.y) / 2);

        }



        function dragstarted(event, d) {
            if (!event.active) simulation.alphaTarget(0.3).restart();
            d.fx = d.x;
            d.fy = d.y;
        }

        function dragged(event, d) {
            d.fx = event.x;
            d.fy = event.y;
        }

        function dragended(event, d) {
            if (!event.active) simulation.alphaTarget(0);
            d.fx = null;
            d.fy = null;
        }

        function handleNodeClick(nodeData) {
            setSelectedNode(nodeData);
        }

        link.on("mouseover", function (event, d) {
            const label = linkText.filter(link => link === d);
            label.style("display", "block");

            setTimeout(() => {
                label.style("display", "none");
            }, 1000);
        });

        return () => {
            svg.selectAll("*").remove();
        };
    }, [selectedNodes, selectedEdges, selectedSectors]);

    function calculateBetweennessCentrality(nodes, edges) {
        const centrality = {};

        // Initialize centrality for each node
        nodes.forEach(node => {
            centrality[node.id] = 0;
        });

        // Function to perform BFS and find shortest paths
        function bfs(source) {
            const queue = [source.id];
            const paths = {};
            const sigma = {};
            const predecessors = {};
            const visited = new Set();

            visited.add(source.id);
            sigma[source.id] = 1;
            paths[source.id] = [];

            while (queue.length > 0) {
                const current = queue.shift();
                const neighbors = edges
                    .filter(edge => edge.source.id === current || edge.target.id === current)
                    .map(edge => edge.source.id === current ? edge.target.id : edge.source.id);

                neighbors.forEach(neighbor => {
                    if (!visited.has(neighbor)) {
                        visited.add(neighbor);
                        queue.push(neighbor);
                    }
                    if (!paths[neighbor]) {
                        paths[neighbor] = [];
                    }
                    paths[neighbor].push(current);

                    if (!sigma[neighbor]) {
                        sigma[neighbor] = 0;
                    }
                    sigma[neighbor] += sigma[current];
                });
            }

            return { paths, sigma };
        }

        nodes.forEach(source => {
            const { paths, sigma } = bfs(source);
            const dependency = {};

            nodes.forEach(node => {
                dependency[node.id] = 0;
            });

            for (let target in paths) {
                if (target !== source.id) {
                    paths[target].forEach(predecessor => {
                        const coeff = (sigma[predecessor] / sigma[target]) * (1 + dependency[target]);
                        dependency[predecessor] += coeff;
                    });
                    if (target !== source.id) {
                        centrality[target] += dependency[target];
                    }
                }
            }
        });
        setBetweeners(centrality)
    }


    const calculateGraphMetrics = (nodes, edges) => {
        const numNodes = nodes.length;
        const numEdges = edges.length;
        const densityValue = numNodes > 1 ? (2 * numEdges) / (numNodes * (numNodes - 1)) : 0;
        setDensity(densityValue);
    }
    function calculateAverageDistance(nodes, filteredEdges) {
        const distances = {};

        nodes.forEach(node => {
            distances[node.id] = {};
            nodes.forEach(otherNode => {
                distances[node.id][otherNode.id] = Infinity; // Start with infinite distance
            });
            distances[node.id][node.id] = 0; // Distance to itself is zero
        });

        const adjacencyList = {};
        edges.forEach(edge => {
            if (!adjacencyList[edge.source.id]) {
                adjacencyList[edge.source.id] = [];
            }
            if (!adjacencyList[edge.target.id]) {
                adjacencyList[edge.target.id] = [];
            }
            adjacencyList[edge.source.id].push(edge.target.id);
            adjacencyList[edge.target.id].push(edge.source.id); // For undirected graph
        });

        function bfs(startNode) {
            const queue = [startNode];
            const visited = new Set();
            visited.add(startNode);

            while (queue.length > 0) {
                const current = queue.shift();
                const neighbors = adjacencyList[current] || [];

                neighbors.forEach(neighbor => {
                    if (!visited.has(neighbor)) {
                        visited.add(neighbor);
                        queue.push(neighbor);
                        distances[startNode][neighbor] = distances[startNode][current] + 1;
                    }
                });
            }
        }

        nodes.forEach(node => {
            bfs(node.id);
        });

        let totalDistance = 0;
        let count = 0;

        nodes.forEach(node => {
            nodes.forEach(otherNode => {
                if (distances[node.id][otherNode.id] < Infinity && node.id !== otherNode.id) {
                    totalDistance += distances[node.id][otherNode.id];
                    count++;
                }
            });
        });

        const distanceValue = count > 0 ? totalDistance / count : 0; // Return average distance
        setAverageDistance(distanceValue);

    }


    return(
        <div>
            <svg ref={svgRef}></svg>
            <div className="network--metrics">
                <p>{t('density')}: {density.toFixed(2)}</p>
                <p>{t('avDistance')}: {averageDistance.toFixed(2)}</p>
            </div>
        </div>
    )
};

export default NodeLinkGraph;
