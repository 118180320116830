import React, {createContext, useContext, useEffect, useState} from 'react';
import data from './data/stakeholderData'

const NetworkContext = createContext();


export const NetworkProvider = ({ children }) => {

    const defaultdata = data.find(entry => entry.id === 1);

    const sectorCategories = ['academia', 'civilSociety', 'publicSector', 'privateSector', 'media', 'nonOrg'];

    const connectionCategories = ['Joint Project Development', 'Public Policy Design',
        'Human Resource Mobilization', 'Economic Exchange', 'Shared Infrastructure',
        'Collaborative Innovation', 'Technology Transfer',	'Research Consortia',
        'Strategic Partnerships', 'Knowledge Exchange (bidirectional)', 'Knowledge Transfer (unidirectional)',
        'Sharing spaces', 'Community Engagement Initiatives', 'Sharing Advice', 'Lobbying', 'Personal Relations'];

    const valueCategories = ['Self Assessment', 'Region Assessment', 'Technology Assessment']

    const valueDescriptions = [ 'I consider that I carry out and promote agriculture and water management that protect and enhance local ecosystems.',
                                        'I consider that the region promotes agriculture and water management that protects and enhances local ecosystems.',
                                        'I believe that technology plays a crucial role in my region`s ability to overcome the challenges posed by climate change'
    ]

    const sectorColors = {
        'civilSociety': '#C3D69B',
        'academia': '#CA6FB0',
        'privateSector': '#FAC090',
        'publicSector': '#95B3FC',
        'media': '#93CDDD',
        'nonOrg': '#EFD610',

    };

    const [selectedCaseStudy, setSelectedCaseStudy] = useState(null);
    const [selectedNodes, setSelectedNodes] = useState(defaultdata.nodes);
    const [filteredNodes, setFilteredNodes] = useState(null);
    const [selectedSectors, setSelectedSectors] = useState(null);
    const [selectedEdges, setSelectedEdges] = useState(defaultdata.edges);
    const [selectedNode, setSelectedNode] = useState(null);
    const [selectedEdge, setSelectedEdge] = useState(null);



    const toggleCategory = (category) => {
        setSelectedSectors(prev => {
            const newCategories = new Set(prev);
            if (newCategories.has(category)) {
                newCategories.delete(category); // Hide category
            } else {
                newCategories.add(category); // Show category
            }
            return newCategories;
        });
    };

    useEffect(() => {
        if(selectedSectors){
            const s = Array.from(selectedSectors);
            const filtered = selectedNodes.filter(node =>
                s.includes(node.sector)
            );
            setFilteredNodes(filtered);
        }
    }, [selectedSectors]);


    /*
    const filterNodes = (categories, nodes) => {
        const categoryArray = Array.isArray(categories) ? categories : Array.from(categories);
        const filtered = nodes.filter(node => categoryArray.includes(node.category));
        setFilteredNodes(filtered);
        console.log("Filtered Nodes Length: ", filtered.length);
        console.log("SelectedSectors in Provider: ", categories);
        console.log("Filtered Nodes Length: ", filteredNodes.length);
    }*/


    return (
        <NetworkContext.Provider value={{
            sectorCategories,
            sectorColors,
            connectionCategories,
            valueCategories,
            selectedNode,
            setSelectedNode,
            selectedSectors,
            setSelectedSectors,
            filteredNodes,
            selectedEdge,
            setSelectedEdge,
            selectedNodes,
            setSelectedNodes,
            selectedEdges,
            setSelectedEdges,
            selectedCaseStudy,
            setSelectedCaseStudy,
            toggleCategory}}>
            {children}
        </NetworkContext.Provider>
    );
};

export const useNetworkContext = () => {
    const context = useContext(NetworkContext);
    if (!context) {
        throw new Error('useNodeContext must be used within a NodeProvider');
    }
    return context;
};