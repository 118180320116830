import React, { useState, useEffect} from 'react';
import {useNetworkContext} from "../NetworkContext";

const Dropdown = ({ onSelect }) => {
    const { selectedCaseStudy, setSelectedCaseStudy, setSelectedNode } = useNetworkContext();

    const options = [
        { value: '1', label: 'Sample Data' },
        { value: '2', label: 'Sample Data 2' },
    ];

    const handleChange = (event) => {
        setSelectedCaseStudy(event.target.value);
        setSelectedNode(null);
    };

    return (
        <div className="dropdown">
            <select
                id="dropdownSelect"
                value={(selectedCaseStudy)?selectedCaseStudy:" "}
                onChange={handleChange}
                className="form-select"
            >
                {options.map(option => (
                    <option key={option.value} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </select>
        </div>
    );
};

export default Dropdown;
