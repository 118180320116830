import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';


//TODO: translate sectors[], activities[], connectionTypes[]
// think about how to map them everywhere (also in selected Node etc.)
// Use different solution to translate countries[], regions[]
i18n
    .use(initReactI18next)
    .init({
        resources: {
            en: {
                translation: {
                    welcome_h2: "FARMWISE stakeholders survey",
                    welcome_p1: "If you are receiving this survey it is because you are considered an important actor in your region’s water and agri-food system.",
                    welcome_p2: 'This survey is part of the FARMWISE project (Future Agricultural Resource Management and Water Innovations for a Sustainable Europe), funded by the EU’s Horizon Europe programme. The project aims to enhance resilience in water systems across eight European case studies. For more information, visit ',
                    welcome_p2_1: 'the official website.', 
                    welcome_p3: "Your responses will help create a “map” (a detailed representation) of the key actors and their interactions in each region where FARMWISE operates.",
                    welcome_p4: "Once these maps are developed, they will be shared with all participating organizations. This will allow you to see where your organization is “located” within the agri-food and water ecosystem of your region.",
                    welcome_p5: "We believe these maps will strategically benefit both your organization and the broader ecosystem by improving understanding and supporting better decision-making. Our ultimate goal is to enhance water management and strengthen resilience to the impacts of climate change.",
                    welcome_p6: "Thank you very much for your participation,",
                    signature: "The FARMWISE team", 
                    startSurvey: "start survey",
                    home: "Home",
                    survey: "Survey",
                    network: "Network",
                    admin: "Admin",
                    stakeholderinfo: "Stakeholder Information",
                    name: "Name",
                    sector : "Sector",
                    sectors: {
                        privateSector: "Private sector",
                        publicSector: "Public sector",
                        academia: "Academia",
                        civilSociety: "Civil society",
                        media: "Media",
                        nonOrg: "Non-organized Civil Society"
                    },
                    activity : "Activity",
                    activities : {
                        activism: "Activism",
                        cropProduction: "Crop Production",
                        livestockFarming: "Livestock Farming",
                        research: "Research",
                        communication: "Communication",
                        resourceManagement: "Resource Management",
                        techDev: "Technology Development and Application",
                        foodProcessing: "Food Processing",
                        foodSafety: "Food Safety and Quality Control",
                        education: "Education and Training",
                        policyDev: "Policy Development",
                        communityOutreach: "Community Outreach",
                        economicActivities: "Economic Activities",
                        innovation: "Innovation Hub",
                        monitoring: "Environmental Monitoring",
                        infrastructure: "Infrastructure Maintenance and Development",
                        internationalCoop: "International Cooperation"
                    },
                    country: "Country",
                    region: "Region",
                    numEmp: "Number of employees",
                    years: "Years of activity",
                    website: "Website",
                    legend: "Legend",
                    filter: "Filter",
                    actor: "Actor",
                    size: "Size",
                    formal: "formal",
                    informal: "informal",
                    connections: "Collaboration",
                    collaborations: {
                        jointProjectDev: "Joint Project Development",
                        publicPolicyDesign: "Public Policy Design",
                        humanResourceMob: "Human Resource Mobilization",
                        sharedInfrastructure: "Shared Infrastructure",
                        collaborationInoovation: "Collaboration for Innovation",
                        technologyExchange: "Technology Exchange (bidirectional)",
                        technologyTransfer: "Technology Transfer (unidirectional)",
                        researchConsortia: "Research Consortia",
                        strategicPartnership: "Strategic Partnership",
                        knowledgeExchange: "Knowledge Exchange (bidirectional)",
                        knowledgeTransfer: "Knowledge Transfer (unidirectional)",
                        sharingSpaces: "Sharing spaces (e.g: Innovation Platforms, Councils…)",
                        communityEngagement: "Community Engagement Initiatives",
                        sharingAdvice: "Sharing Advice",
                        lobbying: "Lobbying (Lobbying is the act of attempting to influence the decisions of government officials in favor of a particular cause, policy, or interest) ",
                        personalRelations: "Personal Relations"
                    },
                    density: "Network density",
                    avDistance: "Average distance",
                    sampleData: "This is a simplified Stakeholder Network with Sample Data.",
                    completedSuccess: "Your survey has been submitted successfully!"
                }
            },
            fi: {
                translation: {
                    welcome_h2: "FARMWISE-sidosryhmäkysely",
                    welcome_p1: "Jos saat tämän kyselyn, se johtuu siitä, että pidetään tärkeänä toimijana alueesi vesi- ja maatalouselintarvikejärjestelmässä.",
                    welcome_p2: 'Tämä kysely on osa FARMWISE-projektia (Future Agricultural Resource Management and Water Innovations for a Sustainable Europe), rahoitetaan EU:n Horizon Europe -ohjelmasta. Hankkeen tavoitteena on parantaa vesijärjestelmien kestävyyttä kahdeksassa eurooppalaisessa tapaustutkimuksessa. Lisätietoja löytyy ',
                    welcome_p2_1: 'viralliselta verkkosivustolta.', 
                    welcome_p3: "Vastauksesi auttavat luomaan “kartan” (yksityiskohtainen esitys) tärkeimmistä toimijoista ja heidän vuorovaikutuksistaan kullakin alueella, jolla FARMWISE toimii.",
                    welcome_p4: "Kun nämä kartat on kehitetty, ne jaetaan kaikkien osallistuvien organisaatioiden kanssa. Näin voit nähdä, missä organisaatiosi “sijaitsee” alueesi maatalous- ja vesiekosysteemissä.",
                    welcome_p5: "Uskomme, että nämä kartat hyödyttävät strategisesti sekä organisaatiotasi että laajempaa ekosysteemiä parantamalla ymmärrystä ja tukemalla parempaa päätöksentekoa. Perimmäisenä tavoitteemme on parantaa vesihuoltoa ja vahvistaa vastustuskykyä ilmastonmuutoksen vaikutuksille.",
                    welcome_p6: "Kiitos osallistumisestasi.",
                    signature: "FARMWISE-tiimi", 
                    startSurvey: "aloita kysely",
                    home: "Aloitusnäyttö",
                    survey: "Kyselyn",
                    network: "Kartan",
                    admin: "Admin",
                    stakeholderinfo: "Sidosryhmiä",
                    name: "Organisaatiosi nimi",
                    sector: "Toimiala",
                    sectors: {
                        privateSector: "Yksityinen sektori",
                        publicSector: "Julkinen sektori",
                        academia: "Akateeminen maailma",
                        civilSociety: "Kansalaisyhteiskunta",
                        media: "Media",
                        nonOrg: "Järjestämätön kansalaisyhteiskunta"
                    },
                    activity: "Aktivitäten",
                    activities : {
                        activism: "Aktivismi",
                        cropProduction: "Kasvintuotanto",
                        livestockFarming: "Karjankasvatus",
                        research: "Tutkimus",
                        communication: "Viestintä",
                        resourceManagement: "Resurssienhallinta",
                        techDev: "Teknologian kehittäminen ja soveltaminen",
                        foodProcessing: "Elintarvikkeiden jalostus",
                        foodSafety: "Elintarvikkeiden turvallisuus ja laadunvalvonta",
                        education: "Koulutus ja koulutus",
                        policyDev: "Politiikan kehittäminen",
                        communityOutreach: "Yhteisön toiminta",
                        economicActivities: "Taloudellinen toiminta",
                        innovation: "Innovaatiokeskus",
                        monitoring: "Ympäristön seuranta",
                        infrastructure: "Infrastruktuurin ylläpito ja kehittäminen",
                        internationalCoop: "Kansainvälinen yhteistyö"
                    },
                    country: "Maa",
                    region: "Alue",
                    numEmp: "Organisaationi työntekijöiden lukumäärä",
                    years: "Yksiön toimintavuodet",
                    q5: {
                        knowledgeCreation: "Tietämyksen luomisen ja käytön uudelleenajattelu",
                        networkBehavior: "Verkoston käyttäytymiseen vaikuttaminen",
                        changingStructure: "Verkoston rakenteen muuttamine",
                        culturalValues: "TODO"
                    },
                    a5: {
                        stronglyDisagree: "jyrkästi eri mieltä",
                        stronglyAgree: "vahvasti samaa mieltä"
                    },
                    website: "Verkkosivusto",
                    legend: "Legenda",
                    actor: "Sidosryhmiä",
                    size: "Koko",
                    formal: "muodollinen",
                    informal: "epävirallinen",
                    connections: "Yhteistyö",
                    collaborations: {
                        jointProjectDev: "Yhteinen projektikehitys",
                        publicPolicyDesign: "Julkisen politiikan suunnittelu",
                        humanResourceMob: "Henkilöstöresurssien mobilisointi",
                        sharedInfrastructure: "Jaettu infrastruktuuri",
                        collaborationInoovation: "Yhteistyötä innovaatioiden puolesta",
                        technologyExchange: "Teknologian vaihto (kaksisuuntainen)",
                        technologyTransfer: "Teknologian siirto (yksisuuntainen)",
                        researchConsortia: "Tutkimuskonsortiot",
                        strategicPartnership: "Strategiset kumppanuudet",
                        knowledgeExchange: "Tiedonvaihto (kaksisuuntainen)",
                        knowledgeTransfer: "Tietämyksen siirto (yksisuuntainen)",
                        sharingSpaces: "Jaetut tilat (esim. innovaatioalustat, neuvostot…)",
                        communityEngagement: "Yhteisön sitoutumista edistävät aloitteet",
                        sharingAdvice: "Jakaminen neuvoja",
                        lobbying: "Lobbaus (Lobbaus on yritystä vaikuttaa valtion virkamiesten päätöksiin tietyn asian, politiikan tai edun hyväksi)",
                        personalRelations: "Henkilökohtaiset suhteet"
                    },
                    density: "Verkon tiheys",
                    avDistance: "Keskimääräinen etäisyys",
                    sampleData: "Tämä verkko esittelee esimerkkitietoja.",
                    completedSuccess: "Kyselysi on lähetetty onnistuneesti!"
                }
            },
            it: {
                translation: {
                    welcome_h2: "Sondaggio degli stakeholder FARMWISE",
                    welcome_p1: "Se stai ricevendo questo sondaggio è perché sei considerato un attore importante nel sistema idrico e agroalimentare della tua regione.",
                    welcome_p2: "Questo sondaggio fa parte del progetto FARMWISE (Future Agricultural Resource Management and Water Innovations for a Sustainable Europe), finanziato dal programma Horizon Europe dell'UE. Il progetto mira a migliorare la resilienza nei sistemi idrici attraverso otto casi di studio europei. Per maggiori informazioni, visita ",
                    welcome_p2_1: 'il sito ufficiale.', 
                    welcome_p3: "Le tue risposte contribuiranno a creare una “mappa” (una rappresentazione dettagliata) degli attori chiave e delle loro interazioni in ogni regione in cui opera FARMWISE.",
                    welcome_p4: "Una volta sviluppate, queste mappe saranno condivise con tutte le organizzazioni partecipanti. Ciò ti consentirà di vedere dove si trova la tua organizzazione. all'interno dell'ecosistema agroalimentare e idrico della tua regione.",
                    welcome_p5: "Riteniamo che queste mappe apporteranno vantaggi strategici sia alla tua organizzazione che all'ecosistema più ampio, migliorando la comprensione e supportando un processo decisionale migliore. Il nostro obiettivo finale è migliorare la gestione delle risorse idriche e rafforzare la resilienza agli impatti del cambiamento climatico.",
                    welcome_p6: "Grazie mille per la vostra partecipazione,",
                    signature: "Il team FARMWISE", 
                    startSurvey: "avviare il sondaggio",
                    home: "Benvenuto",
                    survey: "Sondaggio",
                    network: "Rete",
                    admin: "Admin",
                    stakeholderinfo: "Stakeholder",
                    name: "Nome",
                    sector : "Settore",
                    sectors: {
                        privateSector: "Settore privato",
                        publicSector: "Settore pubblico",
                        academia: "Accademia",
                        civilSociety: "Società civile",
                        media: "Media",
                        nonOrg: "Società civile non organizzata"
                    },
                    activity : "Attività",
                    activities : {
                        activism: "Attivismo",
                        cropProduction: "Produzione di colture",
                        livestockFarming: "Allevamento di bestiame",
                        research: "Ricerca",
                        communication: "Comunicazione",
                        resourceManagement: "Gestione delle risorse",
                        techDev: "Sviluppo e applicazione della tecnologia",
                        foodProcessing: "Lavorazione alimentare",
                        foodSafety: "Sicurezza alimentare e controllo di qualità",
                        education: "Istruzione e formazione",
                        policyDev: "Sviluppo delle politiche",
                        communityOutreach: "Sensibilizzazione della comunità",
                        economicActivities: "Attività economiche",
                        innovation: "Hub di innovazione",
                        monitoring: "Monitoraggio ambientale",
                        infrastructure: "Manutenzione e sviluppo delle infrastrutture",
                        internationalCoop: "Cooperazione Internazionale"
                    },
                    country: "Paese",
                    region: "Regione",
                    numEmp: "Numero di dipendenti",
                    years: "Anni di attività",
                    q5: {
                        knowledgeCreation: "Ripensare la creazione e l'uso della conoscenza",
                        networkBehavior: "Influenzare il comportamento della ret",
                        changingStructure: "Modificare la struttura della rete",
                        culturalValues: "TODO"
                    },
                    a5: {
                        stronglyDisagree: "Fortemente in disaccordo",
                        stronglyAgree: "Assolutamente d'accordo "
                    },
                    website: "Sito web",
                    legend: "Legenda",
                    filter: "Filtri",
                    actor: "Stakeholder",
                    size: "Dimensione",
                    formal: "formale",
                    informal: "informale",
                    connections: "Collaborazione",
                    collaborations: {
                        jointProjectDev: "Sviluppo di progetti congiunti",
                        publicPolicyDesign: "Progettazione delle politiche pubbliche",
                        humanResourceMob: "Mobilitazione delle risorse umane",
                        sharedInfrastructure: "Infrastruttura condivisa",
                        collaborationInoovation: "Collaborazione per l'innovazione",
                        technologyExchange: "Scambio tecnologico (bidirezionale)",
                        technologyTransfer: "Trasferimento tecnologico (unidirezionale)",
                        researchConsortia: "Consorzi di ricerca",
                        strategicPartnership: "Partnership strategiche",
                        knowledgeExchange: "Scambio di conoscenze (bidirezionale)",
                        knowledgeTransfer: "Trasferimento di conoscenza (unidirezionale)",
                        sharingSpaces: "Condivisione di spazi (ad esempio: Piattaforme di innovazione, Consigli…)",
                        communityEngagement: "Iniziative di coinvolgimento della comunità",
                        sharingAdvice: "Condivisione di consigli",
                        lobbying: "Lobbying (Il lobbying è l'atto di tentare di influenzare le decisioni dei funzionari governativi a favore di una particolare causa, politica o interesse)",
                        personalRelations: "Relazioni personali"
                    },
                    density: "Densità della rete",
                    avDistance: "Distanza media",
                    sampleData: "Questa rete presenta Dati di esempio.",
                    completedSuccess: "Il tuo sondaggio è stato inviato con successo!"
                }
            },
            nl: {
                translation: {
                    welcome_h2: "FARMWISE stakeholders survey",
                    welcome_p1: "Als u deze enquête ontvangt, is dat omdat u wordt beschouwd als een belangrijke speler in het water- en agrovoedselsysteem van uw regio.",
                    welcome_p2: "Dit onderzoek is onderdeel van het FARMWISE-project (Future Agricultural Resource Management and Water Innovations for a Sustainable Europe), gefinancierd door het Horizon Europe-programma van de EU. Het project heeft als doel de veerkracht van watersystemen te verbeteren in acht Europese casestudies. Voor meer informatie, bezoek ",
                    welcome_p2_1: "de officiële website.", 
                    welcome_p3: "Uw antwoorden helpen bij het maken van een “kaart” (een gedetailleerde weergave) van de belangrijkste actoren en hun interacties in elke regio waar FARMWISE actief is.",
                    welcome_p4: "Zodra deze kaarten zijn ontwikkeld, worden ze gedeeld met alle deelnemende organisaties. Zo kunt u zien waar uw organisatie zich bevindt binnen het agrifood- en waterecosysteem van uw regio.",
                    welcome_p5: "Wij geloven dat deze kaarten zowel uw organisatie als het bredere ecosysteem strategisch ten goede komen door het begrip te verbeteren en betere besluitvorming te ondersteunen. Ons uiteindelijke doel is om het waterbeheer te verbeteren en de veerkracht tegen de gevolgen van klimaatverandering te vergroten.",
                    welcome_p6: "Hartelijk dank voor uw deelname,",
                    signature: "Het FARMWISE-team", 
                    startSurvey: "enquête starten",
                    home: "Home",
                    survey: "Survey",
                    network: "Netwerk",
                    admin: "Admin",
                    stakeholderinfo: "Stakeholder",
                    name: "Naam",
                    sector : "Sector",
                    sectors: {
                        privateSector: "Private sector",
                        publicSector: "Publieke sector",
                        academia: "Academia",
                        civilSociety: "Maatschappelijk middenveld ",
                        media: "Media",
                        nonOrg: "Niet-georganiseerde burgermaatschappij "
                    },
                    activity : "Activiteit",
                    activities : {
                        activism: "Activisme",
                        cropProduction: "Gewasproductie",
                        livestockFarming: "Veehouderij",
                        research: "Onderzoek",
                        communication: "Mededeling",
                        resourceManagement: "Resourcebeheer",
                        techDev: "Technologieontwikkeling en -toepassing",
                        foodProcessing: "Voedselverwerking",
                        foodSafety: "Voedselveiligheid en kwaliteitscontrole",
                        education: "Onderwijs en opleiding",
                        policyDev: "Beleidsontwikkeling",
                        communityOutreach: "Gemeenschapswerk",
                        economicActivities: "Economische activiteiten",
                        innovation: "Innovatiecentrum",
                        monitoring: "Milieumonitoring",
                        infrastructure: "Infrastructuuronderhoud en -ontwikkeling",
                        internationalCoop: "Internationale samenwerking"
                    },
                    country: "Land",
                    region: "Regio",
                    numEmp: "Aantal werknemers",
                    years: "Jaren van activiteit",
                    q5: {
                        knowledgeCreation: "Kenniscreatie en -gebruik heroverwegen",
                        networkBehavior: "Beïnvloeding van netwerkgedrag",
                        changingStructure: "De structuur van het netwerk veranderen",
                        culturalValues: "Culturele waarden"
                    },
                    a5: {
                        stronglyDisagree: "Helemaal niet mee eens",
                        stronglyAgree: "Helemaal mee eens"
                    },
                    website: "Website",
                    legend: "Legende",
                    filter: "Filters",
                    actor: "Belanghebbenden",
                    size: "Maat",
                    formal: "formele",
                    informal: "informele",
                    connections: "Samenwerking",
                    collaborations: {
                        jointProjectDev: "Gezamenlijke projectontwikkeling",
                        publicPolicyDesign: "Ontwerp van het overheidsbeleid",
                        humanResourceMob: "Mobilisatie van menselijke hulpbronnen",
                        sharedInfrastructure: "Gedeelde infrastructuur",
                        collaborationInoovation: "Samenwerking voor innovatie",
                        technologyExchange: "Technologie-uitwisseling (bidirectioneel)",
                        technologyTransfer: "Technologieoverdracht (unidirectioneel)",
                        researchConsortia: "Onderzoeksconsortia",
                        strategicPartnership: "Strategische partnerschappen",
                        knowledgeExchange: "Kennisuitwisseling (bidirectioneel)",
                        knowledgeTransfer: "Kennisoverdracht (unidirectioneel)",
                        sharingSpaces: "Gedeelde ruimtes (bijv.: Innovatieplatforms, raden…)",
                        communityEngagement: "Initiatieven voor maatschappelijke betrokkenheid",
                        sharingAdvice: "Advies delen",
                        lobbying: "Lobbyen (lobbyen is de handeling waarbij men probeert de beslissingen van overheidsfunctionarissen te beïnvloeden ten gunste van een bepaalde zaak, beleid of belang)",
                        personalRelations: "Persoonlijke relaties"
                    },
                    density: "Netwerkdichtheid",
                    avDistance: "Gemiddelde afstand.",
                    sampleData: "Dit netwerk presenteert voorbeeldgegevens.",
                    completedSuccess: "Uw enquête is succesvol verzonden!"
                }
            },
            pl: {
                translation: {
                    welcome_h2: "Ankieta dla interesariuszy FARMWISE",
                    welcome_p1: "Jeśli otrzymujesz tę ankietę, oznacza to, że jesteś uważany za ważnego aktora w systemie wodnym i rolno-spożywczym swojego regionu.",
                    welcome_p2: "Niniejsze badanie jest częścią projektu FARMWISE (Future Agricultural Resource Management and Water Innovations for a Sustainable Europe), finansowanego przez program UE Horyzont Europa. Celem projektu jest zwiększenie odporności systemów wodnych w ośmiu europejskich studiach przypadku. Aby uzyskać więcej informacji, odwiedź ",
                    welcome_p2_1: "oficjalną stronę internetową.", 
                    welcome_p3: "Twoje odpowiedzi pomogą stworzyć „mapę” (szczegółowa reprezentacja) kluczowych aktorów i ich interakcji w każdym regionie, w którym działa FARMWISE.",
                    welcome_p4: "Po opracowaniu tych map zostaną one udostępnione wszystkim uczestniczącym organizacjom. Pozwoli Ci to zobaczyć, gdzie znajduje się Twoja organizacja. w ekosystemie rolno-spożywczym i wodnym Twojego regionu.",
                    welcome_p5: "Uważamy, że mapy te przyniosą strategiczne korzyści zarówno Twojej organizacji, jak i szerszemu ekosystemowi, poprawiając zrozumienie i wspierając podejmowanie lepszych decyzji. Naszym ostatecznym celem jest poprawa zarządzania wodą i wzmocnienie odporności na skutki zmiany klimatu.",
                    welcome_p6: "Dziękujemy bardzo za udział,",
                    signature: "Zespół FARMWISE",  
                    startSurvey: "rozpocznij ankietę",
                    home: "Home",
                    survey: "Ankieta",
                    network: "Sieć",
                    admin: "Administrator",
                    stakeholderinfo: "Informacje dla interesariuszy",
                    name: "Nazwa",
                    sector : "Sektor",
                    sectors: {
                        privateSector: "Sektor prywatny",
                        publicSector: "Sektor publiczny",
                        academia: "Środowisko akademickie",
                        civilSociety: "Społeczeństwo obywatelskie",
                        media: "Media",
                        nonOrg: "Społeczeństwo obywatelskie"
                    },
                    activity : "Aktywność",
                    activities : {
                        activism: "Aktywizm",
                        cropProduction: "Produkcja roślinna",
                        livestockFarming: "Hodowla zwierząt gospodarskich",
                        research: "Badania",
                        communication: "Komunikacja",
                        resourceManagement: "Zarządzanie zasobami",
                        techDev: "Rozwój i zastosowanie technologii",
                        foodProcessing: "Przetwórstwo spożywcze",
                        foodSafety: "Bezpieczeństwo żywności i kontsrola jakości",
                        education: "Edukacja i szkolenia",
                        policyDev: "Rozwój polityki",
                        communityOutreach: "Zasięg społeczności",
                        economicActivities: "Działalność gospodarcza",
                        innovation: "Centrum Innowacji",
                        monitoring: "Monitorowanie środowiska",
                        infrastructure: "Utrzymanie i rozwój infrastruktury",
                        internationalCoop: "Współpraca międzynarodowa"
                    },
                    country: "Kraj",
                    region: "Region",
                    numEmp: "Liczba pracowników ",
                    years: "Lata działalności",
                    q5: {
                        knowledgeCreation: "Rethinking Knowledge Creation and Use",
                        networkBehavior: "Influencing Network Behavior",
                        changingStructure: "Changing the Structure of the Network",
                    },
                    a5: {
                        stronglyDisagree: "Strongly disagree",
                        stronglyAgree: "Strongly agree"
                    },
                    website: "Strona internetowa",
                    legend: "Legenda",
                    filter: "Filtr",
                    actor: "Interesariusz",
                    size: "Rozmiar",
                    formal: "formalna",
                    informal: "nieformalna",
                    connections: "Współpraca",
                    collaborations: {
                        jointProjectDev: "Wspólny rozwój projektu",
                        publicPolicyDesign: "Projektowanie Polityki Publicznej",
                        humanResourceMob: "Mobilizacja zasobów ludzkich",
                        sharedInfrastructure: "Wspólna infrastruktura",
                        collaborationInoovation: "Współpraca na rzecz innowacji",
                        technologyExchange: "Wymiana technologii (dwukierunkowa)",
                        technologyTransfer: "Transfer technologii (jednokierunkowy)",
                        researchConsortia: "Konsorcja badawcze",
                        strategicPartnership: "Partnerstwa strategiczne",
                        knowledgeExchange: "Wymiana wiedzy (dwukierunkowa)",
                        knowledgeTransfer: "Transfer wiedzy (jednokierunkowy)",
                        sharingSpaces: "Udostępnianie przestrzeni (np.: Platformy Innowacji, Rady…)",
                        communityEngagement: "Inicjatywy zaangażowania społecznego",
                        sharingAdvice: "Dzielenie się poradami",
                        lobbying: "Lobbing (Lobbing to działanie mające na celu wpłynięcie na decyzje urzędników rządowych na rzecz określonej sprawy, polityki lub interesu)",
                        personalRelations: "Relacje osobiste"
                    },
                    density: "Gęstość sieci",
                    avDistance: "Średnia odległość",
                    sampleData: "Ta sieć przedstawia przykładowe dane.",
                    completedSuccess: "Twoja ankieta została pomyślnie wysłana!"
                }
            },
            es: {
                translation: {
                    welcome_h2: "FARMWISE Stakeholders Survey",
                    welcome_p1: "Si está recibiendo esta encuesta es porque se le considera un agente importante en el sistema hídrico y agroalimentario de su región.",
                    welcome_p2: "Esta encuesta forma parte del proyecto FARMWISE (Future Agricultural Resource Management and Water Innovations for a Sustainable Europe), financiado por el programa Horizonte Europa de la UE. El proyecto pretende aumentar la resiliencia de los sistemas hídricos en ocho estudios de casos europeos. Para más información, visite ",
                    welcome_p2_1: "el sitio web oficial.", 
                    welcome_p3: "Sus respuestas ayudarán a crear un «mapa» (una representación detallada) de los actores clave y sus interacciones en cada región donde opera FARMWISE.",
                    welcome_p4: "Una vez elaborados estos mapas, se compartirán con todas las organizaciones participantes. Esto les permitirá ver dónde está «ubicada» su organización dentro del ecosistema agroalimentario e hídrico de su región.",
                    welcome_p5: "Creemos que estos mapas beneficiarán estratégicamente tanto a su organización como al ecosistema en general al mejorar la comprensión y apoyar una mejor toma de decisiones. Nuestro objetivo último es mejorar la gestión del agua y reforzar la resistencia a los efectos del cambio climático.",
                    welcome_p6: "Muchas gracias por su participación,",
                    signature: "El equipo de FARMWISE",  
                    startSurvey: "iniciar encuesta",
                    home: "Inicio",
                    survey: "Encuesta",
                    network: "Red de Actores",
                    admin: "Admin",
                    stakeholderinfo: "Información sobre el actor",
                    name: "Nombre",
                    sector : "Sector",
                    sectors: {
                        privateSector: "Sector privado",
                        publicSector: "Sector público",
                        academia: "Academia",
                        civilSociety: "Sociedad civil",
                        media: "Medios de comunicación",
                        nonOrg: "Sociedad civil no organizada"
                    },
                    activity : "Actividad",
                    activities : {
                        activism: "Activismo",
                        cropProduction: "Producción agrícola",
                        livestockFarming: "Ganadería",
                        research: "Investigación",
                        communication: "Comunicación",
                        resourceManagement: "Gestión de recursos",
                        techDev: "Desarrollo y aplicación de tecnología",
                        foodProcessing: "Procesado de alimentos",
                        foodSafety: "Seguridad alimentaria y control de calidad",
                        education: "Educación y formación",
                        policyDev: "Desarrollo de políticas",
                        communityOutreach: "Relaciones con la comunidad",
                        economicActivities: "Actividades económicas",
                        innovation: "Centro de innovación",
                        monitoring: "Vigilancia medioambiental",
                        infrastructure: "Mantenimiento y desarrollo de infraestructuras",
                        internationalCoop: "Cooperación internacional"
                    },
                    country: "País",
                    region: "Región",
                    numEmp: "Número de empleados",
                    years: "Años de actividad",
                    q5: {
                        knowledgeCreation: "Rethinking Knowledge Creation and Use",
                        networkBehavior: "Influencing Network Behavior",
                        changingStructure: "Changing the Structure of the Network",
                    },
                    a5: {
                        stronglyDisagree: "Strongly disagree",
                        stronglyAgree: "Strongly agree"
                    },
                    website: "Página web",
                    legend: "Leyenda",
                    filter: "Filtros",
                    actor: "Actor",
                    size: "Talla",
                    formal: "formal",
                    informal: "informal",
                    connections: "Colaboración",
                    collaborations: {
                        jointProjectDev: "Desarrollo de proyectos conjuntos",
                        publicPolicyDesign: "Diseño de políticas públicas",
                        humanResourceMob: "Movilización de recursos humanos",
                        sharedInfrastructure: "Infraestructura compartida",
                        collaborationInoovation: "Colaboración para la innovación",
                        technologyExchange: "Intercambio tecnológico (bidireccional)",
                        technologyTransfer: "Transferencia de tecnología (unidireccional)",
                        researchConsortia: "Consorcios de investigación",
                        strategicPartnership: "Asociaciones estratégicas",
                        knowledgeExchange: "Intercambio de conocimientos (bidireccional)",
                        knowledgeTransfer: "Transferencia de conocimientos (unidireccional)",
                        sharingSpaces: "Espacios compartidos (por ejemplo: plataformas de innovación, consejos...)",
                        communityEngagement: "Iniciativas de participación comunitaria",
                        sharingAdvice: "Asesoramiento compartido",
                        lobbying: "Lobbying (Lobbying es el acto de intentar influir en las decisiones de los funcionarios del gobierno a favor de una causa, política o interés particular)",
                        personalRelations: "Relaciones personales"
                    },
                    density: "Densidad de la red",
                    avDistance: "Distancia media",
                    sampleData: "Esta red representa datos de muestra.",
                    completedSuccess: "¡Su encuesta ha sido enviada exitosamente!"
                }
            },
            sw: {
                translation: {
                    welcome_h2: "FARMWISE intressentundersökning",
                    welcome_p1: "Om du får den här enkäten är det för att du anses vara en viktig aktör i din regions vatten- och livsmedelssystem.",
                    welcome_p2: "Denna undersökning är en del av FARMWISE-projektet (Future) Agricultural Resource Management and Water Innovations for a Sustainable Europe), finansierad av EU:s Horizon Europe-program. Projektet syftar till att förbättra miljöförhållandena i vattensystem i åtta europeiska fallstudier. För mer information, besök ",
                    welcome_p2_1: "den officiella webbplatsen.", 
                    welcome_p3: "Dina svar hjälper till att skapa en “karta” (en detaljerad beskrivning) av nyckelaktörerna och deras interaktioner i varje region där FARMWISE verkar.",
                    welcome_p4: "När dessa kartor har utvecklats kommer de att sändas ut till deltagande organisationer. Detta gör att du kan se var din organisation är “belägen” inom jordbruks- och vattenekosystemet i din region.",
                    welcome_p5: "Vi tror att dessa kartor kommer att gynna både din organisation och det allmänna ekosystemet strategiskt genom att förbättra förståelsen och stödja bättre beslutsfattande. Vårt yttersta mål är att förbättra vattenförvaltningen och stärka motståndskraften mot effekterna av klimatförändringar.",
                    welcome_p6: "Tack så mycket för ditt deltagande,",
                    signature: "FARMWISE-teamet",  
                    startSurvey: "starta undersökning",
                    home: "Hem",
                    survey: "Undersökning",
                    network: "Nätverk",
                    admin: "Admin",
                    stakeholderinfo: "Information om intressenter",
                    name: "Namn",
                    sector : "Sektor",
                    sectors: {
                        privateSector: "Privat sektor",
                        publicSector: "Offentlig sektor",
                        academia: "Akademin",
                        civilSociety: "Det civila samhället",
                        media: "Media",
                        nonOrg: "Icke-organiserat civilsamhälle"
                    },
                    activity : "Aktivitet",
                    activities : {
                        activism: "Aktivism",
                        cropProduction: "Växtproduktion",
                        livestockFarming: "Boskapsuppfödning",
                        research: "Forskning",
                        communication: "Kommunikation",
                        resourceManagement: "Resurshantering",
                        techDev: "Teknikutveckling och applikation",
                        foodProcessing: "Livsmedelsbearbetning",
                        foodSafety: "Livsmedelssäkerhet och kvalitetskontroll",
                        education: "Utbildning och träning",
                        policyDev: "Policyutveckling",
                        communityOutreach: "Community Outreach",
                        economicActivities: "Ekonomisk verksamhet",
                        innovation: "Innovationsnav",
                        monitoring: "Miljöövervakning",
                        infrastructure: "Underhåll och utveckling av infrastruktur",
                        internationalCoop: "Internationellt samarbete"
                    },
                    country: "Land",
                    region: "Region",
                    numEmp: "Antal anställda",
                    years: "År av verksamhet",
                    q5: {
                        knowledgeCreation: "Rethinking Knowledge Creation and Use",
                        networkBehavior: "Influencing Network Behavior",
                        changingStructure: "Changing the Structure of the Network",
                    },
                    a5: {
                        stronglyDisagree: "Strongly disagree",
                        stronglyAgree: "Strongly agree"
                    },
                    website: "Webbplats",
                    legend: "Legend",
                    filter: "Filter",
                    actor: "Aktör",
                    size: "Storlek",
                    formal: "formellt",
                    informal: "informellt",
                    connections: "Samarbete",
                    collaborations: {
                        jointProjectDev: "Gemensam projektutveckling",
                        publicPolicyDesign: "Public Policy Design",
                        humanResourceMob: "Mobilisering av mänskliga resurser",
                        sharedInfrastructure: "Delad infrastruktur",
                        collaborationInoovation: "Samverkan för innovation",
                        technologyExchange: "Teknikutbyte (dubbelriktad)",
                        technologyTransfer: "Tekniköverföring (enkelriktad)",
                        researchConsortia: "Forskningskonsortier",
                        strategicPartnership: "Strategiska partnerskap",
                        knowledgeExchange: "Kunskapsutbyte (dubbelriktad)",
                        knowledgeTransfer: "Kunskapsöverföring (enkelriktad)",
                        sharingSpaces: "Dela utrymmen (t.ex. innovationsplattformar, råd...)",
                        communityEngagement: "Initiativ för samhällsengagemang",
                        sharingAdvice: "Dela råd",
                        lobbying: "Lobbyverksamhet (Lobbyverksamhet är handlingen att försöka påverka regeringstjänstemäns beslut till förmån för en viss sak, policy eller intresse)",
                        personalRelations: "Personliga relationer"
                    },
                    density: "Nätverkstäthet",
                    avDistance: "Genomsnittligt avstånd",
                    sampleData: "I detta nätverk presenteras exempeldata.",
                    completedSuccess: "Din undersökning har skickats in!"
                }
            },
            ua: {
                translation: {
                    welcome_h2: "Опитування зацікавлених сторін FARMWISE",
                    welcome_p1: "Якщо ви отримуєте це опитування, це тому, що ви вважаються важливим учасником водної та агропродовольчої системи вашого регіону.",
                    welcome_p2: "Це опитування є частиною проекту FARMWISE (Майбутнє Управління сільськогосподарськими ресурсами та водні інновації для сталої Європи), що фінансується програмою ЄС Horizon Europe. Проект спрямований на підвищення стійкості водних систем у восьми європейських прикладах. Для отримання додаткової інформації відвідайте ",
                    welcome_p2_1: "офіційний сайт.", 
                    welcome_p3: "Ваші відповіді допоможуть створити “карту” (детальне представлення) ключових учасників та їх взаємодію в кожному регіоні, де працює FARMWISE.",
                    welcome_p4: "Після розробки цих карт до них буде надано спільний доступ з усіма організаціями-учасниками. Це дозволить вам побачити, де “знаходиться” в агропродовольчій та водній екосистемі вашого регіону.",
                    welcome_p5: "Ми вважаємо, що ці карти принесуть стратегічну користь як вашій організації, так і ширшій екосистемі, покращуючи розуміння та сприяючи прийняттю кращих рішень. Наша кінцева мета — покращити управління водними ресурсами та посилити стійкість до наслідків зміни клімату.",
                    welcome_p6: "Щиро дякуємо за вашу участь,",
                    signature: "Команда FARMWISE",  
                    startSurvey: "почати опитування",
                    home: "головна",
                    survey: "опитування",
                    network: "мережа",
                    admin: "адмін",
                    stakeholderinfo: "інформація про актора",
                    name: "Назва",
                    sector : "Регіон",
                    sectors: {
                        privateSector: "Приватний сектор",
                        publicSector: "Державний сектор",
                        academia: "Академія",
                        civilSociety: "Громадянське суспільство",
                        media: "ЗМІ",
                        nonOrg: "Неорганізоване громадянське суспільство"
                    },
                    activity : "діяльність",
                    activities : {
                        activism: "Активізм",
                        cropProduction: "Рослинництво",
                        livestockFarming: "LТваринництво",
                        research: "дослідження",
                        communication: "спілкування",
                        resourceManagement: "Управління ресурсами",
                        techDev: "Розробка та застосування технологій",
                        foodProcessing: "Обробка харчових продуктів",
                        foodSafety: "Контроль безпеки та якості харчових продуктів",
                        education: "Освіта та навчання",
                        policyDev: "Розробка політики",
                        communityOutreach: "Робота з громадськістю",
                        economicActivities: "Господарська діяльність",
                        innovation: "Інноваційний хаб",
                        monitoring: "Екологічний моніторинг",
                        infrastructure: "Обслуговування та розвиток інфраструктури",
                        internationalCoop: "Міжнародна співпраця"
                    },
                    country: "Країна",
                    region: "Регіон",
                    numEmp: "Кількість працівників",
                    years: "Роки діяльності",
                    q5: {
                        knowledgeCreation: "Rethinking Knowledge Creation and Use",
                        networkBehavior: "Influencing Network Behavior",
                        changingStructure: "Changing the Structure of the Network",
                    },
                    a5: {
                        stronglyDisagree: "Strongly disagree",
                        stronglyAgree: "Strongly agree"
                    },
                    website: "сайт",
                    legend: "легенда",
                    filter: "фільтр",
                    actor: "актор",
                    size: "розмір",
                    formal: "Офіційна",
                    informal: "Неформальна",
                    connections: "співпраця",
                    collaborations: {
                        jointProjectDev: "Спільна розробка проекту",
                        publicPolicyDesign: "Дизайн державної політики",
                        humanResourceMob: "Мобілізація людських ресурсів",
                        sharedInfrastructure: "Спільна інфраструктура",
                        collaborationInoovation: "Співпраця заради інновацій",
                        technologyExchange: "Обмін технологіями (двосторонній)",
                        technologyTransfer: "Трансфер технологій (односпрямований)",
                        researchConsortia: "Дослідницькі консорціуми",
                        strategicPartnership: "Стратегічне партнерство",
                        knowledgeExchange: "Обмін знаннями (двосторонній)",
                        knowledgeTransfer: "Передача знань (односпрямована)",
                        sharingSpaces: "Обмін місцями (наприклад, інноваційні платформи, ради…)",
                        communityEngagement: "Ініціативи залучення громади",
                        sharingAdvice: "Обмін порадами",
                        lobbying: "Лобіювання (Лобіювання – це спроба вплинути на рішення урядовців на користь певної справи, політики чи інтересів)",
                        personalRelations: "Особисті відносини"
                    },
                    density: "щільність мережі",
                    avDistance: "середня відстань",
                    sampleData: "У цій мережі представлені вибіркові дані.",
                    completedSuccess: "Ваше опитування успішно подано!"
                }
            }

        },
        lng: "en", // default language
        fallbackLng: "en",
        interpolation: {
            escapeValue: false // React already does escaping
        }
    });

export default i18n;