import logo from '../img/logo_FARMWISE.png'
import logoUPV from '../img/logo_UPV.jpg'
import logoHDA from '../img/logo_HDA.png'
import React from 'react';
import LanguageSwitcher from './LanguageSwitcher';

import style from '../style.css'

export default function Header(){
    return(
        <header className="header">
            <img src={logoUPV} alt="logo"/>
            <img src={logo} alt="logo"/>
            <img src={logoHDA} alt="logo"/>
        </header>
    )
}