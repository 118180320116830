import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import "./App.css";
import "./style.css";
import { HomePage } from "./pages/Home";
import { SurveyPage } from "./pages/Survey";
import { StakeholderMap } from "./pages/StakeholderMap";
import { AdminPage } from "./pages/Admin";
import { NetworkProvider } from './NetworkContext';
import React from 'react';

import * as Survey from "survey-core";
import $ from "jquery";

export { MyQuestion } from "./components/MyQuestion";

import "jquery-ui/themes/base/all.css";
import "nouislider/distribute/nouislider.css";
import "select2/dist/css/select2.css";
import "bootstrap-slider/dist/css/bootstrap-slider.css";
import "jquery-bar-rating/dist/themes/css-stars.css";
import "jquery-bar-rating/dist/themes/fontawesome-stars.css";
import "pretty-checkbox/dist/pretty-checkbox.css";

import "select2/dist/js/select2.js";
import "jquery-bar-rating";
import { useTranslation } from 'react-i18next';

// import "icheck/skins/square/blue.css";
// require("icheck");

import * as widgets from "surveyjs-widgets";
import Header from "./components/Header";
import LanguageSwitcher from "./components/LanguageSwitcher";

window["$"] = window["jQuery"] = $;
require("jquery-ui/ui/widgets/datepicker.js");

widgets.prettycheckbox(Survey);
widgets.select2(Survey, $);
widgets.inputmask(Survey);
widgets.jquerybarrating(Survey, $);
widgets.jqueryuidatepicker(Survey, $);
widgets.nouislider(Survey);
widgets.select2tagbox(Survey, $);
widgets.sortablejs(Survey);
widgets.ckeditor(Survey);
widgets.autocomplete(Survey, $);
widgets.bootstrapslider(Survey);
// widgets.icheck(Survey, $);

export const BACKEND_ENDPOINT = "https://sus.media.h-da.de/api";

export default function SurveyJSReactApplication() {
  const { t } = useTranslation();
  return (

        <NetworkProvider>
          <>
          <Router>
            <div>
              <nav className="navbar navbar-expand-lg">
                <div className="container-fluid">
                  <div className="navbar-header">

                    <a className="navbar-brand" href="/">
                      FARMWISE Stakeholder Mapping
                    </a>
                    <button
                        className="navbar-toggler"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarNav"
                        aria-controls="navbarNav"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                      <span className="navbar-toggler-icon"></span>
                    </button>
                  </div>
                  <div className="collapse navbar-collapse" id="navbarNav">
                    <ul className="nav navbar-nav me-auto mb-lg-0">
                      <li className="nav-item">
                        <Link className="nav-link" to="/">{t('home')}</Link>
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link" to="/survey">{t('survey')}</Link>
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link" to="/stakeholdernetwork">{t('network')}</Link>
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link" to="/admin">{t('admin')}</Link>
                      </li>
                    </ul>
                  </div>
                  <LanguageSwitcher/>
                </div>
              </nav>

              <div className="app-content">
                <Switch>
                  <Route exact path="/">
                    <HomePage/>
                  </Route>
                  <Route path="/survey">
                    <SurveyPage/>
                  </Route>
                  <Route path="/stakeholdernetwork">
                    <StakeholderMap/>
                  </Route>
                  <Route path="/admin">
                    <AdminPage/>
                  </Route>
                </Switch>
              </div>
            </div>
          </Router>
            <Header/>
          </>
        </NetworkProvider>

  );
}
