import React, {useEffect, useState} from "react";

import Dropdown from '../components/Dropdown'
import NodeLinkGraph from '../components/NodeLinkGraph';
import InfoBox from '../components/InfoBox';
import FilterLegend from '../components/FilterLegend';

import data from '../data/stakeholderData'
import {useNetworkContext} from "../NetworkContext";
import { useTranslation } from 'react-i18next';

export function StakeholderMap() {

    const [currentHeading, setHeading] = useState("Welcome");


    //TODO: integrate next two const and function toggleCategory
    const sectorCategories = [
        {id: 1, name:'academia', isSelected:true},
        {id: 2, name:'civilSociety', isSelected:true},
        {id: 3, name: 'publicSector', isSelected:true},
        {id: 4, name:'Private Sector', isSelected:true},
        {id: 5, name:'Media', isSelected:true},
        {id: 6, name:'nonOrg', isSelected:true}]

    const [selectedCategories, setSelectedCategories] = useState(sectorCategories);

    function toggleCategories (id) {
        setSelectedCategories(prevCategories => {
            return prevCategories.map((category) => {
                return category.id === id ? {...category,  isSelected : !category.isSelected} : category
                })
            })
    }

    const {selectedCaseStudy,
        selectedNodes, setSelectedNodes, filteredNodes,
        selectedEdges, setSelectedEdges,
        selectedColoringAttrib, setSelectedColoringAttrib,
        sectorColors} = useNetworkContext();

    const coloringAttrib = "sector"

    const { t } = useTranslation();


    useEffect(() => {
        const id = parseInt(selectedCaseStudy)
        const searchData = data.find(entry => entry.id === id);
        if (searchData) {
            setSelectedNodes(searchData.nodes)
            setSelectedEdges(searchData.edges)
            setHeading(searchData.name)
        } else {
            setHeading("Sample Data")
        }
    }, [selectedCaseStudy, setSelectedNodes, setSelectedEdges, setHeading, selectedNodes, selectedEdges, filteredNodes, currentHeading]);


    return (
        <>
            <div className="map-heading">
                <h2>{currentHeading}</h2>
                <Dropdown/>
                <p>{t('sampleData')}</p>
            </div>
            <div className="container-fluid" id="map-container">

                <div className="row">
                    {/* Left Sidebar */}
                    <aside className="col-md-3 bg-light p-4 asideBox">
                        <InfoBox/>
                    </aside>

                    <main className="col-md-7 p-4" id="main-map-container">
                        <div>
                            <NodeLinkGraph nodes={selectedNodes} edges={selectedEdges} colors={sectorColors}
                                           coloringAttrib={coloringAttrib}/>
                        </div>
                    </main>
                    <aside className="col-md-2 bg-light p-4 asideBox">
                        <FilterLegend />
                    </aside>
                </div>
            </div>

        </>
    );
}