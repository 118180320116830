export const surveyContent = {
  triggers: [{ type: "complete", 
              expression: "{consentQuestion} == 'disagree'" 
            }],
  completedHtml: { "en": "<h3>Thank you for your participation!</h3>",
                  "fi": "<h3>Kiitos osallistumisestasi!</h3>",
                  "it": "<h3>Grazie per la vostra partecipazione!</h3>",
                  "es": "<h3>¡Gracias por tu participación!</h3>",
                  "nl": "<h3>Bedankt voor uw deelname!</h3>",
                  "pl": "<h3>Dziękujemy za udział!</h3>",
                  "sw": "<h3>Tack för ditt deltagande!</h3>",
                  "ua": "<h3>Дякуємо за участь!</h3>"
                },
  completedHtmlOnCondition: [{ expression: "{consentQuestion} == 'agree'", 
                              html: { "en": "<h3>Thank you for your participation!</h3>",
                                      "fi": "<h3>Kiitos osallistumisestasi!</h3>",
                                      "it": "<h3>Grazie per la vostra partecipazione!</h3>",
                                      "es": "<h3>¡Gracias por tu participación!</h3>",
                                      "nl": "<h3>Bedankt voor uw deelname!</h3>",
                                      "pl": "<h3>Dziękujemy za udział!</h3>",
                                      "sw": "<h3>Tack för ditt deltagande!</h3>",
                                      "ua": "<h3>Дякуємо за участь!</h3>"
                                    }
                              }, 
                              { expression: "{consentQuestion} == 'disagree'", 
                                html: { "en": "<h3>Thank you for your time.</h3>",
                                "fi": "<h3>Kiitos ajastasi.</h3>",
                                "it": "<h3>Grazie per il tuo tempo.</h3>",
                                "es": "<h3>Gracias por tu tiempo.</h3>",
                                "nl": "<h3>Dank u voor uw tijd.</h3>",
                                "pl": "<h3>Dziękuję za poświęcony czas.</h3>",
                                "sw": "<h3>Tack för din tid.</h3>",
                                "ua": "<h3>Дякуємо за ваш час.</h3>"
                                      }
                              }
                            ],
  "questionTitlePattern": "numTitle",
  "requiredText": "",
  pages: [{ name: "consentSectionTest",
            title: { "en": "<h1 style=\"text-align:center;\">PARTICIPANT INFORMATION</h1> <p style=\"text-align:center;font-size:22px;font-weight:normal;\">Project: FARMWISE. Future Agricultural Resource Management and Water Innovations for a Sustainable Europe, funded by the European Union´s Horizon Europe research and innovation programme under Grant Agreement 101135533.<br>Call: HORIZON-CL6-2023-ZEROPOLLUTION-01</p>",
                    "fi":"<h1 style=\"text-align:center;\">OSALLISTUJEN TIEDOT</h1> <p style=\"text-align:center;font-size:22px;font-weight:normal;\">Projekti: FARMWISE. Tulevaisuuden maatalous- ja vesiinnovaatioita kestävän Euroopan puolesta, rahoitetaan Euroopan unionin Horizon Europe -tutkimus- ja innovaatioohjelmasta avustussopimuksen 101135533 mukaisesti.<br>Soita: HORIZON-CL6-2023-ZEROPOLLUTION-01</p>",
                    "it": "<h1 style=\"text-align:center;\">SCHEDA INFORMATIVA PER I PARTECIPANTI</h1> <p style=\"text-align:center;font-size:22px;font-weight:normal;\"> Progetto: FARMWISE. Future Agricultural Resource Management and Water Innovations for a Sustainable Europe, finanziato dal programma di ricerca e innovazione Horizon Europe dell'Unione Europea nell'ambito dell'accordo di sovvenzione 101135533.<br> Bando: HORIZON-CL6-2023-ZEROPOLLUTION-01</p>",
                    "es": "<h1 style=\"text-align:center;\">HOJA DE INFORMACIÓN DEL PARTICIPANTE</h1> <p style=\"text-align:center;font-size:22px;font-weight:normal;\"> Proyecto: FARMWISE. Future Agricultural Resource Management and Water Innovations for a Sustainable Europe, financiado por el programa de investigación e innovación Horizon Europe de la Unión Europea en virtud del acuerdo de subvención 101135533.<br>Convocatoria: HORIZON-CL6-2023-ZEROPOLLUTION-01</p>",
                    "nl": "<h1 style=\"text-align:center;\">INFORMATIEBLAD VOOR DEELNEMERS</h1> <p style=\"text-align:center;font-size:22px;font-weight:normal;\">Project: FARMWISE. Future Agricultural Resource Management and Water Innovations for a Sustainable Europe, gefinancierd door het Horizon Europe-onderzoeks- en innovatieprogramma van de Europese Unie onder subsidieovereenkomst 101135533.<br>Oproep: HORIZON-CL6-2023-ZEROPOLLUTION-01</p>",
                    "pl": "<h1 style=\"text-align:center;\">KARTA INFORMACJI DLA UCZESTNIKÓW</h1> <p style=\"text-align:center;font-size:22px;font-weight:normal;\">Projekt: FARMWISE. Future Agricultural Resource Management and Water Innovations for a Sustainable Europe, finansowany przez program badawczy i innowacyjny Unii Europejskiej Horizon Europe na mocy umowy o dotację 101135533.<br>Zaproszenie: HORIZON-CL6-2023-ZEROPOLLUTION-01</p>",
                    "sw": "<h1 style=\"text-align:center;\">INFORMATIONSBLAD FÖR DELTAGARE</h1> <p style=\"text-align:center;font-size:22px;font-weight:normal;\">Projekt: FARMWISE. Framtida jordbruksresursförvaltning och vatteninnovationer för ett hållbart Europa, finansierat av Europeiska unionens forsknings- och innovationsprogram Horizon Europe under bidragsavtal 101135533.<br>Call: HORIZON-CL6-2023-ZEROPOLLUTION-01</p>",
                    "ua": "<h1 style=\"text-align:center;\">ІНФОРМАЦІЙНИЙ ЛИСТ УЧАСНИКА</h1> <p style=\"text-align:center;font-size:22px;font-weight:normal;\">Проект: FARMWISE. Майбутнє управління сільськогосподарськими ресурсами та водними інноваціями для сталої Європи, фінансується програмою досліджень та інновацій Horizon Europe Європейського Союзу згідно з Угодою про надання гранту 101135533.<br> Телефон: HORIZON-CL6-2023-ZEROPOLLUTION-01</p>"
                  },
            elements: [{"type": "panel",
                        "name": "participant_info_p1",
                        "title": { "en": "<strong>Introduction and description</strong>",
                                  "fi": "<strong>Esittely ja kuvaus</strong>",
                                  "it": "<strong>Introduzione e descrizione</strong>",
                                  "es": "<strong>Introducción y descripción</strong>",
                                  "nl": "<strong>Inleiding en beschrijving</strong>",
                                  "pl": "<strong>Wprowadzenie i opis</strong>",
                                  "sw": "<strong>Introduktion och beskrivning</strong>",
                                  "ua": "<strong>Вступ і опис</strong>"
                                },
                        "state": "collapsed",
                        "elements": [{"name": "participant_info_p1_text",
                                      "type": "rating",
                                      "autoGenerate": false,
                                      "rateCount": 5,
                                      "rateValues": [ "ok" ],
                                      "title": { "en": "This fact sheet provides details of the \"FARMWISE\" research project. Its overall objective is to develop improved tools for more efficient European water policy and decision-making. These tools are based on Artificial Intelligence (AI), and aim to solve the most burning water scarcity and/or pollution problems.",
                                                "fi": "Tämä tietolomake sisältää tiedot \"FARMWISE\" tutkimusprojekti. Sen yleisenä tavoitteena on kehittää parempia työkaluja tehokkaampaan eurooppalaiseen vesipolitiikkaan ja päätöksentekoon. Nämä työkalut perustuvat tekoälyyn (AI), ja niillä pyritään ratkaisemaan pahimmillaan vesipula- ja/tai saasteongelmat.",
                                                "it": "Questa scheda informativa fornisce i dettagli del progetto di ricerca \"FARMWISE\". Il suo obiettivo generale è sviluppare strumenti migliorati per una politica e un processo decisionale europei più efficienti in materia di acqua. Questi strumenti si basano sull'intelligenza artificiale (IA) e mirano a risolvere i problemi più urgenti di scarsità d'acqua e/o inquinamento.",
                                                "es": "Esta ficha informativa ofrece detalles sobre el proyecto de investigación «FARMWISE». Su objetivo general es desarrollar herramientas mejoradas para una política europea del agua y una toma de decisiones más eficientes. Estas herramientas se basan en la Inteligencia Artificial (IA) y pretenden resolver los problemas más candentes de escasez y/o contaminación del agua.",
                                                "nl": "Dit informatieblad bevat details over het onderzoeksproject \"FARMWISE\". Het algemene doel is om verbeterde tools te ontwikkelen voor efficiënter Europees waterbeleid en besluitvorming. Deze tools zijn gebaseerd op kunstmatige intelligentie (AI) en zijn bedoeld om de meest brandende problemen met waterschaarste en/of vervuiling op te lossen.",
                                                "pl": "Niniejsza karta informacyjna zawiera szczegółowe informacje na temat projektu badawczego „FARMWISE”. Jego ogólnym celem jest opracowanie ulepszonych narzędzi do bardziej efektywnej europejskiej polityki wodnej i podejmowania decyzji. Narzędzia te opierają się na sztucznej inteligencji (AI) i mają na celu rozwiązanie najbardziej palących problemów niedoboru wody i/lub zanieczyszczenia.",
                                                "sw": "Detta faktablad ger information om \"FARMWISE\" forskningsprojekt. Dess övergripande mål är att utveckla förbättrade verktyg för effektivare europeisk vattenpolitik och beslutsfattande. Dessa verktyg är baserade på artificiell intelligens (AI) och syftar till att lösa de mest brinnande problemen med vattenbrist och/eller föroreningar.",
                                                "ua": "Ця інформаційна сторінка містить детальну інформацію про \"FARMWISE\" дослідницький проект. Його загальна мета полягає в розробці вдосконалених інструментів для більш ефективної європейської водної політики та прийняття рішень. Ці інструменти засновані на штучному інтелекті (ШІ) і спрямовані на вирішення найгостріших проблем дефіциту води та/або забруднення."
                                              },
                                      "isRequired": false
                                    }]
                        },
                        {"type": "panel",
                          "name": "participant_info_p2",
                          "title": { "en": "<strong>Procedure</strong>",
                                    "fi": "<strong>Toimenpide</strong>",
                                    "it": "<strong>Procedura</strong>",
                                    "es": "<strong>Procedimiento</strong>",
                                    "nl": "<strong>Procedure</strong>",
                                    "pl": "<strong>Procedura</strong>",
                                    "sw": "<strong>Procedur</strong>",
                                    "ua": "<strong>Процедура</strong>"
                                  },
                          "state": "collapsed",
                          "elements": [{"name": "participant_info_p2_text",
                                        "type": "rating",
                                        "autoGenerate": false,
                                        "rateCount": 5,
                                        "rateValues": [ "ok" ],
                                        "title": { "en": "This study aims to identify key stakeholders in each region of the FARMWISE project and create a visual map to better understand their roles and interactions. This mapping is crucial for enhancing collaboration, pinpointing important players, and guiding decisions to improve water management and build more resilient territories.<br><br><strong>The Universitat Politécnica de València (Spain) is the organization in charge</strong> of carrying out this research.<br><br>You will be asked to answer some questions in this regard, as well as information about yourself and the organization you represent. <strong>Your answers will be completely confidential.</strong> It may take you 20-25 minutes to complete the survey. Your participation in this research is voluntary and you will not receive any financial compensation for your participation. You have the right to withdraw at any time during the study.<br><br>After the study has finished, we will share the “map” within the respondents.",
                                                  "fi": "Tämän tutkimuksen tavoitteena on tunnistaa keskeiset sidosryhmät kullakin FARMWISE-projektin alueella ja luoda visuaalinen kartta heidän roolinsa ja vuorovaikutuksensa ymmärtämiseksi paremmin. Tämä kartoitus on ratkaisevan tärkeä yhteistyön tehostamiseksi, tärkeiden toimijoiden tunnistamiseksi ja ohjaamaan päätöksiä vesihuollon parantamiseksi ja kestävämpien alueiden rakentamiseksi.<br><br><strong>Espanja Universitat Politécnica de València on organisaatio, joka vastaa</strong> tämän tutkimuksen suorittamisesta.<br><br>Sinua pyydetään vastaamaan joihinkin asiaa koskeviin kysymyksiin sekä tietoja itsestäsi ja edustamastasi organisaatiosta. <strong>Vastauksesi ovat täysin luottamuksellisia.</strong> Kyselyyn vastaaminen voi kestää 20–25 minuuttia. Osallistumisesi tähän tutkimukseen on vapaaehtoista, etkä saa osallistumisestasi taloudellista korvausta. Sinulla on oikeus vetäytyä milloin tahansa tutkimuksen aikana.<br><br>Kun tutkimus on valmis, jaamme „kartan” vastaajien sisällä.",
                                                  "it": "Questo studio mira a identificare gli stakeholder chiave in ogni regione del progetto FARMWISE e a creare una mappa visiva per comprenderne meglio i ruoli e le interazioni. Questa mappatura è fondamentale per migliorare la collaborazione, individuare gli attori importanti e guidare le decisioni per migliorare la gestione delle risorse idriche e costruire territori più resilienti.<br><br><strong> L'Universitat Politécnica de València (Spagna) è l'organizzazione incaricata </strong> di svolgere questa ricerca. <br><br>Ti verrà chiesto di rispondere ad alcune domande a questo proposito, nonché informazioni su di te e sull'organizzazione che rappresenti. <strong>Le tue risposte saranno completamente riservate. </strong>Potrebbero volerci 20-25 minuti per completare il sondaggio. La tua partecipazione a questa ricerca è volontaria e non riceverai alcun compenso finanziario per la tua partecipazione. Hai il diritto di ritirarti in qualsiasi momento durante lo studio.<br><br>Dopo la conclusione dello studio, condivideremo la “mappa” con gli intervistati.",
                                                  "es": "El objetivo de este estudio es identificar a las principales partes interesadas en cada región del proyecto FARMWISE y crear un mapa visual para comprender mejor sus funciones e interacciones. Este mapa es crucial para mejorar la colaboración, identificar a los actores importantes y orientar las decisiones para mejorar la gestión del agua y construir territorios más resilientes.<br><br><strong>La Universitat Politécnica de València (España) es la organización encargada </strong>de llevar a cabo esta investigación.<br><br>Se le pedirá que responda a algunas preguntas al respecto, así como información sobre usted y la organización a la que representa. <strong>Sus respuestas serán totalmente confidenciales.</strong> Completar la encuesta puede llevarle entre 20 y 25 minutos. Su participación en esta investigación es voluntaria y no recibirá ninguna compensación económica por su participación. Tiene derecho a retirarse en cualquier momento del estudio.<br><br>Una vez finalizado el estudio, compartiremos el «mapa» con los encuestados.",
                                                  "nl": "Deze studie is bedoeld om de belangrijkste belanghebbenden in elke regio van het FARMWISE-project te identificeren en een visuele kaart te maken om hun rollen en interacties beter te begrijpen. Deze mapping is cruciaal voor het verbeteren van de samenwerking, het lokaliseren van belangrijke spelers en het begeleiden van beslissingen om het waterbeheer te verbeteren en veerkrachtigere gebieden te bouwen.<br><br><strong>De Universitat Politécnica de València (Spanje) is de organisatie die verantwoordelijk is</strong> voor het uitvoeren van dit onderzoek.<br><br>U wordt gevraagd om een aantal vragen in dit verband te beantwoorden, evenals informatie over uzelf en de organisatie die u vertegenwoordigt. <strong>Uw antwoorden zijn volledig vertrouwelijk. </strong>Het kan 20-25 minuten duren om de enquête in te vullen. Uw deelname aan dit onderzoek is vrijwillig en u ontvangt geen financiële vergoeding voor uw deelname. U hebt het recht om u op elk moment tijdens het onderzoek terug te trekken.<br><br>Na afloop van het onderzoek delen we de “kaart” met de respondenten.",
                                                  "pl": "Niniejsze badanie ma na celu zidentyfikowanie kluczowych interesariuszy w każdym regionie projektu FARMWISE i stworzenie mapy wizualnej, aby lepiej zrozumieć ich role i interakcje. To mapowanie jest kluczowe dla zwiększenia współpracy, wskazania ważnych graczy i kierowania decyzjami w celu poprawy zarządzania wodą i budowania bardziej odpornych terytoriów.<br><br><strong>Universitat Politécnica de València (Hiszpania) jest organizacją odpowiedzialną </strong>za przeprowadzenie tego badania.<br><br>Zostaniesz poproszony o odpowiedź na kilka pytań w tym zakresie, a także o podanie informacji o sobie i organizacji, którą reprezentujesz. <strong>Twoje odpowiedzi będą całkowicie poufne. </strong>Wypełnienie ankiety może zająć Ci 20-25 minut. Twój udział w tym badaniu jest dobrowolny i nie otrzymasz żadnego wynagrodzenia finansowego za swój udział. Masz prawo wycofać się w dowolnym momencie w trakcie badania.<br><br>Po zakończeniu badania udostępnimy „mapę” respondentom.",
                                                  "sw": "Denna studie syftar till att identifiera nyckelintressenter i varje region i FARMWISE-projektet och skapa en visuell karta för att bättre förstå deras roller och interaktioner. Denna kartläggning är avgörande för att förbättra samarbetet, peka ut viktiga aktörer och vägleda beslut för att förbättra vattenförvaltningen och bygga mer motståndskraftiga områden.<br><br><strong>Universitat Politécnica de València (Spanien) är den organisation som ansvarar </strong>för att genomföra denna forskning.<br><br>Du kommer att bli ombedd att svara på några frågor i detta avseende, samt information om dig själv och den organisation du representerar. <strong>Dina svar kommer att vara helt konfidentiella.</strong> Det kan ta dig 20-25 minuter att fylla i undersökningen. Ditt deltagande i denna forskning är frivilligt och du kommer inte att få någon ekonomisk ersättning för ditt deltagande. Du har rätt att dra dig ur när som helst under studien.<br><br>När studien är klar kommer vi att skicka ut resultaten till respondenterna.",
                                                  "ua": "Це дослідження спрямоване на визначення ключових зацікавлених сторін у кожному регіоні проекту FARMWISE та створення візуальної карти для кращого розуміння їхніх ролей та взаємодії. Це відображення має вирішальне значення для покращення співпраці, визначення важливих гравців і прийняття рішень щодо покращення управління водними ресурсами та створення більш стійких територій.<br><br><strong>Університет Politécnica de Valéncia (Іспанія) є організацією, відповідальною </strong>за проведення цього дослідження.<br><br>Вас попросять відповісти на деякі запитання щодо цього, а також надати інформацію про себе та організацію, яку ви представляєте.<strong> Ваші відповіді будуть повністю конфіденційними. </strong>Заповнення опитування може зайняти 20-25 хвилин. Ваша участь у цьому дослідженні є добровільною, і ви не отримаєте жодної фінансової компенсації за свою участь. Ви маєте право відмовитися від участі в будь-який час протягом дослідження.<br><br>Після завершення дослідження ми поділимося “картою” серед респондентів."
                                                },
                                        "isRequired": false
                                      }]
                        },
                        {"type": "panel",
                          "name": "participant_info_p3",
                          "title": { "en": "<strong>Data handling</strong>",
                                    "fi": "<strong>Tietojen käsittely</strong>",
                                    "it": "<strong>Gestione dei dati</strong>",
                                    "es": "<strong>Manejo de datos</strong>",
                                    "nl": "<strong>Gegevensverwerking</strong>",
                                    "pl": "<strong>Przetwarzanie danych</strong>",
                                    "sw": "<strong>Datahantering</strong>",
                                    "ua": "<strong>Обробка даних</strong>"
                                  },
                          "state": "collapsed",
                          "elements": [{"name": "participant_info_p3_text",
                                        "type": "rating",
                                        "autoGenerate": false,
                                        "rateCount": 5,
                                        "rateValues": [ "ok" ],
                                        "title": { "en": "<strong>Regulation (EU) 2016-679 of the European Parliament and of the Council of April 27, 2016</strong>, on the protection of individuals with regard to the processing of personal data and on the free movement of such data, ensures that all information submitted is handled confidentially and in accordance with current data protection regulations. Your responses will not be shared with outside parties and will only be utilized for this study's objectives.",
                                                  "fi": "<strong>Euroopan parlamentin ja neuvoston asetus (EU) 2016-679, annettu 27. huhtikuuta 2016</strong>, yksilöiden suojelusta varmistaa henkilötietojen käsittelyn ja tällaisten tietojen vapaan liikkuvuuden osalta, että kaikkia toimitettuja tietoja käsitellään luottamuksellisesti ja voimassa olevien tietosuojamääräysten mukaisesti. Vastauksiasi ei jaeta ulkopuolisille, ja niitä käytetään vain tämän tutkimuksen tarkoituksiin.",
                                                  "it": "<strong>Il Regolamento (UE) 2016-679 del Parlamento europeo e del Consiglio del 27 aprile 2016</strong>, sulla protezione delle persone fisiche con riguardo al trattamento dei dati personali e sulla libera circolazione di tali dati, garantisce che tutte le informazioni inviate siano gestite in modo confidenziale e in conformità con le attuali normative sulla protezione dei dati. Le tue risposte non saranno condivise con terze parti e saranno utilizzate solo per gli obiettivi di questo studio.",
                                                  "es": "<strong>El Reglamento (UE) 2016-679 del Parlamento Europeo y del Consejo</strong>, de 27 de abril de 2016, relativo a la protección de las personas físicas en lo que respecta al tratamiento de datos personales y a la libre circulación de estos datos, garantiza que toda la información enviada se tratará de forma confidencial y de conformidad con la normativa vigente en materia de protección de datos. Sus respuestas no se compartirán con terceros y solo se utilizarán para los objetivos de este estudio.",
                                                  "nl": "<strong>Verordening (EU) 2016-679 van het Europees Parlement en de Raad van 27 april 2016</strong>, betreffende de bescherming van natuurlijke personen in verband met de verwerking van persoonsgegevens en betreffende het vrije verkeer van die gegevens, zorgt ervoor dat alle ingediende informatie vertrouwelijk en in overeenstemming met de huidige regelgeving inzake gegevensbescherming wordt behandeld. Uw antwoorden worden niet gedeeld met externe partijen en worden alleen gebruikt voor de doelstellingen van dit onderzoek.",
                                                  "pl": "<strong>Rozporządzenie (UE) 2016-679 Parlamentu Europejskiego i Rady z dnia 27 kwietnia 2016 r.</strong> w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i swobodnym przepływem takich danych zapewnia, że wszystkie przesłane informacje są traktowane poufnie i zgodnie z obowiązującymi przepisami o ochronie danych. Twoje odpowiedzi nie zostaną udostępnione stronom zewnętrznym i zostaną wykorzystane wyłącznie do celów tego badania.",
                                                  "sw": "<strong>Europaparlamentets och rådets förordning (EU) 2016-679 av den 27 april 2016</strong>, om skydd av individer med hänsyn till behandling av personuppgifter och om fri rörlighet för sådana uppgifter, säkerställer att all information som lämnas hanteras konfidentiellt och i enlighet med gällande dataskyddsbestämmelser. Dina svar kommer inte att delas med externa parter och kommer endast att användas för denna studies syften.",
                                                  "ua": "<strong>Регламент (ЄС) 2016-679 Європейського парламенту та Ради від 27 квітня 2016 р. </strong>щодо захисту осіб щодо обробки персональних даних і вільного переміщення таких даних забезпечує конфіденційність обробки всієї наданої інформації відповідно до чинних норм захисту даних. Ваші відповіді не будуть передані стороннім особам і використовуватимуться лише для цілей цього дослідження."
                                                },
                                        "isRequired": false
                                      }]
                        },
                        {"type": "panel",
                          "name": "participant_info_p4",
                          "title": { "en": "<strong>Risks and benefits</strong>",
                                    "fi": "<strong>Riskit ja edut</strong>",
                                    "it": "<strong>Rischi e benefici</strong>",
                                    "es": "<strong>Riesgos y beneficios</strong>",
                                    "nl": "<strong>Risico's en voordelen</strong>",
                                    "pl": "<strong>Ryzyko i korzyści</strong>",
                                    "sw": "<strong>Risker och fördelar</strong>",
                                    "ua": "<strong>Ризики та переваги</strong>"
                                  },
                          "state": "collapsed",
                          "elements": [{"name": "participant_info_p4_text",
                                        "type": "rating",
                                        "autoGenerate": false,
                                        "rateCount": 5,
                                        "rateValues": [ "ok" ],
                                        "title": { "en": "There are no physical or emotional risks associated with participation. However, a potential risk may be unauthorized disclosure of confidential information, so strict confidentiality and data protection measures will be taken. To protect your privacy, partial anonymization will be made in the publications using pseudonyms and the real identity of the respondents will be kept confidential when handling the data. There are no direct benefits to individual participants, but their contribution will help to improve the understanding of urban food systems and their relationship to sustainable food security.",
                                                  "fi": "Osallistumiseen ei liity fyysisiä tai henkisiä riskejä. Mahdollinen riski voi kuitenkin olla luottamuksellisten tietojen luvaton luovuttaminen, joten tiukat luottamuksellisuus- ja tietosuojatoimenpiteet noudatetaan. Yksityisyytesi suojaamiseksi julkaisuissa tehdään osittainen anonymisointi pseudonyymeillä ja vastaajien todellinen henkilöllisyys pidetään luottamuksellisina tietoja käsiteltäessä. Yksittäisille osallistujille ei ole suoria etuja, mutta heidän panoksensa auttaa ymmärtämään kaupunkien ruokajärjestelmiä ja niiden suhdetta kestävään elintarviketurvaan.",
                                                  "it": "Non vi sono rischi fisici o emotivi associati alla partecipazione. Tuttavia, un rischio potenziale potrebbe essere la divulgazione non autorizzata di informazioni riservate, pertanto verranno adottate misure rigorose di riservatezza e protezione dei dati. Per proteggere la tua privacy, verrà effettuata un'anonimizzazione parziale nelle pubblicazioni utilizzando pseudonimi e la vera identità degli intervistati verrà mantenuta riservata durante la gestione dei dati. Non ci sono vantaggi diretti per i singoli partecipanti, ma il loro contributo contribuirà a migliorare la comprensione dei sistemi alimentari urbani e della loro relazione con la sicurezza alimentare sostenibile.",
                                                  "es": "No existen riesgos físicos ni emocionales asociados a la participación. Sin embargo, un riesgo potencial puede ser la divulgación no autorizada de información confidencial, por lo que se tomarán estrictas medidas de confidencialidad y protección de datos. Para proteger su privacidad, en las publicaciones se realizará una anonimización parcial mediante seudónimos y se mantendrá la confidencialidad de la identidad real de los encuestados al manejar los datos. No hay beneficios directos para los participantes individuales, pero su contribución ayudará a mejorar la comprensión de los sistemas alimentarios urbanos y su relación con la seguridad alimentaria sostenible.",
                                                  "nl": "Er zijn geen fysieke of emotionele risico's verbonden aan deelname. Een potentieel risico kan echter ongeoorloofde openbaarmaking van vertrouwelijke informatie zijn, dus er worden strikte vertrouwelijkheids- en gegevensbeschermingsmaatregelen genomen. Om uw privacy te beschermen, wordt er gedeeltelijke anonimisering toegepast in de publicaties met behulp van pseudoniemen en wordt de echte identiteit van de respondenten vertrouwelijk behandeld bij het verwerken van de gegevens. Er zijn geen directe voordelen voor individuele deelnemers, maar hun bijdrage zal helpen het begrip van stedelijke voedselsystemen en hun relatie tot duurzame voedselzekerheid te verbeteren",
                                                  "pl": "Z udziałem nie wiążą się żadne ryzyka fizyczne ani emocjonalne. Jednak potencjalnym ryzykiem może być nieautoryzowane ujawnienie poufnych informacji, dlatego zostaną podjęte ścisłe środki poufności i ochrony danych. Aby chronić Twoją prywatność, w publikacjach zostanie zastosowana częściowa anonimizacja przy użyciu pseudonimów, a prawdziwa tożsamość respondentów pozostanie poufna podczas przetwarzania danych. Poszczególni uczestnicy nie odniosą żadnych bezpośrednich korzyści, ale ich wkład pomoże w lepszym zrozumieniu miejskich systemów żywnościowych i ich związku ze zrównoważonym bezpieczeństwem żywnościowym.",
                                                  "sw": "Det finns inga fysiska eller känslomässiga risker förknippade med deltagande. En potentiell risk kan dock vara obehörigt röjande av konfidentiell information, så strikt sekretess och dataskyddsåtgärder kommer att vidtas. För att skydda din integritet kommer partiell anonymisering att göras i publikationerna med hjälp av pseudonymer och respondenternas verkliga identitet kommer att hållas konfidentiell vid hantering av uppgifterna. Det finns inga direkta fördelar för enskilda deltagare, men deras bidrag kommer att bidra till att förbättra förståelsen av urbana livsmedelssystem och deras relation till hållbar livsmedelssäkerhet.",
                                                  "ua": "Участь не пов’язана з фізичними чи емоційними ризиками. Проте потенційним ризиком може бути несанкціоноване розголошення конфіденційної інформації, тому будуть вжиті заходи суворої конфіденційності та захисту даних. Для захисту вашої конфіденційності в публікаціях буде здійснено часткову анонімізацію з використанням псевдонімів, а справжні особи респондентів залишатимуться в таємниці при обробці даних. Немає прямої вигоди для окремих учасників, але їхній внесок допоможе покращити розуміння міських продовольчих систем та їхнього зв’язку зі стійкою продовольчою безпекою."
                                                },
                                        "isRequired": false
                                      }]
                        },
                        {"type": "panel",
                        "name": "participant_info_p5",
                        "title": { "en": "<strong>Information and responsibility</strong>",
                                  "fi": "<strong>Tiedot ja vastuu</strong>",
                                  "it": "<strong>Informazioni e responsabilità</strong>",
                                  "es": "<strong>Información y responsabilidad</strong>",
                                  "nl": "<strong>Informatie en verantwoordelijkheid</strong>",
                                  "pl": "<strong>Informacje i odpowiedzialność</strong>",
                                  "sw": "<strong>Information och ansvar</strong>",
                                  "ua": "<strong>Інформація та відповідальність</strong>"
                                },
                        "state": "collapsed",
                        "elements": [{"name": "participant_info_p5_text",
                                      "type": "rating",
                                      "autoGenerate": false,
                                      "rateCount": 5,
                                      "rateValues": [ "ok" ],
                                      "title": { "en": "The data controller is the Universitat Politècnica de València, in the person of Guillermo Palau Salvador, researcher responsible for the FARMWISE project. The rights of access, rectification, deletion, limitation, portability or opposition are guaranteed at any time. The way to exercise them will be by request and mail to guipasal@agf.upv.es.",
                                                "fi": "Rekisterinpitäjä on Universitat Politècnica de València, FARMWISE-projektista vastaavan tutkijan Guillermo Palau Salvadorin henkilössä. Käyttö-, oikaisu-, poistamis-, rajoittamis-, siirrettävyys- tai vastustusoikeudet taataan milloin tahansa. Harjoittelutapa on pyynnöstä ja postitse osoitteeseen guipasal@agf.upv.es.",
                                                "it": "Il titolare del trattamento dei dati è l'Universitat Politècnica de València, nella persona di Guillermo Palau Salvador, ricercatore responsabile del progetto FARMWISE. I diritti di accesso, rettifica, cancellazione, limitazione, portabilità o opposizione sono garantiti in qualsiasi momento. Il modo per esercitarli sarà tramite richiesta e posta a guipasal@agf.upv.es.",
                                                "es": "El responsable del fichero es la Universitat Politècnica de València, en la persona de Guillermo Palau Salvador, investigador responsable del proyecto FARMWISE. Se garantizan los derechos de acceso, rectificación, supresión, limitación, portabilidad u oposición en cualquier momento. La forma de ejercitarlos será mediante solicitud y correo a guipasal@agf.upv.es.",
                                                "nl": "De gegevensbeheerder is de Universitat Politècnica de València, in de persoon van Guillermo Palau Salvador, onderzoeker die verantwoordelijk is voor het FARMWISE-project. De rechten op toegang, rectificatie, verwijdering, beperking, overdraagbaarheid of verzet worden te allen tijde gegarandeerd. De manier om ze uit te oefenen is door een verzoek in te dienen en een e-mail te sturen naar guipasal@agf.upv.es.",
                                                "pl": "Administratorem danych jest Universitat Politècnica de València, w osobie Guillermo Palau Salvador, badacza odpowiedzialnego za projekt FARMWISE. Prawa dostępu, sprostowania, usunięcia, ograniczenia, przenoszenia lub sprzeciwu są gwarantowane w dowolnym momencie. Można je wykorzystać na żądanie i wysłać pocztą na adres guipasal@agf.upv.es.",
                                                "sw": "Den personuppgiftsansvarige är Universitat Politècnica de València, i egenskap av Guillermo Palau Salvador, forskare ansvarig för FARMWISE-projektet. Rätten till åtkomst, rättelse, radering, begränsning, portabilitet eller opposition garanteras när som helst. Sättet att utöva dem kommer att vara genom förfrågan och mail till guipasal@agf.upv.es.",
                                                "ua": "Контролером даних є Політичний університет Валенсії в особі Гільєрмо Палау Сальвадора, дослідника, відповідального за проект FARMWISE. Права доступу, виправлення, видалення, обмеження, перенесення або заперечення гарантуються в будь-який час. Виконувати їх можна буде за запитом і електронною поштою на guipasal@agf.upv.es."
                                              },
                                      "isRequired": false
                                    }]
                        },
                        {"name": "consentQuestion",
                          "type": "rating",
                          "autoGenerate": false,
                          "rateCount": 5,
                          "rateValues": [ {"value": "agree",
                                          "text":{ "en": "AGREE and continue to the survey",
                                                  "fi": "HYVÄKSY ja jatka kyselyyn",
                                                  "it": "ACCETTO e continua con il sondaggio",
                                                  "es": "ACEPTAR y pasar a la encuesta",
                                                  "nl": "AKKOORD en ga verder naar de enquête",
                                                  "pl": "ZGADZAM SIĘ i kontynuuję ankietę",
                                                  "sw": "MEDGE och fortsätt till undersökningen",
                                                  "ua": " ПОГОДИТИСЯ та продовжуйте опитування"
                                                }
                                          }, 
                                          { "value": "disagree",
                                            "text":{ "en": "DISAGREE and quit",
                                                    "fi": "ERITII ja lopeta",
                                                    "it": "NON ACCETTARE e concludere",
                                                    "es": "NO ESTAR DE ACUERDO y terminar",
                                                    "nl": "ONEENS en klaar",
                                                    "pl": "NIE ZGADZAM SIĘ i zakończ",
                                                    "sw": "Håller inte med och avsluta",
                                                    "ua": " НЕ ПОГОДИТИСЯ та закінчити "
                                                  }
                                          }
                                        ],
                          "title": { "en": "<ul><li>I understand the information set forth above.</li> <li>I confirm that my participation in the study is voluntary.</li> <li>I am aware that I may choose to terminate my participation at any time for any reason without my responses being used for the study.</li></ul>",
                                    "fi": "<ul><li>Ymmärrän yllä olevat tiedot.</li> <li>Vahvistan, että osallistumiseni tutkimukseen on vapaaehtoista.</li> <li>Olen tietoinen siitä, että voin päättää lopettaa osallistumiseni milloin tahansa mistä tahansa syystä ilman, että vastauksiani käytetään tutkimukseen.</li></ul>",
                                    "it": "<ul><li>Ho compreso le informazioni sopra riportate.</li> <li>Confermo che la mia partecipazione allo studio è volontaria.</li> <li>Sono consapevole che potrei decidere di interrompere la mia partecipazione in qualsiasi momento e per qualsiasi motivo, senza che le mie risposte vengano utilizzate per lo studio.</li></ul>",
                                    "es": "<ul><li>Entiendo la información expuesta anteriormente.</li> <li>Confirmo que mi participación en el estudio es voluntaria.</li> <li>Soy consciente de que puedo optar por finalizar mi participación en cualquier momento y por cualquier motivo sin que mis respuestas se utilicen para el estudio.</li></ul>",
                                    "nl": "<ul><li>Ik begrijp de bovenstaande informatie.</li> <li>Ik bevestig dat mijn deelname aan het onderzoek vrijwillig is.</li> <li>Ik ben mij ervan bewust dat ik mijn deelname op elk moment en om welke reden dan ook kan beëindigen zonder dat mijn antwoorden voor het onderzoek worden gebruikt.</li></ul>",
                                    "pl": "<ul><li>Rozumiem informacje zawarte powyżej.</li> <li>Potwierdzam, że mój udział w badaniu jest dobrowolny.</li> <li>Jestem świadomy/świadoma, że mogę w każdej chwili z dowolnego powodu zrezygnować z udziału w badaniu, a moje odpowiedzi nie zostaną wykorzystane na potrzeby badania.</li></ul>",
                                    "sw": "<ul><li>Jag förstår informationen ovan.</li> <li>Jag bekräftar att mitt deltagande i studien är frivilligt.</li> <li>Jag är medveten om att jag kan välja att avsluta mitt deltagande när som helst av vilken anledning som helst utan att mina svar används för studien.</li></ul>",
                                    "ua": "<ul><li>Я розумію інформацію, викладену вище.</li> <li>Я підтверджую, що моя участь у дослідженні є добровільною.</li> <li>Я усвідомлюю, що можу припинити свою участь у будь-який час із будь-якої причини без використання моїх відповідей для дослідження.</li></ul>"
                                  },
                          "isRequired": true
                        }
                      ]
          },
          { visibleIf: "{consentQuestion} == 'agree'",
            name: "section1",
            title: { "en": "Section 1. General information",
                    "fi": "Osa 1. Yleistä",
                    "it": "Sezione 1. Informazioni generali",
                    "es": "Sección 1. Información general",
                    "nl": "Hoofdstuk 1. Algemene informatie",
                    "pl": "Rozdział 1. Informacje ogólne",
                    "sw": "Avsnitt 1. Allmän information",
                    "ua": " Розділ 1. Загальні відомості "
                  },
            elements: [{"name": "nameSelf",
                        "type": "text",
                        "title": { "en": "Name of your organization",
                                  "fi": "Organisaatiosi nimi",
                                  "it": "Nome della tua organizzazione",
                                  "es": "Nombre de su organización",
                                  "nl": "Naam van uw organisatie",
                                  "pl": "Nazwa Twojej organizacji",
                                  "sw": "Namn på din organisation",
                                  "ua": "Назва вашої організації"
                                },
                        "maxLength": 200,
                        "isRequired": true
                        }, 
                        { "type": "dropdown",
                          "name": "countrySelf",
                          "title": { "en": "Country",
                                    "fi": "Maa",
                                    "it": "Paese",
                                    "es": "País",
                                    "nl": "Land",
                                    "pl": "Kraj",
                                    "sw": "Land",
                                    "ua": " Країна"
                                  },
                          "isRequired": true,
                          "showNoneItem": false,
                          "showOtherItem": true,
                          "choices":  [ {"value": "Finland",
                                        "text":{"en": "Finland",
                                                "fi": "Suomi",
                                                "it": "Finlandia",
                                                "es": "Finlandia",
                                                "nl": "Finland",
                                                "pl": "Finlandia",
                                                "sw": "Finland",
                                                "ua": "Фінляндія"
                                              }
                                        }, 
                                        {"value": "Italy",
                                        "text":{"en": "Italy",
                                                "fi": "Italia",
                                                "it": "Italia",
                                                "es": "Italia",
                                                "nl": "Italië",
                                                "pl": "Włochy",
                                                "sw": "Italien",
                                                "ua": "Італія"
                                              }
                                        }, 
                                        {"value": "Netherlands",
                                        "text":{"en": "Netherlands",
                                                "fi": "Alankomaat",
                                                "it": "Paesi Bassi",
                                                "es": "Países Bajos",
                                                "nl": "Nederland",
                                                "pl": "Niderlandy",
                                                "sw": "Nederländerna",
                                                "ua": "Нідерланди"
                                              }
                                        }, 
                                        {"value": "Poland",
                                        "text":{"en": "Poland",
                                                "fi": "Puola",
                                                "it": "Polonia",
                                                "es": "Polonia",
                                                "nl": "Polen",
                                                "pl": "Polska",
                                                "sw": "Polen",
                                                "ua": "Польща"
                                              }
                                        }, 
                                        {"value": "Spain",
                                        "text":{"en": "Spain",
                                                "fi": "Espanja",
                                                "it": "Spagna",
                                                "es": "España",
                                                "nl": "Spanje",
                                                "pl": "Hiszpania",
                                                "sw": "Spanien",
                                                "ua": "Іспанія"
                                              }
                                        }, 
                                        {"value": "Sweden",
                                        "text":{"en": "Sweden",
                                                "fi": "Ruotsi",
                                                "it": "Svezia",
                                                "es": "Suecia",
                                                "nl": "Zweden",
                                                "pl": "Szwecja",
                                                "sw": "Sverige",
                                                "ua": "Швеція"
                                              }
                                        }, 
                                        {"value": "Ukraine",
                                        "text":{"en": "Ukraine",
                                                "fi": "Ukraina",
                                                "it": "Ucraina",
                                                "es": "Ucrania",
                                                "nl": "Oekraïne",
                                                "pl": "Ukraina",
                                                "sw": "Ukraina",
                                                "ua": "Україна"
                                              }
                                        }, 
                                        {"value": "United Kingdom",
                                        "text":{"en": "United Kingdom",
                                                "fi": "Yhdistynyt kuningaskunta",
                                                "it": "Regno Unito",
                                                "es": "Reino Unido",
                                                "nl": "Verenigd Koninkrijk",
                                                "pl": "Wielka Brytania",
                                                "sw": "Storbritannien",
                                                "ua": "Великобританія" 
                                              }
                                        }
                                      ]
                        }, 
                        { "name": "regionSelf",
                          "visibleIf": "{countrySelf} anyof ['Finland', 'Finlandia', 'Фінляндія', 'Suomi', 'Italy', 'Włochy', 'Italien', 'Італія', 'Italia', 'Italië', 'Netherlands', 'Holandia', 'Nederländerna', 'Нідерланди', 'Alankomaat', 'Paesi Bassi', 'Países Bajos', 'Nederland', 'Poland', 'Polska', 'Polen', 'Польща', 'Puola', 'Polonia', 'Spain', 'Hiszpania', 'Spanien', 'Іспанія', 'Espanja', 'Spagna', 'España', 'Spanje', 'Sweden', 'Szwecja', 'Sverige', 'Швеція', 'Ruotsi', 'Svezia', 'Suecia', 'Zweden', 'Ukraine', 'Ukraina', 'Україна', 'Ucraina', 'Ucrania', 'Oekraïne', 'United Kingdom', 'Wielka Brytania', 'Storbritannien', 'Великобританія', 'Yhdistynyt kuningaskunta', 'Regno Unito', 'Reino Unido', 'Verenigd Koninkrijk']",
                          "type": "dropdown",
                          "title": { "en":"Region",
                                    "fi": "Alue",
                                    "it": "Regione",
                                    "es": "Región",
                                    "nl": "Provincie",
                                    "pl": "Region",
                                    "sw": "Region",
                                    "ua": "Регіон"
                                  },
                          "isRequired": true,
                          "showNoneItem": false,
                          "showOtherItem": false,
                          "choices": [{ "value": "Derbyshire and Nottinghamshire", "visibleIf": "{countrySelf} anyof ['United Kingdom', 'Wielka Brytania', 'Storbritannien', 'Великобританія', 'Yhdistynyt kuningaskunta', 'Regno Unito', 'Reino Unido', 'Verenigd Koninkrijk']" },
                                      { "value": "Leicestershire, Rutland and Northamptonshire", "visibleIf": "{countrySelf} anyof ['United Kingdom', 'Wielka Brytania', 'Storbritannien', 'Великобританія', 'Yhdistynyt kuningaskunta', 'Regno Unito', 'Reino Unido', 'Verenigd Koninkrijk']" },
                                      { "value": "Lincolnshire", "visibleIf": "{countrySelf} anyof ['United Kingdom', 'Wielka Brytania', 'Storbritannien', 'Великобританія', 'Yhdistynyt kuningaskunta', 'Regno Unito', 'Reino Unido', 'Verenigd Koninkrijk']" },
                                      { "value": "East Anglia", "visibleIf": "{countrySelf} anyof ['United Kingdom', 'Wielka Brytania', 'Storbritannien', 'Великобританія', 'Yhdistynyt kuningaskunta', 'Regno Unito', 'Reino Unido', 'Verenigd Koninkrijk']" },
                                      { "value": "Bedfordshire and Hertfordshire", "visibleIf": "{countrySelf} anyof ['United Kingdom', 'Wielka Brytania', 'Storbritannien', 'Великобританія', 'Yhdistynyt kuningaskunta', 'Regno Unito', 'Reino Unido', 'Verenigd Koninkrijk']" },
                                      { "value": "Essex", "visibleIf": "{countrySelf} anyof ['United Kingdom', 'Wielka Brytania', 'Storbritannien', 'Великобританія', 'Yhdistynyt kuningaskunta', 'Regno Unito', 'Reino Unido', 'Verenigd Koninkrijk']" },
                                      { "value": "Inner London – West", "visibleIf": "{countrySelf} anyof ['United Kingdom', 'Wielka Brytania', 'Storbritannien', 'Великобританія', 'Yhdistynyt kuningaskunta', 'Regno Unito', 'Reino Unido', 'Verenigd Koninkrijk']" },
                                      { "value": "Inner London – East", "visibleIf": "{countrySelf} anyof ['United Kingdom', 'Wielka Brytania', 'Storbritannien', 'Великобританія', 'Yhdistynyt kuningaskunta', 'Regno Unito', 'Reino Unido', 'Verenigd Koninkrijk']" },
                                      { "value": "Outer London – East and North East", "visibleIf": "{countrySelf} anyof ['United Kingdom', 'Wielka Brytania', 'Storbritannien', 'Великобританія', 'Yhdistynyt kuningaskunta', 'Regno Unito', 'Reino Unido', 'Verenigd Koninkrijk']" },
                                      { "value": "Outer London – South", "visibleIf": "{countrySelf} anyof ['United Kingdom', 'Wielka Brytania', 'Storbritannien', 'Великобританія', 'Yhdistynyt kuningaskunta', 'Regno Unito', 'Reino Unido', 'Verenigd Koninkrijk']" },
                                      { "value": "Outer London – West and North West", "visibleIf": "{countrySelf} anyof ['United Kingdom', 'Wielka Brytania', 'Storbritannien', 'Великобританія', 'Yhdistynyt kuningaskunta', 'Regno Unito', 'Reino Unido', 'Verenigd Koninkrijk']" },
                                      { "value": "Tees Valley and Durham", "visibleIf": "{countrySelf} anyof ['United Kingdom', 'Wielka Brytania', 'Storbritannien', 'Великобританія', 'Yhdistynyt kuningaskunta', 'Regno Unito', 'Reino Unido', 'Verenigd Koninkrijk']" },
                                      { "value": "Northumberland and Tyne and Wear", "visibleIf": "{countrySelf} anyof ['United Kingdom', 'Wielka Brytania', 'Storbritannien', 'Великобританія', 'Yhdistynyt kuningaskunta', 'Regno Unito', 'Reino Unido', 'Verenigd Koninkrijk']" },
                                      { "value": "Cumbria", "visibleIf": "{countrySelf} anyof ['United Kingdom', 'Wielka Brytania', 'Storbritannien', 'Великобританія', 'Yhdistynyt kuningaskunta', 'Regno Unito', 'Reino Unido', 'Verenigd Koninkrijk']" },
                                      { "value": "Greater Manchester", "visibleIf": "{countrySelf} anyof ['United Kingdom', 'Wielka Brytania', 'Storbritannien', 'Великобританія', 'Yhdistynyt kuningaskunta', 'Regno Unito', 'Reino Unido', 'Verenigd Koninkrijk']" },
                                      { "value": "Lancashire", "visibleIf": "{countrySelf} anyof ['United Kingdom', 'Wielka Brytania', 'Storbritannien', 'Великобританія', 'Yhdistynyt kuningaskunta', 'Regno Unito', 'Reino Unido', 'Verenigd Koninkrijk']" },
                                      { "value": "Cheshire", "visibleIf": "{countrySelf} anyof ['United Kingdom', 'Wielka Brytania', 'Storbritannien', 'Великобританія', 'Yhdistynyt kuningaskunta', 'Regno Unito', 'Reino Unido', 'Verenigd Koninkrijk']" },
                                      { "value": "Berkshire, Buckinghamshire and Oxfordshire", "visibleIf": "{countrySelf} anyof ['United Kingdom', 'Wielka Brytania', 'Storbritannien', 'Великобританія', 'Yhdistynyt kuningaskunta', 'Regno Unito', 'Reino Unido', 'Verenigd Koninkrijk']" },
                                      { "value": "Surrey, East and West Sussex", "visibleIf": "{countrySelf} anyof ['United Kingdom', 'Wielka Brytania', 'Storbritannien', 'Великобританія', 'Yhdistynyt kuningaskunta', 'Regno Unito', 'Reino Unido', 'Verenigd Koninkrijk']" },
                                      { "value": "Hampshire and Isle of Wight", "visibleIf": "{countrySelf} anyof ['United Kingdom', 'Wielka Brytania', 'Storbritannien', 'Великобританія', 'Yhdistynyt kuningaskunta', 'Regno Unito', 'Reino Unido', 'Verenigd Koninkrijk']" },
                                      { "value": "Kent", "visibleIf": "{countrySelf} anyof ['United Kingdom', 'Wielka Brytania', 'Storbritannien', 'Великобританія', 'Yhdistynyt kuningaskunta', 'Regno Unito', 'Reino Unido', 'Verenigd Koninkrijk']" },
                                      { "value": "Gloucestershire, Wiltshire and Bath/Bristol area", "visibleIf": "{countrySelf} anyof ['United Kingdom', 'Wielka Brytania', 'Storbritannien', 'Великобританія', 'Yhdistynyt kuningaskunta', 'Regno Unito', 'Reino Unido', 'Verenigd Koninkrijk']" },
                                      { "value": "Dorset and Somerset", "visibleIf": "{countrySelf} anyof ['United Kingdom', 'Wielka Brytania', 'Storbritannien', 'Великобританія', 'Yhdistynyt kuningaskunta', 'Regno Unito', 'Reino Unido', 'Verenigd Koninkrijk']" },
                                      { "value": "Cornwall and Isles of Scilly", "visibleIf": "{countrySelf} anyof ['United Kingdom', 'Wielka Brytania', 'Storbritannien', 'Великобританія', 'Yhdistynyt kuningaskunta', 'Regno Unito', 'Reino Unido', 'Verenigd Koninkrijk']" },
                                      { "value": "Devon", "visibleIf": "{countrySelf} anyof ['United Kingdom', 'Wielka Brytania', 'Storbritannien', 'Великобританія', 'Yhdistynyt kuningaskunta', 'Regno Unito', 'Reino Unido', 'Verenigd Koninkrijk']" },
                                      { "value": "Herefordshire, Worcestershire and Warwickshire", "visibleIf": "{countrySelf} anyof ['United Kingdom', 'Wielka Brytania', 'Storbritannien', 'Великобританія', 'Yhdistynyt kuningaskunta', 'Regno Unito', 'Reino Unido', 'Verenigd Koninkrijk']" },
                                      { "value": "Shropshire and Staffordshire", "visibleIf": "{countrySelf} anyof ['United Kingdom', 'Wielka Brytania', 'Storbritannien', 'Великобританія', 'Yhdistynyt kuningaskunta', 'Regno Unito', 'Reino Unido', 'Verenigd Koninkrijk']" },
                                      { "value": "West Midlands", "visibleIf": "{countrySelf} anyof ['United Kingdom', 'Wielka Brytania', 'Storbritannien', 'Великобританія', 'Yhdistynyt kuningaskunta', 'Regno Unito', 'Reino Unido', 'Verenigd Koninkrijk']" },
                                      { "value": "East Yorkshire and Northern Lincolnshire", "visibleIf": "{countrySelf} anyof ['United Kingdom', 'Wielka Brytania', 'Storbritannien', 'Великобританія', 'Yhdistynyt kuningaskunta', 'Regno Unito', 'Reino Unido', 'Verenigd Koninkrijk']" },
                                      { "value": "North Yorkshire", "visibleIf": "{countrySelf} anyof ['United Kingdom', 'Wielka Brytania', 'Storbritannien', 'Великобританія', 'Yhdistynyt kuningaskunta', 'Regno Unito', 'Reino Unido', 'Verenigd Koninkrijk']" },
                                      { "value": "South Yorkshire", "visibleIf": "{countrySelf} anyof ['United Kingdom', 'Wielka Brytania', 'Storbritannien', 'Великобританія', 'Yhdistynyt kuningaskunta', 'Regno Unito', 'Reino Unido', 'Verenigd Koninkrijk']" },
                                      { "value": "West Yorkshire", "visibleIf": "{countrySelf} anyof ['United Kingdom', 'Wielka Brytania', 'Storbritannien', 'Великобританія', 'Yhdistynyt kuningaskunta', 'Regno Unito', 'Reino Unido', 'Verenigd Koninkrijk']" },
                                      { "value": "Merseyside", "visibleIf": "{countrySelf} anyof ['United Kingdom', 'Wielka Brytania', 'Storbritannien', 'Великобританія', 'Yhdistynyt kuningaskunta', 'Regno Unito', 'Reino Unido', 'Verenigd Koninkrijk']" },
                                      { "value": "Eastern Scotland", "visibleIf": "{countrySelf} anyof ['United Kingdom', 'Wielka Brytania', 'Storbritannien', 'Великобританія', 'Yhdistynyt kuningaskunta', 'Regno Unito', 'Reino Unido', 'Verenigd Koninkrijk']" },
                                      { "value": "South Western Scotland", "visibleIf": "{countrySelf} anyof ['United Kingdom', 'Wielka Brytania', 'Storbritannien', 'Великобританія', 'Yhdistynyt kuningaskunta', 'Regno Unito', 'Reino Unido', 'Verenigd Koninkrijk']" },
                                      { "value": "North Eastern Scotland", "visibleIf": "{countrySelf} anyof ['United Kingdom', 'Wielka Brytania', 'Storbritannien', 'Великобританія', 'Yhdistynyt kuningaskunta', 'Regno Unito', 'Reino Unido', 'Verenigd Koninkrijk']" },
                                      { "value": "Highlands and Islands", "visibleIf": "{countrySelf} anyof ['United Kingdom', 'Wielka Brytania', 'Storbritannien', 'Великобританія', 'Yhdistynyt kuningaskunta', 'Regno Unito', 'Reino Unido', 'Verenigd Koninkrijk']" },
                                      { "value": "West Wales and The Valleys", "visibleIf": "{countrySelf} anyof ['United Kingdom', 'Wielka Brytania', 'Storbritannien', 'Великобританія', 'Yhdistynyt kuningaskunta', 'Regno Unito', 'Reino Unido', 'Verenigd Koninkrijk']" },
                                      { "value": "East Wales", "visibleIf": "{countrySelf} anyof ['United Kingdom', 'Wielka Brytania', 'Storbritannien', 'Великобританія', 'Yhdistynyt kuningaskunta', 'Regno Unito', 'Reino Unido', 'Verenigd Koninkrijk']" },
                                      { "value": "Northern Ireland", "visibleIf": "{countrySelf} anyof ['United Kingdom', 'Wielka Brytania', 'Storbritannien', 'Великобританія', 'Yhdistynyt kuningaskunta', 'Regno Unito', 'Reino Unido', 'Verenigd Koninkrijk']" },
                                      { "value": "Buckinghamshire CC", "visibleIf": "{countrySelf} anyof ['United Kingdom', 'Wielka Brytania', 'Storbritannien', 'Великобританія', 'Yhdistynyt kuningaskunta', 'Regno Unito', 'Reino Unido', 'Verenigd Koninkrijk']" },
                                      { "value": "Northamptonshire CC", "visibleIf": "{countrySelf} anyof ['United Kingdom', 'Wielka Brytania', 'Storbritannien', 'Великобританія', 'Yhdistynyt kuningaskunta', 'Regno Unito', 'Reino Unido', 'Verenigd Koninkrijk']" },
                                      { "value": "Warwickshire CC", "visibleIf": "{countrySelf} anyof ['United Kingdom', 'Wielka Brytania', 'Storbritannien', 'Великобританія', 'Yhdistynyt kuningaskunta', 'Regno Unito', 'Reino Unido', 'Verenigd Koninkrijk']" },
                                      { "value": "Gloucestershire CC", "visibleIf": "{countrySelf} anyof ['United Kingdom', 'Wielka Brytania', 'Storbritannien', 'Великобританія', 'Yhdistynyt kuningaskunta', 'Regno Unito', 'Reino Unido', 'Verenigd Koninkrijk']" },
                                      { "value": "Dorset CC", "visibleIf": "{countrySelf} anyof ['United Kingdom', 'Wielka Brytania', 'Storbritannien', 'Великобританія', 'Yhdistynyt kuningaskunta', 'Regno Unito', 'Reino Unido', 'Verenigd Koninkrijk']" },
                                      { "value": "Somerset CC", "visibleIf": "{countrySelf} anyof ['United Kingdom', 'Wielka Brytania', 'Storbritannien', 'Великобританія', 'Yhdistynyt kuningaskunta', 'Regno Unito', 'Reino Unido', 'Verenigd Koninkrijk']" },
                                      { "value": "Devon CC", "visibleIf": "{countrySelf} anyof ['United Kingdom', 'Wielka Brytania', 'Storbritannien', 'Великобританія', 'Yhdistynyt kuningaskunta', 'Regno Unito', 'Reino Unido', 'Verenigd Koninkrijk']" },
                                      { "value": "Cornwall CC", "visibleIf": "{countrySelf} anyof ['United Kingdom', 'Wielka Brytania', 'Storbritannien', 'Великобританія', 'Yhdistynyt kuningaskunta', 'Regno Unito', 'Reino Unido', 'Verenigd Koninkrijk']" },
                                      { "value": "Abruzzo", "visibleIf": "{countrySelf} anyof ['Italy', 'Włochy', 'Italien', 'Італія', 'Italia', 'Italië']" },
                                      { "value": "Basilicata", "visibleIf": "{countrySelf} anyof ['Italy', 'Włochy', 'Italien', 'Італія', 'Italia', 'Italië']" },
                                      { "value": "Calabria", "visibleIf": "{countrySelf} anyof ['Italy', 'Włochy', 'Italien', 'Італія', 'Italia', 'Italië']" },
                                      { "value": "Campania", "visibleIf": "{countrySelf} anyof ['Italy', 'Włochy', 'Italien', 'Італія', 'Italia', 'Italië']" },
                                      { "value": "Emilia-Romagna", "visibleIf": "{countrySelf} anyof ['Italy', 'Włochy', 'Italien', 'Італія', 'Italia', 'Italië']" },
                                      { "value": "Friuli Venezia Giulia", "visibleIf": "{countrySelf} anyof ['Italy', 'Włochy', 'Italien', 'Італія', 'Italia', 'Italië']" },
                                      { "value": "Lazio", "visibleIf": "{countrySelf} anyof ['Italy', 'Włochy', 'Italien', 'Італія', 'Italia', 'Italië']" },
                                      { "value": "Liguria", "visibleIf": "{countrySelf} anyof ['Italy', 'Włochy', 'Italien', 'Італія', 'Italia', 'Italië']" },
                                      { "value": "Lombardia", "visibleIf": "{countrySelf} anyof ['Italy', 'Włochy', 'Italien', 'Італія', 'Italia', 'Italië']" },
                                      { "value": "Marche", "visibleIf": "{countrySelf} anyof ['Italy', 'Włochy', 'Italien', 'Італія', 'Italia', 'Italië']" },
                                      { "value": "Molise", "visibleIf": "{countrySelf} anyof ['Italy', 'Włochy', 'Italien', 'Італія', 'Italia', 'Italië']" },
                                      { "value": "Piemonte", "visibleIf": "{countrySelf} anyof ['Italy', 'Włochy', 'Italien', 'Італія', 'Italia', 'Italië']" },
                                      { "value": "Puglia", "visibleIf": "{countrySelf} anyof ['Italy', 'Włochy', 'Italien', 'Італія', 'Italia', 'Italië']" },
                                      { "value": "Sardegna", "visibleIf": "{countrySelf} anyof ['Italy', 'Włochy', 'Italien', 'Італія', 'Italia', 'Italië']" },
                                      { "value": "Sicilia", "visibleIf": "{countrySelf} anyof ['Italy', 'Włochy', 'Italien', 'Італія', 'Italia', 'Italië']" },
                                      { "value": "Toscana", "visibleIf": "{countrySelf} anyof ['Italy', 'Włochy', 'Italien', 'Італія', 'Italia', 'Italië']" },
                                      { "value": "Trentino-Alto Adige", "visibleIf": "{countrySelf} anyof ['Italy', 'Włochy', 'Italien', 'Італія', 'Italia', 'Italië']" },
                                      { "value": "Umbria", "visibleIf": "{countrySelf} anyof ['Italy', 'Włochy', 'Italien', 'Італія', 'Italia', 'Italië']" },
                                      { "value": "Valle d'Aosta", "visibleIf": "{countrySelf} anyof ['Italy', 'Włochy', 'Italien', 'Італія', 'Italia', 'Italië']" },
                                      { "value": "Veneto", "visibleIf": "{countrySelf} anyof ['Italy', 'Włochy', 'Italien', 'Італія', 'Italia', 'Italië']" },
                                      { "value": "Andalucía", "visibleIf": "{countrySelf} anyof ['Spain', 'Hiszpania', 'Spanien', 'Іспанія', 'Espanja', 'Spagna', 'España', 'Spanje']" },
                                      { "value": "Aragón", "visibleIf": "{countrySelf} anyof ['Spain', 'Hiszpania', 'Spanien', 'Іспанія', 'Espanja', 'Spagna', 'España', 'Spanje']" },
                                      { "value": "Asturias", "visibleIf": "{countrySelf} anyof ['Spain', 'Hiszpania', 'Spanien', 'Іспанія', 'Espanja', 'Spagna', 'España', 'Spanje']" },
                                      { "value": "Islas Baleares", "visibleIf": "{countrySelf} anyof ['Spain', 'Hiszpania', 'Spanien', 'Іспанія', 'Espanja', 'Spagna', 'España', 'Spanje']" },
                                      { "value": "Canarias", "visibleIf": "{countrySelf} anyof ['Spain', 'Hiszpania', 'Spanien', 'Іспанія', 'Espanja', 'Spagna', 'España', 'Spanje']" },
                                      { "value": "Cantabria", "visibleIf": "{countrySelf} anyof ['Spain', 'Hiszpania', 'Spanien', 'Іспанія', 'Espanja', 'Spagna', 'España', 'Spanje']" },
                                      { "value": "Castilla-La Mancha", "visibleIf": "{countrySelf} anyof ['Spain', 'Hiszpania', 'Spanien', 'Іспанія', 'Espanja', 'Spagna', 'España', 'Spanje']" },
                                      { "value": "Castilla y León", "visibleIf": "{countrySelf} anyof ['Spain', 'Hiszpania', 'Spanien', 'Іспанія', 'Espanja', 'Spagna', 'España', 'Spanje']" },
                                      { "value": "Cataluña", "visibleIf": "{countrySelf} anyof ['Spain', 'Hiszpania', 'Spanien', 'Іспанія', 'Espanja', 'Spagna', 'España', 'Spanje']" },
                                      { "value": "Extremadura", "visibleIf": "{countrySelf} anyof ['Spain', 'Hiszpania', 'Spanien', 'Іспанія', 'Espanja', 'Spagna', 'España', 'Spanje']" },
                                      { "value": "Galicia", "visibleIf": "{countrySelf} anyof ['Spain', 'Hiszpania', 'Spanien', 'Іспанія', 'Espanja', 'Spagna', 'España', 'Spanje']" },
                                      { "value": "La Rioja", "visibleIf": "{countrySelf} anyof ['Spain', 'Hiszpania', 'Spanien', 'Іспанія', 'Espanja', 'Spagna', 'España', 'Spanje']" },
                                      { "value": "Madrid", "visibleIf": "{countrySelf} anyof ['Spain', 'Hiszpania', 'Spanien', 'Іспанія', 'Espanja', 'Spagna', 'España', 'Spanje']" },
                                      { "value": "Murcia", "visibleIf": "{countrySelf} anyof ['Spain', 'Hiszpania', 'Spanien', 'Іспанія', 'Espanja', 'Spagna', 'España', 'Spanje']" },
                                      { "value": "Navarra", "visibleIf": "{countrySelf} anyof ['Spain', 'Hiszpania', 'Spanien', 'Іспанія', 'Espanja', 'Spagna', 'España', 'Spanje']" },
                                      { "value": "País Vasco", "visibleIf": "{countrySelf} anyof ['Spain', 'Hiszpania', 'Spanien', 'Іспанія', 'Espanja', 'Spagna', 'España', 'Spanje']" },
                                      { "value": "Comunidad Valenciana", "visibleIf": "{countrySelf} anyof ['Spain', 'Hiszpania', 'Spanien', 'Іспанія', 'Espanja', 'Spagna', 'España', 'Spanje']" },
                                      { "value": "Wielkopolskie", "visibleIf": "{countrySelf} anyof ['Poland', 'Polska', 'Polen', 'Польща', 'Puola', 'Polonia']" },
                                      { "value": "Kujawsko-Pomorskie", "visibleIf": "{countrySelf} anyof ['Poland', 'Polska', 'Polen', 'Польща', 'Puola', 'Polonia']" },
                                      { "value": "Małopolskie", "visibleIf": "{countrySelf} anyof ['Poland', 'Polska', 'Polen', 'Польща', 'Puola', 'Polonia']" },
                                      { "value": "Łódzkie", "visibleIf": "{countrySelf} anyof ['Poland', 'Polska', 'Polen', 'Польща', 'Puola', 'Polonia']" },
                                      { "value": "Dolnośląskie", "visibleIf": "{countrySelf} anyof ['Poland', 'Polska', 'Polen', 'Польща', 'Puola', 'Polonia']" },
                                      { "value": "Lubelskie", "visibleIf": "{countrySelf} anyof ['Poland', 'Polska', 'Polen', 'Польща', 'Puola', 'Polonia']" },
                                      { "value": "Lubuskie", "visibleIf": "{countrySelf} anyof ['Poland', 'Polska', 'Polen', 'Польща', 'Puola', 'Polonia']" },
                                      { "value": "Mazowieckie", "visibleIf": "{countrySelf} anyof ['Poland', 'Polska', 'Polen', 'Польща', 'Puola', 'Polonia']" },
                                      { "value": "Opolskie", "visibleIf": "{countrySelf} anyof ['Poland', 'Polska', 'Polen', 'Польща', 'Puola', 'Polonia']" },
                                      { "value": "Podlaskie", "visibleIf": "{countrySelf} anyof ['Poland', 'Polska', 'Polen', 'Польща', 'Puola', 'Polonia']" },
                                      { "value": "Pomorskie", "visibleIf": "{countrySelf} anyof ['Poland', 'Polska', 'Polen', 'Польща', 'Puola', 'Polonia']" },
                                      { "value": "Śląskie", "visibleIf": "{countrySelf} anyof ['Poland', 'Polska', 'Polen', 'Польща', 'Puola', 'Polonia']" },
                                      { "value": "Podkarpackie", "visibleIf": "{countrySelf} anyof ['Poland', 'Polska', 'Polen', 'Польща', 'Puola', 'Polonia']" },
                                      { "value": "Świętokrzyskie", "visibleIf": "{countrySelf} anyof ['Poland', 'Polska', 'Polen', 'Польща', 'Puola', 'Polonia']" },
                                      { "value": "Warmińsko-Mazurskie", "visibleIf": "{countrySelf} anyof ['Poland', 'Polska', 'Polen', 'Польща', 'Puola', 'Polonia']" },
                                      { "value": "Zachodniopomorskie", "visibleIf": "{countrySelf} anyof ['Poland', 'Polska', 'Polen', 'Польща', 'Puola', 'Polonia']" },
                                      { "value": "Drenthe", "visibleIf": "{countrySelf} anyof ['Netherlands', 'Holandia', 'Nederländerna', 'Нідерланди', 'Alankomaat', 'Paesi Bassi', 'Países Bajos', 'Nederland']" },
                                      { "value": "Flevoland", "visibleIf": "{countrySelf} anyof ['Netherlands', 'Holandia', 'Nederländerna', 'Нідерланди', 'Alankomaat', 'Paesi Bassi', 'Países Bajos', 'Nederland']" },
                                      { "value": "Friesland ", "visibleIf": "{countrySelf} anyof ['Netherlands', 'Holandia', 'Nederländerna', 'Нідерланди', 'Alankomaat', 'Paesi Bassi', 'Países Bajos', 'Nederland']" },
                                      { "value": "Gelderland", "visibleIf": "{countrySelf} anyof ['Netherlands', 'Holandia', 'Nederländerna', 'Нідерланди', 'Alankomaat', 'Paesi Bassi', 'Países Bajos', 'Nederland']" },
                                      { "value": "Groningen", "visibleIf": "{countrySelf} anyof ['Netherlands', 'Holandia', 'Nederländerna', 'Нідерланди', 'Alankomaat', 'Paesi Bassi', 'Países Bajos', 'Nederland']" },
                                      { "value": "Limburg", "visibleIf": "{countrySelf} anyof ['Netherlands', 'Holandia', 'Nederländerna', 'Нідерланди', 'Alankomaat', 'Paesi Bassi', 'Países Bajos', 'Nederland']" },
                                      { "value": "Noord-Brabant", "visibleIf": "{countrySelf} anyof ['Netherlands', 'Holandia', 'Nederländerna', 'Нідерланди', 'Alankomaat', 'Paesi Bassi', 'Países Bajos', 'Nederland']" },
                                      { "value": "Noord-Holland", "visibleIf": "{countrySelf} anyof ['Netherlands', 'Holandia', 'Nederländerna', 'Нідерланди', 'Alankomaat', 'Paesi Bassi', 'Países Bajos', 'Nederland']" },
                                      { "value": "Overijssel", "visibleIf": "{countrySelf} anyof ['Netherlands', 'Holandia', 'Nederländerna', 'Нідерланди', 'Alankomaat', 'Paesi Bassi', 'Países Bajos', 'Nederland']" },
                                      { "value": "Utrecht", "visibleIf": "{countrySelf} anyof ['Netherlands', 'Holandia', 'Nederländerna', 'Нідерланди', 'Alankomaat', 'Paesi Bassi', 'Países Bajos', 'Nederland']" },
                                      { "value": "Zeeland", "visibleIf": "{countrySelf} anyof ['Netherlands', 'Holandia', 'Nederländerna', 'Нідерланди', 'Alankomaat', 'Paesi Bassi', 'Países Bajos', 'Nederland']" },
                                      { "value": "Zuid-Holland", "visibleIf": "{countrySelf} anyof ['Netherlands', 'Holandia', 'Nederländerna', 'Нідерланди', 'Alankomaat', 'Paesi Bassi', 'Países Bajos', 'Nederland']" },
                                      { "value": "Ahvenanmaa/Åland", "visibleIf": "{countrySelf} anyof ['Finland', 'Finlandia', 'Фінляндія', 'Suomi']" },
                                      { "value": "Etelä-Suomi", "visibleIf": "{countrySelf} anyof ['Finland', 'Finlandia', 'Фінляндія', 'Suomi']" },
                                      { "value": "Itä-Suomi", "visibleIf": "{countrySelf} anyof ['Finland', 'Finlandia', 'Фінляндія', 'Suomi']" },
                                      { "value": "Pohjois-Suomi", "visibleIf": "{countrySelf} anyof ['Finland', 'Finlandia', 'Фінляндія', 'Suomi']" },
                                      { "value": "Uusimaa", "visibleIf": "{countrySelf} anyof ['Finland', 'Finlandia', 'Фінляндія', 'Suomi']" },
                                      { "value": "Väli-Suomi", "visibleIf": "{countrySelf} anyof ['Finland', 'Finlandia', 'Фінляндія', 'Suomi']" },
                                      { "value": "Mellersta Norrland", "visibleIf": "{countrySelf} anyof ['Sweden', 'Szwecja', 'Sverige', 'Швеція', 'Ruotsi', 'Svezia', 'Suecia', 'Zweden']" },
                                      { "value": "Norra Mellansverige", "visibleIf": "{countrySelf} anyof ['Sweden', 'Szwecja', 'Sverige', 'Швеція', 'Ruotsi', 'Svezia', 'Suecia', 'Zweden']" },
                                      { "value": "Östra Mellansverige", "visibleIf": "{countrySelf} anyof ['Sweden', 'Szwecja', 'Sverige', 'Швеція', 'Ruotsi', 'Svezia', 'Suecia', 'Zweden']" },
                                      { "value": "Övre Norrland", "visibleIf": "{countrySelf} anyof ['Sweden', 'Szwecja', 'Sverige', 'Швеція', 'Ruotsi', 'Svezia', 'Suecia', 'Zweden']" },
                                      { "value": "Småland med öarna", "visibleIf": "{countrySelf} anyof ['Sweden', 'Szwecja', 'Sverige', 'Швеція', 'Ruotsi', 'Svezia', 'Suecia', 'Zweden']" },
                                      { "value": "Stockholm", "visibleIf": "{countrySelf} anyof ['Sweden', 'Szwecja', 'Sverige', 'Швеція', 'Ruotsi', 'Svezia', 'Suecia', 'Zweden']" },
                                      { "value": "Sydsverige", "visibleIf": "{countrySelf} anyof ['Sweden', 'Szwecja', 'Sverige', 'Швеція', 'Ruotsi', 'Svezia', 'Suecia', 'Zweden']" },
                                      { "value": "Västsverige", "visibleIf": "{countrySelf} anyof ['Sweden', 'Szwecja', 'Sverige', 'Швеція', 'Ruotsi', 'Svezia', 'Suecia', 'Zweden']" },
                                      { "value": "Автономна Республіка Крим", "visibleIf": "{countrySelf} anyof ['Ukraine', 'Ukraina', 'Україна', 'Ucraina', 'Ucrania', 'Oekraïne']" },
                                      { "value": "Вінницька область", "visibleIf": "{countrySelf} anyof ['Ukraine', 'Ukraina', 'Україна', 'Ucraina', 'Ucrania', 'Oekraïne']" },
                                      { "value": "Волинська область", "visibleIf": "{countrySelf} anyof ['Ukraine', 'Ukraina', 'Україна', 'Ucraina', 'Ucrania', 'Oekraïne']" },
                                      { "value": "Дніпропетровська область", "visibleIf": "{countrySelf} anyof ['Ukraine', 'Ukraina', 'Україна', 'Ucraina', 'Ucrania', 'Oekraïne']" },
                                      { "value": "Донецька область", "visibleIf": "{countrySelf} anyof ['Ukraine', 'Ukraina', 'Україна', 'Ucraina', 'Ucrania', 'Oekraïne']" },
                                      { "value": "Житомирська область", "visibleIf": "{countrySelf} anyof ['Ukraine', 'Ukraina', 'Україна', 'Ucraina', 'Ucrania', 'Oekraïne']" },
                                      { "value": "Закарпатська область", "visibleIf": "{countrySelf} anyof ['Ukraine', 'Ukraina', 'Україна', 'Ucraina', 'Ucrania', 'Oekraïne']" },
                                      { "value": "Запорізька область", "visibleIf": "{countrySelf} anyof ['Ukraine', 'Ukraina', 'Україна', 'Ucraina', 'Ucrania', 'Oekraïne']" },
                                      { "value": "Івано-Франківська область", "visibleIf": "{countrySelf} anyof ['Ukraine', 'Ukraina', 'Україна', 'Ucraina', 'Ucrania', 'Oekraïne']" },
                                      { "value": "Київська область", "visibleIf": "{countrySelf} anyof ['Ukraine', 'Ukraina', 'Україна', 'Ucraina', 'Ucrania', 'Oekraïne']" },
                                      { "value": "Кіровоградська область", "visibleIf": "{countrySelf} anyof ['Ukraine', 'Ukraina', 'Україна', 'Ucraina', 'Ucrania', 'Oekraïne']" },
                                      { "value": "Луганська область", "visibleIf": "{countrySelf} anyof ['Ukraine', 'Ukraina', 'Україна', 'Ucraina', 'Ucrania', 'Oekraïne']" },
                                      { "value": "Львівська область", "visibleIf": "{countrySelf} anyof ['Ukraine', 'Ukraina', 'Україна', 'Ucraina', 'Ucrania', 'Oekraïne']" },
                                      { "value": "Миколаївська область", "visibleIf": "{countrySelf} anyof ['Ukraine', 'Ukraina', 'Україна', 'Ucraina', 'Ucrania', 'Oekraïne']" },
                                      { "value": "Одеська область", "visibleIf": "{countrySelf} anyof ['Ukraine', 'Ukraina', 'Україна', 'Ucraina', 'Ucrania', 'Oekraïne']" },
                                      { "value": "Полтавська область", "visibleIf": "{countrySelf} anyof ['Ukraine', 'Ukraina', 'Україна', 'Ucraina', 'Ucrania', 'Oekraïne']" },
                                      { "value": "Рівненська область", "visibleIf": "{countrySelf} anyof ['Ukraine', 'Ukraina', 'Україна', 'Ucraina', 'Ucrania', 'Oekraïne']" },
                                      { "value": "Сумська область", "visibleIf": "{countrySelf} anyof ['Ukraine', 'Ukraina', 'Україна', 'Ucraina', 'Ucrania', 'Oekraïne']" },
                                      { "value": "Тернопільська область", "visibleIf": "{countrySelf} anyof ['Ukraine', 'Ukraina', 'Україна', 'Ucraina', 'Ucrania', 'Oekraïne']" },
                                      { "value": "Харківська область", "visibleIf": "{countrySelf} anyof ['Ukraine', 'Ukraina', 'Україна', 'Ucraina', 'Ucrania', 'Oekraïne']" },
                                      { "value": "Херсонська область", "visibleIf": "{countrySelf} anyof ['Ukraine', 'Ukraina', 'Україна', 'Ucraina', 'Ucrania', 'Oekraïne']" },
                                      { "value": "Хмельницька область", "visibleIf": "{countrySelf} anyof ['Ukraine', 'Ukraina', 'Україна', 'Ucraina', 'Ucrania', 'Oekraïne']" },
                                      { "value": "Черкаська область", "visibleIf": "{countrySelf} anyof ['Ukraine', 'Ukraina', 'Україна', 'Ucraina', 'Ucrania', 'Oekraïne']" },
                                      { "value": "Чернівецька область", "visibleIf": "{countrySelf} anyof ['Ukraine', 'Ukraina', 'Україна', 'Ucraina', 'Ucrania', 'Oekraïne']" },
                                      { "value": "Чернігівська область", "visibleIf": "{countrySelf} anyof ['Ukraine', 'Ukraina', 'Україна', 'Ucraina', 'Ucrania', 'Oekraïne']" },
                                      { "value": "Київ", "visibleIf": "{countrySelf} anyof ['Ukraine', 'Ukraina', 'Україна', 'Ucraina', 'Ucrania', 'Oekraïne']" },
                                      { "value": "Севастополь", "visibleIf": "{countrySelf} anyof ['Ukraine', 'Ukraina', 'Україна', 'Ucraina', 'Ucrania', 'Oekraïne']" }]
                        }, 
                        { "name": "personName",
                          "type": "text",
                          "title": { "en": "Name of the person who is filling in the survey <em>(confidential, only for internal use)</em>",
                                    "fi": "Kyselyyn osallistuvan henkilön nimi <em>(luottamuksellinen, vain sisäiseen käyttöön)</em>",
                                    "it": "Nome della persona che compila il sondaggio <em>(riservato, solo per uso interno)</em>",
                                    "es": "Nombre de la persona que rellena la encuesta <em>(confidencial, sólo para uso interno)</em>",
                                    "nl": "Naam van de persoon die de enquête invult <em>(vertrouwelijk, alleen voor intern gebruik)</em>",
                                    "pl": "Imię i nazwisko osoby wypełniającej ankietę <em>(poufne, tylko do użytku wewnętrznego)</em>",
                                    "sw": "Namn på personen som fyller i enkäten <em>(konfidentiellt, endast för internt bruk)</em>",
                                    "ua": " Ім'я особи, яка заповнює опитування <em>(конфіденційно, лише для внутрішнього використання)</em>"
                                  },
                          "maxLength": 200,
                          "isRequired": false
                        }, 
                        { "name": "personPosition",
                          "type": "text",
                          "title": { "en": "Position in the organization <em>(confidential, only for internal use)</em>",
                                    "fi": "Asema organisaatiossa <em>(luottamuksellinen, vain sisäiseen käyttöön)</em>",
                                    "it": "Posizione nell'organizzazione <em>(riservato, solo per uso interno)</em>",
                                    "es": "Cargo en la organización <em>(confidencial, sólo para uso interno)</em>",
                                    "nl": "Positie in de organisatie <em>(vertrouwelijk, alleen voor intern gebruik)</em>",
                                    "pl": "Stanowisko w organizacji <em>(poufne, tylko do użytku wewnętrznego)</em>",
                                    "sw": "Position i organisationen <em>(konfidentiellt, endast för internt bruk)</em>",
                                    "ua": " Посада в організації <em>(конфіденційно, лише для внутрішнього використання)</em>"
                                  },
                          "maxLength": 200,
                          "isRequired": false
                        }
                      ], 
            },
  {
    name: "section2",
    title: { "en": "Section 2. Characterization of your organization",
      "fi": "Osa 2. Organisaatiosi luonnehdinta",
      "it": "Sezione 2. Caratterizzazione della tua organizzazione",
      "es": "Sección 2. Caracterización de su organización",
      "nl": "Sectie 2. Karakterisering van uw organisatie",
      "pl": "Rozdział 2. Charakterystyka Twojej organizacji",
      "sw": "Avsnitt 2. Karakterisering av din organisation",
      "ua": "Розділ 2. Характеристика вашої організації"}
   ,
    elements: [{
      "type": "dropdown",
      "name": "sectorSelf",
      "title": { "en": "Sector of your organization",
        "fi": "Organisaatiosi toimiala",
        "it": "Settore della tua organizzazione",
        "es": "Sector al cual pertenece su organización",
        "nl": "Sector van uw organisatie",
        "pl": "Sektor Twojej organizacji",
        "sw": "Sektor för din organisation",
        "ua": "Сектор вашої організації"}
     ,
      "isRequired": true,
      "showNoneItem": false,
      "showOtherItem": false,
      "choices":  [{"value": "Private sector",
        "text":{"en": "Private sector (SME, Enterprises, Technology Developers, Private Foundations…)",
           "fi": "Yksityinen sektori (pk-yritykset, yritykset, teknologian kehittäjät, yksityiset säätiöt…)",
           "it": "Settore privato (PMI, imprese, sviluppatori tecnologici, fondazioni private…)",
           "es": "Sector privado (PYME, empresas, desarrolladores tecnológicos, fundaciones privadas...)",
           "nl": "Private sector (MKB, ondernemingen, technologieontwikkelaars, particuliere stichtingen…)",
           "pl": "Sektor prywatny (MŚP, przedsiębiorstwa, twórcy technologii, fundacje prywatne…)",
         "sw": "Privat sektor (Små och medelstora företag, företag, teknikutvecklare, privata stiftelser...)",
         "ua": "Приватний сектор (МСП, підприємства, розробники технологій, приватні фонди…)"}},
      {"value": "Public sector",
        "text":{"en": "Public sector (Government, Public Agencies, Public Schools, European Commission…)",
           "fi": "Julkinen sektori (hallitus, julkiset virastot, julkiset koulut, Euroopan komissio…)",
           "it": "Settore pubblico (Governo, Agenzie pubbliche, Scuole pubbliche, Commissione europea…)",
           "es": "Sector público (Gobierno, organismos públicos, escuelas públicas, Comisión Europea...)",
           "nl": "Publieke sector (overheid, overheidsinstanties, openbare scholen, Europese Commissie…)",
           "pl": "Sektor publiczny (rząd, agencje publiczne, szkoły publiczne, Komisja Europejska…)",
         "sw": "Offentlig sektor (stater, offentliga organ, offentliga skolor, Europeiska kommissionen...)",
         "ua": "Державний сектор (уряд, державні установи, державні школи, Європейська комісія…)"}},
      {"value": "Academia",
        "text":{"en": "Academia (Universities, academic institutions and/or research centers)",
           "fi": "Akateeminen maailma (yliopistot, korkeakoulut ja/tai tutkimuskeskukset)",
           "it": "Accademia (Università, istituzioni accademiche e/o centri di ricerca)",
           "es": "Academia (Universidades, instituciones académicas y/o centros de investigación)",
           "nl": "Academia (universiteiten, academische instellingen en/of onderzoekscentra)",
           "pl": "Środowisko akademickie (Uniwersytety, instytucje akademickie i/lub ośrodki badawcze)",
         "sw": "Akademin (universitet, akademiska institutioner och/eller forskningscentra)",
         "ua": "Академія (університети, академічні установи та/або дослідницькі центри)"}},
      {"value": "Civic society",
        "text":{"en": "Civic society (NGOs, Citizen Associations, Confederations, Trade/ Labor Unions, etc.)",
           "fi": "Kansalaisyhteiskunta (kansalaisjärjestöt, kansalaisjärjestöt, keskusliitot, ammattiliitot jne.)",
           "it": "Società civile (ONG, associazioni di cittadini, confederazioni, sindacati, ecc.)",
           "es": "Sociedad civil (ONG, asociaciones ciudadanas, confederaciones, sindicatos, etc.)",
           "nl": "Maatschappelijk middenveld (NGO's, burgerverenigingen, confederaties, vakbonden, enz.)",
           "pl": "Społeczeństwo obywatelskie (organizacje pozarządowe, stowarzyszenia obywatelskie, konfederacje, związki zawodowe/pracownicze itp.)",
         "sw": "Det civila samhället (NGOs, medborgarföreningar, konfederationer, fackföreningar, etc.)",
         "ua": "Громадянське суспільство (НУО, асоціації громадян, конфедерації, профспілки та ін.)"}},
      {"value": "Media",
        "text":{"en": "Media (Journalists, Magazines…)",
           "fi": "Media (toimittajat, aikakauslehdet…)",
           "it": "Media (giornalisti, riviste…)",
           "es": "Medios de comunicación (periodistas, revistas...)",
           "nl": "Media (Journalisten, tijdschriften…)",
           "pl": "Media (dziennikarze, magazyny…)",
         "sw": "Media (journalister, tidskrifter...)",
         "ua": "ЗМІ (журналісти, журнали…)"}},
      {"value": "Non-Organized Civil Society",
        "text":{"en": "Non-Organized Civil Society (Individuals, Activists…)",
           "fi": "Järjestämätön kansalaisyhteiskunta (yksityishenkilöt, aktivistit…)",
           "it": "Società civile non organizzata (individui, attivisti…)",
           "es": "Sociedad civil no organizada (particulares, activistas...)",
           "nl": "Niet-georganiseerde burgermaatschappij (individuen, activisten…)",
           "pl": "Niezorganizowane społeczeństwo obywatelskie (osoby prywatne, aktywiści…)",
         "sw": "Icke-organiserat civilsamhälle (individer, aktivister...)",
         "ua": "Неорганізоване громадянське суспільство (окремі особи, активісти…)"}}]      
    }, {
        "type": "radiogroup",
        "name": "mainActivitySelf",
        "title": { "en": "Main activity",
          "fi": "Päätoiminta",
          "it": "Attività principale",
          "es": "Actividad principal",
          "nl": "Hoofdactiviteit",
          "pl": "Główna aktywność",
          "sw": "Huvudaktivitet",
          "ua": "Основна діяльність"}
       ,
        "isRequired": true,
        "colCount": 1,
        "choices": [{"value": "Activism",
          "text":{"en": "Activism",
             "fi": "Aktivismi",
             "it": "Attivismo",
             "es": "Activismo",
             "nl": "Activisme",
             "pl": "Aktywizm",
           "sw": "Aktivism",
           "ua": "Активізм"}},
        {"value": "Crop Production",
          "text":{"en": "Crop Production",
             "fi": "Kasvintuotanto",
             "it": "Produzione di colture",
             "es": "Producción agrícola",
             "nl": "Gewasproductie",
             "pl": "Produkcja roślinna",
           "sw": "Växtproduktion",
           "ua": "Рослинництво"}},
        {"value": "Livestock Farming",
          "text":{"en": "Livestock Farming",
             "fi": "Karjankasvatus",
             "it": "Allevamento di bestiame",
             "es": "Ganadería",
             "nl": "Veehouderij",
             "pl": "Hodowla zwierząt gospodarskich",
           "sw": "Boskapsuppfödning",
           "ua": "Тваринництво"}},
        {"value": "Research",
          "text":{"en": "Research",
             "fi": "Tutkimus",
             "it": "Ricerca",
             "es": "Investigación",
             "nl": "Onderzoek",
             "pl": "Badania",
           "sw": "Forskning",
           "ua": "дослідження"}},
        {"value": "Communication",
          "text":{"en": "Communication",
             "fi": "Viestintä",
             "it": "Comunicazione",
             "es": "Comunicación",
             "nl": "Mededeling",
             "pl": "Komunikacja",
           "sw": "Kommunikation",
           "ua": "спілкування"}},
        {"value": "Resource Management",
          "text":{"en": "Resource Management",
             "fi": "Resurssienhallinta",
             "it": "Gestione delle risorse",
             "es": "Gestión de recursos",
             "nl": "Resourcebeheer",
             "pl": "Zarządzanie zasobami",
           "sw": "Resurshantering",
           "ua": "Управління ресурсами"}},
        {"value": "Technology Development and Application",
          "text":{"en": "Technology Development and Application",
             "fi": "Teknologian kehittäminen ja soveltaminen",
             "it": "Sviluppo e applicazione della tecnologia",
             "es": "Desarrollo y aplicación de tecnología",
             "nl": "Technologieontwikkeling en -toepassing",
             "pl": "Rozwój i zastosowanie technologii",
           "sw": "Teknikutveckling och applikation",
           "ua": "Розробка та застосування технологій"}},
        {"value": "Food Processing",
          "text":{"en": "Food Processing",
             "fi": "Elintarvikkeiden jalostus",
             "it": "Lavorazione alimentare",
             "es": "Procesado de alimentos",
             "nl": "Voedselverwerking",
             "pl": "Przetwórstwo spożywcze",
           "sw": "Livsmedelsbearbetning",
           "ua": "Обробка харчових продуктів"}},
        {"value": "Food Safety and Quality Control",
          "text":{"en": "Food Safety and Quality Control",
             "fi": "Elintarvikkeiden turvallisuus ja laadunvalvonta",
             "it": "Sicurezza alimentare e controllo di qualità",
             "es": "Seguridad alimentaria y control de calidad",
             "nl": "Voedselveiligheid en kwaliteitscontrole",
             "pl": "Bezpieczeństwo żywności i kontsrola jakości",
           "sw": "Livsmedelssäkerhet och kvalitetskontroll",
           "ua": "Контроль безпеки та якості харчових продуктів"}},
        {"value": "Education and Training",
          "text":{"en": "Education and Training",
             "fi": "Koulutus ja koulutus",
             "it": "Istruzione e formazione",
             "es": "Educación y formación",
             "nl": "Onderwijs en opleiding",
             "pl": "Edukacja i szkolenia",
           "sw": "Utbildning och träning",
           "ua": "Освіта та навчання"}},
        {"value": "Policy Development",
          "text":{"en": "Policy Development",
             "fi": "Politiikan kehittäminen",
             "it": "Sviluppo delle politiche",
             "es": "Desarrollo de políticas",
             "nl": "Beleidsontwikkeling",
             "pl": "Rozwój polityki",
           "sw": "Policyutveckling",
           "ua": "Розробка політики"}},
        {"value": "Community Outreach",
          "text":{"en": "Community Outreach",
             "fi": "Yhteisön toiminta",
             "it": "Sensibilizzazione della comunità",
             "es": "Relaciones con la comunidad",
             "nl": "Gemeenschapswerk",
             "pl": "Zasięg społeczności",
           "sw": "Community Outreach",
           "ua": "Робота з громадськістю"}},
        {"value": "Economic Activities",
          "text":{"en": "Economic Activities",
             "fi": "Taloudellinen toiminta",
             "it": "Attività economiche",
             "es": "Actividades económicas",
             "nl": "Economische activiteiten",
             "pl": "Działalność gospodarcza",
           "sw": "Ekonomisk verksamhet",
           "ua": "Господарська діяльність"}},
        {"value": "Innovation Hub",
          "text":{"en": "Innovation Hub",
             "fi": "Innovaatiokeskus",
             "it": "Hub di innovazione",
             "es": "Centro de innovación",
             "nl": "Innovatiecentrum",
             "pl": "Centrum Innowacji",
           "sw": "Innovationsnav",
           "ua": "Інноваційний хаб"}},
        {"value": "Environmental Monitoring",
          "text":{"en": "Environmental Monitoring",
             "fi": "Ympäristön seuranta",
             "it": "Monitoraggio ambientale",
             "es": "Vigilancia medioambiental",
             "nl": "Milieumonitoring",
             "pl": "Monitorowanie środowiska",
           "sw": "Miljöövervakning",
           "ua": "Екологічний моніторинг"}},
        {"value": "Infrastructure Maintenance and Development",
          "text":{"en": "Infrastructure Maintenance and Development",
             "fi": "Infrastruktuurin ylläpito ja kehittäminen",
             "it": "Manutenzione e sviluppo delle infrastrutture",
             "es": "Mantenimiento y desarrollo de infraestructuras",
             "nl": "Infrastructuuronderhoud en -ontwikkeling",
             "pl": "Utrzymanie i rozwój infrastruktury",
           "sw": "Underhåll och utveckling av infrastruktur",
           "ua": "Обслуговування та розвиток інфраструктури"}},
        {"value": "International Cooperation",
          "text":{"en": "International Cooperation",
             "fi": "Kansainvälinen yhteistyö",
             "it": "Cooperazione Internazionale",
             "es": "Cooperación internacional",
             "nl": "Internationale samenwerking",
             "pl": "Współpraca międzynarodowa",
           "sw": "Internationellt samarbete",
           "ua": "Міжнародна співпраця"}}]
        ,
        "showClearButton": true
      }, {
        "type": "radiogroup",
        "name": "secondaryActivitySelf",
        "title": { "en": "Secondary activity (if any)",
          "fi": "Toissijainen toiminta (jos on)",
          "it": "Attività secondaria (se presente)",
          "es": "Actividad secundaria (en su caso)",
          "nl": "Secundaire activiteit (indien van toepassing)",
          "pl": "Działalność drugorzędna (jeśli istnieje)",
          "sw": "Sekundär aktivitet (om någon)",
          "ua": "Додаткова діяльність (якщо є)"}
       ,
        "isRequired": false,
        "colCount": 1,
        "choices": [{"value": "Activism",
          "text":{"en": "Activism",
             "fi": "Aktivismi",
             "it": "Attivismo",
             "es": "Activismo",
             "nl": "Activisme",
             "pl": "Aktywizm",
           "sw": "Aktivism",
           "ua": "Активізм"}},
        {"value": "Crop Production",
          "text":{"en": "Crop Production",
             "fi": "Kasvintuotanto",
             "it": "Produzione di colture",
             "es": "Producción agrícola",
             "nl": "Gewasproductie",
             "pl": "Produkcja roślinna",
           "sw": "Växtproduktion",
           "ua": "Рослинництво"}},
        {"value": "Livestock Farming",
          "text":{"en": "Livestock Farming",
             "fi": "Karjankasvatus",
             "it": "Allevamento di bestiame",
             "es": "Ganadería",
             "nl": "Veehouderij",
             "pl": "Hodowla zwierząt gospodarskich",
           "sw": "Boskapsuppfödning",
           "ua": "Тваринництво"}},
        {"value": "Research",
          "text":{"en": "Research",
             "fi": "Tutkimus",
             "it": "Ricerca",
             "es": "Investigación",
             "nl": "Onderzoek",
             "pl": "Badania",
           "sw": "Forskning",
           "ua": "дослідження"}},
        {"value": "Communication",
          "text":{"en": "Communication",
             "fi": "Viestintä",
             "it": "Comunicazione",
             "es": "Comunicación",
             "nl": "Mededeling",
             "pl": "Komunikacja",
           "sw": "Kommunikation",
           "ua": "спілкування"}},
        {"value": "Resource Management",
          "text":{"en": "Resource Management",
             "fi": "Resurssienhallinta",
             "it": "Gestione delle risorse",
             "es": "Gestión de recursos",
             "nl": "Resourcebeheer",
             "pl": "Zarządzanie zasobami",
           "sw": "Resurshantering",
           "ua": "Управління ресурсами"}},
        {"value": "Technology Development and Application",
          "text":{"en": "Technology Development and Application",
             "fi": "Teknologian kehittäminen ja soveltaminen",
             "it": "Sviluppo e applicazione della tecnologia",
             "es": "Desarrollo y aplicación de tecnología",
             "nl": "Technologieontwikkeling en -toepassing",
             "pl": "Rozwój i zastosowanie technologii",
           "sw": "Teknikutveckling och applikation",
           "ua": "Розробка та застосування технологій"}},
        {"value": "Food Processing",
          "text":{"en": "Food Processing",
             "fi": "Elintarvikkeiden jalostus",
             "it": "Lavorazione alimentare",
             "es": "Procesado de alimentos",
             "nl": "Voedselverwerking",
             "pl": "Przetwórstwo spożywcze",
           "sw": "Livsmedelsbearbetning",
           "ua": "Обробка харчових продуктів"}},
        {"value": "Food Safety and Quality Control",
          "text":{"en": "Food Safety and Quality Control",
             "fi": "Elintarvikkeiden turvallisuus ja laadunvalvonta",
             "it": "Sicurezza alimentare e controllo di qualità",
             "es": "Seguridad alimentaria y control de calidad",
             "nl": "Voedselveiligheid en kwaliteitscontrole",
             "pl": "Bezpieczeństwo żywności i kontsrola jakości",
           "sw": "Livsmedelssäkerhet och kvalitetskontroll",
           "ua": "Контроль безпеки та якості харчових продуктів"}},
        {"value": "Education and Training",
          "text":{"en": "Education and Training",
             "fi": "Koulutus ja koulutus",
             "it": "Istruzione e formazione",
             "es": "Educación y formación",
             "nl": "Onderwijs en opleiding",
             "pl": "Edukacja i szkolenia",
           "sw": "Utbildning och träning",
           "ua": "Освіта та навчання"}},
        {"value": "Policy Development",
          "text":{"en": "Policy Development",
             "fi": "Politiikan kehittäminen",
             "it": "Sviluppo delle politiche",
             "es": "Desarrollo de políticas",
             "nl": "Beleidsontwikkeling",
             "pl": "Rozwój polityki",
           "sw": "Policyutveckling",
           "ua": "Розробка політики"}},
        {"value": "Community Outreach",
          "text":{"en": "Community Outreach",
             "fi": "Yhteisön toiminta",
             "it": "Sensibilizzazione della comunità",
             "es": "Relaciones con la comunidad",
             "nl": "Gemeenschapswerk",
             "pl": "Zasięg społeczności",
           "sw": "Community Outreach",
           "ua": "Робота з громадськістю"}},
        {"value": "Economic Activities",
          "text":{"en": "Economic Activities",
             "fi": "Taloudellinen toiminta",
             "it": "Attività economiche",
             "es": "Actividades económicas",
             "nl": "Economische activiteiten",
             "pl": "Działalność gospodarcza",
           "sw": "Ekonomisk verksamhet",
           "ua": "Господарська діяльність"}},
        {"value": "Innovation Hub",
          "text":{"en": "Innovation Hub",
             "fi": "Innovaatiokeskus",
             "it": "Hub di innovazione",
             "es": "Centro de innovación",
             "nl": "Innovatiecentrum",
             "pl": "Centrum Innowacji",
           "sw": "Innovationsnav",
           "ua": "Інноваційний хаб"}},
        {"value": "Environmental Monitoring",
          "text":{"en": "Environmental Monitoring",
             "fi": "Ympäristön seuranta",
             "it": "Monitoraggio ambientale",
             "es": "Vigilancia medioambiental",
             "nl": "Milieumonitoring",
             "pl": "Monitorowanie środowiska",
           "sw": "Miljöövervakning",
           "ua": "Екологічний моніторинг"}},
        {"value": "Infrastructure Maintenance and Development",
          "text":{"en": "Infrastructure Maintenance and Development",
             "fi": "Infrastruktuurin ylläpito ja kehittäminen",
             "it": "Manutenzione e sviluppo delle infrastrutture",
             "es": "Mantenimiento y desarrollo de infraestructuras",
             "nl": "Infrastructuuronderhoud en -ontwikkeling",
             "pl": "Utrzymanie i rozwój infrastruktury",
           "sw": "Underhåll och utveckling av infrastruktur",
           "ua": "Обслуговування та розвиток інфраструктури"}},
        {"value": "International Cooperation",
          "text":{"en": "International Cooperation",
             "fi": "Kansainvälinen yhteistyö",
             "it": "Cooperazione Internazionale",
             "es": "Cooperación internacional",
             "nl": "Internationale samenwerking",
             "pl": "Współpraca międzynarodowa",
           "sw": "Internationellt samarbete",
           "ua": "Міжнародна співпраця"}}]
        ,
        "showClearButton": true
      }, {
        "type": "radiogroup",
        "name": "employeeNumberSelf",
        "title": { "en": "Number of employees in your organization",
          "fi": "Organisaationi työntekijöiden lukumäärä",
          "it": "Numero di dipendenti della mia organizzazione",
          "es": "Número de empleados de mi organización",
          "nl": "Aantal werknemers van mijn organisatie",
          "pl": "Liczba pracowników mojej organizacji",
          "sw": "Antal anställda i min organisation",
          "ua": "Кількість працівників моєї організації"}
       ,
        "isRequired": true,
        "colCount": 1,
        "choices": [ "1-5", "6-10", "11-49", "50-250", "250+" ],
        "showClearButton": true
      }, {
        "name": "operationYearsSelf",
        "type": "text",
        "isRequired": true,
        "title": { "en": "Years of activity of the organization",
          "fi": "Yksiön toimintavuodet",
          "it": "Anni di attività dell'ente",
          "es": "Años de actividad de la entidad",
          "nl": "Jaren van activiteit van de entiteit",
          "pl": "Lata działalności podmiotu",
          "sw": "År av verksamhet för enheten",
          "ua": " Роки діяльності організації"}
       ,
        "inputType": "number",
        "validators": [{
          "type": "numeric",
          "text": { "en": "Please provide a number.",
            "fi": "Anna numero.",
            "it": "Inserisci un numero.",
            "es": "Por favor proporcione un número.",
            "nl": "Geef een nummer op.",
            "pl": "Proszę podać liczbę.",
            "sw": "Ange ett nummer.",
            "ua": "Укажіть номер."}
         
        }]
      }]
  }, {
    name: "section3",
    title: { "en": "Section 3. Relationships and connections of your organization",
      "fi": "Osa 3. Organisaatiosi suhteet ja yhteydet",
      "it": "Sezione 3. Relazioni e connessioni della tua organizzazione",
      "es": "Sección 3. Relaciones y conexiones de su organización",
      "nl": "Sectie 3. Relaties en verbindingen van uw organisatie",
      "pl": "Rozdział 3. Relacje i powiązania Twojej organizacji",
      "sw": "Avsnitt 3. Relationer och kopplingar till din organisation",
      "ua": "Розділ 3. Відносини та зв'язки вашої організації"}
   ,
    elements: [{
      "type": "matrixdynamic",
      "name": "partnersSelf",
      "title": { "en": "Please list six (or more) strategic organizations or individual allies with whom your organization has collaborated in the last five years.",
        "fi": "Luettelo kuusi (tai useampia) strategista organisaatiota tai yksittäistä liittolaista, joiden kanssa organisaatiosi on tehnyt yhteistyötä viimeisen viiden vuoden aikana.",
        "it": "Elenca sei (o più) organizzazioni strategiche o singoli alleati con cui la tua organizzazione ha collaborato negli ultimi cinque anni.",
        "es": "Enumere seis (o más) organizaciones estratégicas o aliados individuales con los que su organización haya colaborado en los últimos cinco años",
        "nl": "Geef zes (of meer) strategische organisaties of individuele bondgenoten op waarmee uw organisatie de afgelopen vijf jaar heeft samengewerkt",
        "pl": "Proszę wymienić sześć (lub więcej) organizacji strategicznych lub indywidualnych sojuszników, z którymi Twoja organizacja współpracowała w ciągu ostatnich pięciu lat",
        "sw": "Ange sex (eller fler) strategiska organisationer eller enskilda allierade som din organisation har samarbetat med under de senaste fem åren",
        "ua": " Укажіть шість (чи більше) стратегічних організацій або окремих союзників, з якими ваша організація співпрацювала протягом останніх п’яти років"}
     ,
      "addRowText": { "en": "one more",
        "fi": "yksi lisää",
        "it": "un altro",
        "es": "más",
        "nl": "nog een",
        "pl": "jeszcze jeden",
        "sw": "en till",
        "ua": "ще один"}
     ,
      "isExpanded" : false,
      "columnMinWidth" : "20px",
      "columns": [
        {
          "name": "namePartner",
          "cellType": "text",
          "title": { "en": "Name of the organization",
            "fi": "Organisaation nimi",
            "it": "Nome dell'organizzazione",
            "es": "Nombre de la organización",
            "nl": "Naam van de organisatie",
            "pl": "Nazwa organizacji",
            "sw": "Organisationens namn",
            "ua": "Назва організації"}
         ,
          "isRequired": true,
          "maxLength": 200
        }, {
          "name": "sectorPartner",
          "cellType": "dropdown",
          "title": { "en": "Sector",
            "fi": "Sektori",
            "it": "Settore",
            "es": "Sector",
            "nl": "Sector",
            "pl": "Sektor",
            "sw": "Sektor",
            "ua": "Сектор"}
         ,
          "isRequired": true,
          "choices":  [{"value": "Private sector",
            "text":{"en": "Private sector (SME, Enterprises, Technology Developers, Private Foundations…)",
               "fi": "Yksityinen sektori (pk-yritykset, yritykset, teknologian kehittäjät, yksityiset säätiöt…)",
               "it": "Settore privato (PMI, imprese, sviluppatori tecnologici, fondazioni private…)",
               "es": "Sector privado (PYME, empresas, desarrolladores tecnológicos, fundaciones privadas...)",
               "nl": "Private sector (MKB, ondernemingen, technologieontwikkelaars, particuliere stichtingen…)",
               "pl": "Sektor prywatny (MŚP, przedsiębiorstwa, twórcy technologii, fundacje prywatne…)",
             "sw": "Privat sektor (Små och medelstora företag, företag, teknikutvecklare, privata stiftelser...)",
             "ua": "Приватний сектор (МСП, підприємства, розробники технологій, приватні фонди…)"}},
          {"value": "Public sector",
            "text":{"en": "Public sector (Government, Public Agencies, Public Schools, European Commission…)",
               "fi": "Julkinen sektori (hallitus, julkiset virastot, julkiset koulut, Euroopan komissio…)",
               "it": "Settore pubblico (Governo, Agenzie pubbliche, Scuole pubbliche, Commissione europea…)",
               "es": "Sector público (Gobierno, organismos públicos, escuelas públicas, Comisión Europea...)",
               "nl": "Publieke sector (overheid, overheidsinstanties, openbare scholen, Europese Commissie…)",
               "pl": "Sektor publiczny (rząd, agencje publiczne, szkoły publiczne, Komisja Europejska…)",
             "sw": "Offentlig sektor (stater, offentliga organ, offentliga skolor, Europeiska kommissionen...)",
             "ua": "Державний сектор (уряд, державні установи, державні школи, Європейська комісія…)"}},
          {"value": "Academia",
            "text":{"en": "Academia (Universities, academic institutions and/or research centers)",
               "fi": "Akateeminen maailma (yliopistot, korkeakoulut ja/tai tutkimuskeskukset)",
               "it": "Accademia (Università, istituzioni accademiche e/o centri di ricerca)",
               "es": "Academia (Universidades, instituciones académicas y/o centros de investigación)",
               "nl": "Academia (universiteiten, academische instellingen en/of onderzoekscentra)",
               "pl": "Środowisko akademickie (Uniwersytety, instytucje akademickie i/lub ośrodki badawcze)",
             "sw": "Akademin (universitet, akademiska institutioner och/eller forskningscentra)",
             "ua": "Академія (університети, академічні установи та/або дослідницькі центри)"}},
          {"value": "Civic society",
            "text":{"en": "Civic society (NGOs, Citizen Associations, Confederations, Trade/ Labor Unions, etc.)",
               "fi": "Kansalaisyhteiskunta (kansalaisjärjestöt, kansalaisjärjestöt, keskusliitot, ammattiliitot jne.)",
               "it": "Società civile (ONG, associazioni di cittadini, confederazioni, sindacati, ecc.)",
               "es": "Sociedad civil (ONG, asociaciones ciudadanas, confederaciones, sindicatos, etc.)",
               "nl": "Maatschappelijk middenveld (NGO's, burgerverenigingen, confederaties, vakbonden, enz.)",
               "pl": "Społeczeństwo obywatelskie (organizacje pozarządowe, stowarzyszenia obywatelskie, konfederacje, związki zawodowe/pracownicze itp.)",
             "sw": "Det civila samhället (NGOs, medborgarföreningar, konfederationer, fackföreningar, etc.)",
             "ua": "Громадянське суспільство (НУО, асоціації громадян, конфедерації, профспілки та ін.)"}},
          {"value": "Media",
            "text":{"en": "Media (Journalists, Magazines…)",
               "fi": "Media (toimittajat, aikakauslehdet…)",
               "it": "Media (giornalisti, riviste…)",
               "es": "Medios de comunicación (periodistas, revistas...)",
               "nl": "Media (Journalisten, tijdschriften…)",
               "pl": "Media (dziennikarze, magazyny…)",
             "sw": "Media (journalister, tidskrifter...)",
             "ua": "ЗМІ (журналісти, журнали…)"}},
          {"value": "Non-Organized Civil Society",
            "text":{"en": "Non-Organized Civil Society (Individuals, Activists…)",
               "fi": "Järjestämätön kansalaisyhteiskunta (yksityishenkilöt, aktivistit…)",
               "it": "Società civile non organizzata (individui, attivisti…)",
               "es": "Sociedad civil no organizada (particulares, activistas...)",
               "nl": "Niet-georganiseerde burgermaatschappij (individuen, activisten…)",
               "pl": "Niezorganizowane społeczeństwo obywatelskie (osoby prywatne, aktywiści…)",
             "sw": "Icke-organiserat civilsamhälle (individer, aktivister...)",
             "ua": "Неорганізоване громадянське суспільство (окремі особи, активісти…)"}}]          
        }, {
          "name": "collabActivityWithPartner",
          "cellType": "dropdown",
          "title": { "en": "How do/did you collaborate with them?",
            "fi": "Kuinka teet heidän kanssaan yhteistyötä?",
            "it": "Come li fai collaborare?",
            "es": "¿Cómo colabora con ellos?",
            "nl": "Hoe laat je ze samenwerken?",
            "pl": "Jak z nimi współpracujesz?",
            "sw": "Hur samarbetar du med dem?",
            "ua": "Як ви співпрацюєте з ними?"}
         ,
          "isRequired": true,
          "choices":  [{"value": "joint project development",
            "text":{"en": "joint project development",
               "fi": "Yhteinen projektikehitys",
               "it": "Sviluppo di progetti congiunti",
               "es": "Desarrollo de proyectos conjuntos",
               "nl": "Gezamenlijke projectontwikkeling",
               "pl": "Wspólny rozwój projektu",
             "sw": "Gemensam projektutveckling",
             "ua": "Спільна розробка проекту"}},
          {"value": "public policy design",
            "text":{"en": "public policy design",
               "fi": "Julkisen politiikan suunnittelu",
               "it": "Progettazione delle politiche pubbliche",
               "es": "Diseño de políticas públicas",
               "nl": "Ontwerp van het overheidsbeleid",
               "pl": "Projektowanie Polityki Publicznej",
             "sw": "Public Policy Design",
             "ua": "Дизайн державної політики"}},
          {"value": "human resource mobilization",
            "text":{"en": "human resource mobilization",
               "fi": "Henkilöstöresurssien mobilisointi",
               "it": "Mobilitazione delle risorse umane",
               "es": "Movilización de recursos humanos",
               "nl": "Mobilisatie van menselijke hulpbronnen",
               "pl": "Mobilizacja zasobów ludzkich",
             "sw": "Mobilisering av mänskliga resurser",
             "ua": "Мобілізація людських ресурсів"}},
          {"value": "shared infrastructure",
            "text":{"en": "shared infrastructure",
               "fi": "Jaettu infrastruktuuri",
               "it": "Infrastruttura condivisa",
               "es": "Infraestructura compartida",
               "nl": "Gedeelde infrastructuur",
               "pl": "Wspólna infrastruktura",
             "sw": "Delad infrastruktur",
             "ua": "Спільна інфраструктура"}},
          {"value": "collaboration for innovation",
            "text":{"en": "collaboration for innovation",
               "fi": "Yhteistyötä innovaatioiden puolesta",
               "it": "Collaborazione per l'innovazione",
               "es": "Colaboración para la innovación",
               "nl": "Samenwerking voor innovatie",
               "pl": "Współpraca na rzecz innowacji",
             "sw": "Samverkan för innovation",
             "ua": "Співпраця заради інновацій"}},
          {"value": "technology exchange",
            "text":{"en": "technology exchange (bidirectional)",
               "fi": "Teknologian vaihto (kaksisuuntainen)",
               "it": "Scambio tecnologico (bidirezionale)",
               "es": "Intercambio tecnológico (bidireccional)",
               "nl": "Technologie-uitwisseling (bidirectioneel)",
               "pl": "Wymiana technologii (dwukierunkowa)",
             "sw": "Teknikutbyte (dubbelriktad)",
             "ua": "Обмін технологіями (двосторонній)"}},
          {"value": "technology transfer",
            "text":{"en": "technology transfer (unidirectional)",
               "fi": "Teknologian siirto (yksisuuntainen)",
               "it": "Trasferimento tecnologico (unidirezionale)",
               "es": "Transferencia de tecnología (unidireccional)",
               "nl": "Technologieoverdracht (unidirectioneel)",
               "pl": "Transfer technologii (jednokierunkowy)",
             "sw": "Tekniköverföring (enkelriktad)",
             "ua": "Трансфер технологій (односпрямований)"}},
          {"value": "research consortia",
            "text":{"en": "research consortia",
               "fi": "Tutkimuskonsortiot",
               "it": " Consorzi di ricerca",
               "es": "Consorcios de investigación",
               "nl": "Onderzoeksconsortia",
               "pl": "Konsorcja badawcze",
             "sw": "Forskningskonsortier",
             "ua": "Дослідницькі консорціуми"}},
          {"value": "strategic partnerships",
            "text":{"en": "strategic partnerships",
               "fi": "Strategiset kumppanuudet",
               "it": "Partnership strategiche",
               "es": "Asociaciones estratégicas",
               "nl": "Strategische partnerschappen",
               "pl": "Partnerstwa strategiczne",
             "sw": "Strategiska partnerskap",
             "ua": "Стратегічне партнерство"}},
          {"value": "knowledge exchange",
            "text":{"en": "knowledge exchange (bidirectional)",
               "fi": "Tiedonvaihto (kaksisuuntainen)",
               "it": "Scambio di conoscenze (bidirezionale)",
               "es": "Intercambio de conocimientos (bidireccional)",
               "nl": "Kennisuitwisseling (bidirectioneel)",
               "pl": "Wymiana wiedzy (dwukierunkowa)",
             "sw": "Kunskapsutbyte (dubbelriktad)",
             "ua": "Обмін знаннями (двосторонній)"}},
          {"value": "knowledge transfer",
            "text":{"en": "knowledge transfer (unidirectional)",
               "fi": "Tietämyksen siirto (yksisuuntainen)",
               "it": "Trasferimento di conoscenza (unidirezionale)",
               "es": "Transferencia de conocimientos (unidireccional)",
               "nl": "Kennisoverdracht (unidirectioneel)",
               "pl": "Transfer wiedzy (jednokierunkowy)",
             "sw": "Kunskapsöverföring (enkelriktad)",
             "ua": "Передача знань (односпрямована)"}},
          {"value": "sharing spaces",
            "text":{"en": "sharing spaces (e.g: innovation platforms, councils…)",
               "fi": "Jaetut tilat (esim. innovaatioalustat, neuvostot…)",
               "it": "Condivisione di spazi (ad esempio: Piattaforme di innovazione, Consigli…)",
               "es": "Espacios compartidos (por ejemplo: plataformas de innovación, consejos...)",
               "nl": "Gedeelde ruimtes (bijv.: Innovatieplatforms, raden…)",
               "pl": "Udostępnianie przestrzeni (np.: Platformy Innowacji, Rady…)",
             "sw": "Dela utrymmen (t.ex. innovationsplattformar, råd...)",
             "ua": "Обмін місцями (наприклад, інноваційні платформи, ради…)"}},
          {"value": "community engagement initiatives",
            "text":{"en": "community engagement initiatives",
               "fi": "Yhteisön sitoutumista edistävät aloitteet",
               "it": "Iniziative di coinvolgimento della comunità",
               "es": "Iniciativas de participación comunitaria",
               "nl": "Initiatieven voor maatschappelijke betrokkenheid",
               "pl": "Inicjatywy zaangażowania społecznego",
             "sw": "Initiativ för samhällsengagemang",
             "ua": "Ініціативи залучення громади"}},
          {"value": "sharing advice",
            "text":{"en": "sharing advice",
               "fi": "Jakaminen neuvoja",
               "it": "Condivisione di consigli",
               "es": "Asesoramiento compartido",
               "nl": "Advies delen",
               "pl": "Dzielenie się poradami",
             "sw": "Dela råd",
             "ua": "Обмін порадами"}},
          {"value": "lobbying",
            "text":{"en": "lobbying (lobbying is the act of attempting to influence the decisions of government officials in favor of a particular cause, policy, or interest)",
               "fi": "Lobbaus (Lobbaus on yritystä vaikuttaa valtion virkamiesten päätöksiin tietyn asian, politiikan tai edun hyväksi)",
               "it": "Lobbying (Il lobbying è l'atto di tentare di influenzare le decisioni dei funzionari governativi a favore di una particolare causa, politica o interesse)",
               "es": "Lobbying (Lobbying es el acto de intentar influir en las decisiones de los funcionarios del gobierno a favor de una causa, política o interés particular)",
               "nl": "Lobbyen (lobbyen is de handeling waarbij men probeert de beslissingen van overheidsfunctionarissen te beïnvloeden ten gunste van een bepaalde zaak, beleid of belang)",
               "pl": "Lobbing (Lobbing to działanie mające na celu wpłynięcie na decyzje urzędników rządowych na rzecz określonej sprawy, polityki lub interesu)",
             "sw": "Lobbyverksamhet (Lobbyverksamhet är handlingen att försöka påverka regeringstjänstemäns beslut till förmån för en viss sak, policy eller intresse)",
             "ua": "Лобіювання (Лобіювання – це спроба вплинути на рішення урядовців на користь певної справи, політики чи інтересів)"}},
          {"value": "personal relations",
            "text":{"en": "personal relations",
               "fi": "Henkilökohtaiset suhteet",
               "it": "Relazioni personali",
               "es": "Relaciones personales",
               "nl": "Persoonlijke relaties",
               "pl": "Relacje osobiste",
             "sw": "Personliga relationer",
             "ua": "Особисті відносини"}}]          
        }, {
          "name": "collabTypeWithPartner",
          "cellType": "dropdown",
          "title": { "en": "What type of collaboration is/was it?",
            "fi": "Millainen yhteistyö se on?",
            "it": "Che tipo di collaborazione è?",
            "es": "¿De qué tipo de colaboración se trata?",
            "nl": "Wat voor soort samenwerking is het?",
            "pl": "Jaki to rodzaj współpracy?",
            "sw": "Vilken typ av samarbete är det?",
            "ua": "Який це тип співпраці?"}
         ,
          "isRequired": true,
          "choices":  [{"value": "informal",
                        "text":{"en": "informal collaboration",
                                "fi": "epävirallinen yhteistyö",
                                "it": "collaborazione informale ",
                                "es": "colaboración informal",
                                "nl": "informele samenwerking",
                                "pl": "współpraca nieformalna",
                                "sw": "informellt samarbete",
                                "ua": "неформальна співпраця"}}, 
                      {"value": "formal",
                        "text":{ "en": "formal collaboration",
                                "fi": "muodollinen yhteistyö",
                                "it": "collaborazione formale ",
                                "es": "colaboración formal",
                                "nl": "formele samenwerking",
                                "pl": "współpraca formalna",
                                "sw": "formellt samarbete",
                                "ua": "офіційна співпраця"}}]
        }
      ],
      "rowCount": 1,
      "minRowCount": 6,
      "maxRowCount": 50
    }]
  }, {
    name: "section4",
    title: { "en": "Section 4. Leverage points",
      "fi": "Osa 4. Vipuvaikutuspisteet",
      "it": " Sezione 4. Punti di leva ",
      "es": " Sección 4. Puntos de apalancamiento ",
      "nl": "Sectie 4. Hefboompunten",
      "pl": " Sekcja 4. Punkty dźwigni ",
      "sw": "Avsnitt 4. Hävstångspoäng",
      "ua": "Розділ 4. Точки впливу"}
   ,
    description: { "en": "How do you agree or disagree with the following statements?",
      "fi": "Miten samaa mieltä tai eri mieltä olet seuraavista väittämistä?",
      "it": "Come ti trovi d'accordo o in disaccordo con le seguenti affermazioni?",
      "es": "¿Estás de acuerdo o en desacuerdo con las siguientes afirmaciones?",
      "nl": "In hoeverre bent u het eens of oneens met de volgende stellingen?",
      "pl": "W jaki sposób zgadzasz się lub nie zgadzasz z następującymi stwierdzeniami?",
      "sw": "Hur håller du med eller inte håller med om följande påståenden?",
      "ua": "Як ви погоджуєтеся чи не погоджуєтеся з наступними твердженнями?"}
   ,
    elements: [{
      "type": "matrix",
      "name": "knowledgeUse",
      "title": { "en": "Rethinking Knowledge Creation and Use",
        "fi": "Tietämyksen luomisen ja käytön uudelleenajattelu",
        "it": "Ripensare la creazione e l'uso della conoscenza",
        "es": "Repensar la creación y el uso del conocimiento",
        "nl": "Kenniscreatie en -gebruik heroverwegen",
        "pl": "Przemyślenie tworzenia i wykorzystania wiedzy",
        "sw": "Ompröva skapandet och användningen av kunskap",
        "ua": "Переосмислення створення та використання знань"}
     ,
      "columns": [{
        "value": -2,
        "text": { "en": "Strongly disagree",
                  "fi": "Jyrkästi eri mieltä",
                  "it": "Fortemente in disaccordo ",
                  "es": "Totalmente en desacuerdo",
                  "nl": "Helemaal niet mee eens",
                  "pl": "Zdecydowanie się nie zgadzam",
                  "sw": "Håller inte med starkt",
                  "ua": "Категорично не згоден"}
      }, {
        "value": -1,
        "text": { "en": "Somewhat disagree",
                  "fi": "Hieman eri mieltä",
                  "it": "Un po' in disaccordo",
                  "es": "Algo en desacuerdo",
                  "nl": "Iets oneens",
                  "pl": "Raczej się nie zgadzam",
                  "sw": "Håller lite med",
                  "ua": "Дещо не згоден"}
      }, {
        "value": 0,
        "text": { "en": "Neither agree nor disagree",
                  "fi": "Ei samaa eikä eri mieltä",
                  "it": "Né d'accordo né in disaccordo",
                  "es": "Ni de acuerdo ni en desacuerdo",
                  "nl": "Noch eens, noch oneens",
                  "pl": "Ani się zgadzam, ani nie zgadzam",
                  "sw": "Varken håller med eller håller med",
                  "ua": "Ні погоджуюсь, ні не погоджуюсь"}
      }, {
        "value": 1,
        "text": { "en": "Somewhat agree",
                  "fi": "Vähän samaa mieltä",
                  "it": "Abbastanza d'accordo",
                  "es": "Algo de acuerdo",
                  "nl": "Een beetje mee eens",
                  "pl": "Raczej się zgadzam",
                  "sw": "Håller lite med",
                  "ua": "Частково згоден"}
      }, {
        "value": 2,
        "text": { "en": "Strongly agree",
                  "fi": "Vahvasti samaa mieltä",
                  "it": "Sono assolutamente d'accordo",
                  "es": "Muy de acuerdo",
                  "nl": "Helemaal mee eens",
                  "pl": "Zdecydowanie się zgadzam",
                  "sw": "Håller starkt med",
                  "ua": "Повністю згоден"}
      }],
      "rows": [
        {
          "value": "strongNetworkSelf",
          "text": { "en": "The <u>network of actors</u> in my territory is strong enough to help the region become more resilient to climate change.",
                    "fi": "Alueeni <u>toimijaverkosto</u> on tarpeeksi vahva auttaakseen aluetta tulemaan kestävämmäksi ilmastonmuutosta vastaan",
                    "it": "La <u>rete di attori</u> nel mio territorio è abbastanza forte da aiutare la regione a diventare più resiliente ai cambiamenti climatici",
                    "es": "La <u>red de actores</u> en mi territorio es lo suficientemente fuerte como para ayudar a la región a volverse más resiliente frente al cambio climático.",
                    "nl": "Het <u>netwerk van actoren</u> in mijn gebied is sterk genoeg om de regio te helpen veerkrachtiger te worden tegen klimaatverandering.",
                    "pl": "<u>Sieć aktorów</u> w moim regionie jest wystarczająco silna, aby pomóc regionowi stać się bardziej odpornym na zmiany klimatu.",
                    "sw": "<u>Nätverket av aktörer</u> i mitt område är tillräckligt starkt för att hjälpa regionen att bli mer motståndskraftig mot klimatförändringar.",
                    "ua": "<u>Мережа акторів</u> у моєму регіоні достатньо сильна, щоб допомогти регіону стати більш стійким до змін клімату."}
         
        },
        {
          "value": "diverseNetworkSelf",
          "text": { "en": "The network of actors includes a <u>diverse enough set of actors</u> (e.g., different sectors) to effectively handle climate challenges.",
            "fi": "Toimijaverkosto sisältää <u>riittävän monipuolisen joukon toimijoita</u> (esim. eri sektoreilta) ilmastohaasteiden käsittelemiseksi tehokkaasti.",
            "it": "La rete di attori include <u>un gruppo sufficientemente diversificato di attori</u> (ad es. di diversi settori) per affrontare efficacemente le sfide climatiche.",
            "es": "La red de actores incluye un <u>conjunto suficientemente diverso de actores</u> (por ejemplo, de diferentes sectores) para abordar eficazmente los desafíos climáticos.",
            "nl": "Het netwerk van actoren omvat een voldoende <u>diverse reeks actoren</u> (bv. verschillende sectoren) om de klimaatuitdagingen effectief aan te pakken.",
            "pl": "Sieć aktorów obejmuje <u>wystarczająco różnorodny zestaw aktorów</u> (np. z różnych sektorów), aby skutecznie radzić sobie z wyzwaniami klimatycznymi.",
            "sw": "Nätverket av aktörer inkluderar en <u>tillräckligt mångsidig grupp aktörer</u> (t.ex. från olika sektorer) för att effektivt hantera klimatutmaningar.",
            "ua": "Мережа акторів включає <u>достатньо різноманітний набір акторів</u> (наприклад, різні сектори), щоб ефективно справлятися з кліматичними викликами."}
         
        }
      ],
      "alternateRows": true,
      "isAllRowRequired": true,
      "isRequired": true
    }, {
      "type": "matrix",
      "name": "influenceNetworkBehaviour",
      "title": { "en": "Influencing Network Behavior",
        "fi": "Verkoston käyttäytymiseen vaikuttaminen",
        "it": "Influenzare il comportamento della ret",
        "es": "Influir en el comportamiento de la re",
        "nl": "Beïnvloeding van netwerkgedrag",
        "pl": "Wpływanie na zachowanie siec",
        "sw": "Påverka nätverkets beteend",
        "ua": "Вплив на поведінку мереж"}
     ,
      "columns": [{
        "value": -2,
        "text": { "en": "Strongly disagree",
                  "fi": "Jyrkästi eri mieltä",
                  "it": "Fortemente in disaccordo ",
                  "es": "Totalmente en desacuerdo",
                  "nl": "Helemaal niet mee eens",
                  "pl": "Zdecydowanie się nie zgadzam",
                  "sw": "Håller inte med starkt",
                  "ua": "Категорично не згоден"}
      }, {
        "value": -1,
        "text": { "en": "Somewhat disagree",
                  "fi": "Hieman eri mieltä",
                  "it": "Un po' in disaccordo",
                  "es": "Algo en desacuerdo",
                  "nl": "Iets oneens",
                  "pl": "Raczej się nie zgadzam",
                  "sw": "Håller lite med",
                  "ua": "Дещо не згоден"}
      }, {
        "value": 0,
        "text": { "en": "Neither agree nor disagree",
                  "fi": "Ei samaa eikä eri mieltä",
                  "it": "Né d'accordo né in disaccordo",
                  "es": "Ni de acuerdo ni en desacuerdo",
                  "nl": "Noch eens, noch oneens",
                  "pl": "Ani się zgadzam, ani nie zgadzam",
                  "sw": "Varken håller med eller håller med",
                  "ua": "Ні погоджуюсь, ні не погоджуюсь"}
      }, {
        "value": 1,
        "text": { "en": "Somewhat agree",
                  "fi": "Vähän samaa mieltä",
                  "it": "Abbastanza d'accordo",
                  "es": "Algo de acuerdo",
                  "nl": "Een beetje mee eens",
                  "pl": "Raczej się zgadzam",
                  "sw": "Håller lite med",
                  "ua": "Частково згоден"}
      }, {
        "value": 2,
        "text": { "en": "Strongly agree",
                  "fi": "Vahvasti samaa mieltä",
                  "it": "Sono assolutamente d'accordo",
                  "es": "Muy de acuerdo",
                  "nl": "Helemaal mee eens",
                  "pl": "Zdecydowanie się zgadzam",
                  "sw": "Håller starkt med",
                  "ua": "Повністю згоден"}
      }],
      "rows": [
        {
          "value": "frequentlyCollaborateSelf",
          "text": { "en": "I frequently <u>collaborate with other organizations</u> in my territory to address climate challenges.",
            "fi": "Teen usein <u>yhteistyötä muiden organisaatioiden kanssa</u> alueellani ilmastohaasteiden ratkaisemiseksi.",
            "it": "<u>Collaboro frequentemente con altre organizzazioni</u> nel mio territorio per affrontare le sfide climatiche",
            "es": "Frecuentemente <u>colaboro con otras organizaciones</u> en mi territorio para enfrentar los desafíos climáticos.",
            "nl": "Ik werk vaak <u>samen met andere organisaties</u> in mijn gebied om klimaatproblemen aan te pakken.",
            "pl": "Często <u>współpracuję z innymi organizacjami</u> w moim regionie, aby stawić czoła wyzwaniom klimatycznym.",
            "sw": "Jag <u>samarbetar ofta med andra organisationer</u> i mitt område för att hantera klimatutmaningar.",
            "ua": "Я часто <u>співпрацюю з іншими організаціями</u> у своєму регіоні для вирішення кліматичних викликів."}
         
        },
        {
          "value": "collaborationIsUsefulSelf",
          "text": { "en": "<u>Collaboration with other actors</u> is useful in improving my skills to tackle climate change challenges.",
            "fi": "<u>Yhteistyö muiden toimijoiden kanssa</u> on hyödyllistä taitojeni parantamisessa ilmastonmuutoksen haasteiden käsittelemiseksi.",
            "it": "La collaborazione con altri attori è <u>utile per migliorare le mie competenze nell'affrontare le sfide</u> del cambiamento climatico ",
            "es": "La colaboración con otros actores <u>es útil para mejorar mis habilidades</u> para abordar los desafíos del cambio climático.",
            "nl": "<u>Samenwerking met andere actoren is nuttig</u> om mijn vaardigheden te verbeteren om de uitdagingen van de klimaatverandering aan te pakken.",
            "pl": "Współpraca z innymi aktorami <u>pomaga mi w doskonaleniu umiejętności</u> potrzebnych do radzenia sobie z wyzwaniami klimatycznymi.",
            "sw": "Samarbete med andra aktörer <u>är användbart för att förbättra mina färdigheter</u> för att hantera klimatförändringsutmaningar.",
            "ua": "Співпраця з іншими акторами <u>допомагає мені покращувати свої навички</u> для подолання кліматичних викликів."}
         
        },
        {
          "value": "actorsAquireKnowledgeThroughCollaborationSelf",
          "text": { "en": "Other actors in the network <u>acquire knowledge to face climate change</u> through collaboration with me.",
            "fi": "Muut verkoston toimijat saavat <u>ilmastonmuutokseen liittyvää tietoa</u> tekemällä yhteistyötä kanssani.",
            "it": "Altri attori della rete <u>acquisiscono conoscenze per affrontare il cambiamento climatico</u> attraverso la collaborazione con me.",
            "es": "Otros actores de la red <u>adquieren conocimientos para enfrentar el cambio climático</u> a través de la colaboración conmigo.",
            "nl": "Andere actoren in het <u>netwerk verwerven kennis om de klimaatverandering</u> het hoofd te bieden door met mij samen te werken.",
            "pl": "Współpracując ze mną, inni uczestnicy sieci <u>zdobywają wiedzę, jak stawić czoła zmianom klimatycznym</u>.",
            "sw": "Andra aktörer i nätverket <u>förvärvar kunskap för att möta klimatförändringar</u> genom samarbete med mig.",
            "ua": "Інші актори в мережі <u>здобувають знання про зміни клімату</u> через співпрацю зі мною."}
         
        }
      ],
      "alternateRows": true,
      "isAllRowRequired": true,
      "isRequired": true
    }, {
      "type": "matrix",
      "name": "changeNetworkStructure",
      "title": { "en": "Changing the Structure of the Network",
        "fi": "Verkoston rakenteen muuttamine",
        "it": "Modificare la struttura della rete",
        "es": "Cambiar la estructura de la re",
        "nl": "De structuur van het netwerk veranderen",
        "pl": "Zmiana struktury siec",
        "sw": "Ändra nätverkets struktu",
        "ua": "Зміна структури мереж"}
     ,
      "columns": [{
        "value": -2,
        "text": { "en": "Strongly disagree",
                  "fi": "Jyrkästi eri mieltä",
                  "it": "Fortemente in disaccordo ",
                  "es": "Totalmente en desacuerdo",
                  "nl": "Helemaal niet mee eens",
                  "pl": "Zdecydowanie się nie zgadzam",
                  "sw": "Håller inte med starkt",
                  "ua": "Категорично не згоден"}
      }, {
        "value": -1,
        "text": { "en": "Somewhat disagree",
                  "fi": "Hieman eri mieltä",
                  "it": "Un po' in disaccordo",
                  "es": "Algo en desacuerdo",
                  "nl": "Iets oneens",
                  "pl": "Raczej się nie zgadzam",
                  "sw": "Håller lite med",
                  "ua": "Дещо не згоден"}
      }, {
        "value": 0,
        "text": { "en": "Neither agree nor disagree",
                  "fi": "Ei samaa eikä eri mieltä",
                  "it": "Né d'accordo né in disaccordo",
                  "es": "Ni de acuerdo ni en desacuerdo",
                  "nl": "Noch eens, noch oneens",
                  "pl": "Ani się zgadzam, ani nie zgadzam",
                  "sw": "Varken håller med eller håller med",
                  "ua": "Ні погоджуюсь, ні не погоджуюсь"}
      }, {
        "value": 1,
        "text": { "en": "Somewhat agree",
                  "fi": "Vähän samaa mieltä",
                  "it": "Abbastanza d'accordo",
                  "es": "Algo de acuerdo",
                  "nl": "Een beetje mee eens",
                  "pl": "Raczej się zgadzam",
                  "sw": "Håller lite med",
                  "ua": "Частково згоден"}
      }, {
        "value": 2,
        "text": { "en": "Strongly agree",
                  "fi": "Vahvasti samaa mieltä",
                  "it": "Sono assolutamente d'accordo",
                  "es": "Muy de acuerdo",
                  "nl": "Helemaal mee eens",
                  "pl": "Zdecydowanie się zgadzam",
                  "sw": "Håller starkt med",
                  "ua": "Повністю згоден"}
      }],
      "rows": [
        {
          "value": "frequentlyCollaborateInPolicy",
          "text": { "en": "I frequently <u>work with other organizations to change policies</u> (e.g., through collaborative lobbying, coalitions, joint petitions, legislative proposals).",
            "fi": "<u>Teen usein yhteistyötä muiden organisaatioiden kanssa muuttaakseni politiikkoja</u> (esim. yhteisen lobbaamisen, koalitioiden, yhteisten vetoomusten, lainsäädäntöehdotusten kautta).",
            "it": "<u>Lavoro frequentemente con altre organizzazioni per modificare le politiche</u> (ad es. attraverso il lobbying collaborativo, coalizioni, petizioni congiunte, proposte legislative).",
            "es": "Frecuentemente <u>trabajo con otras organizaciones para cambiar políticas</u> (por ejemplo, mediante cabildeo colaborativo, coaliciones, peticiones conjuntas, propuestas legislativas).",
            "nl": "Ik werk vaak <u>samen met andere organisaties om beleid te veranderen</u> (bijv. door samen te lobbyen, coalities, gezamenlijke petities, wetsvoorstellen).",
            "pl": "Często <u>współpracuję z innymi organizacjami, aby zmieniać polityki</u> (np. poprzez wspólne lobbing, koalicje, petycje, propozycje legislacyjne).",
            "sw": "Jag <u>arbetar ofta med andra organisationer för att ändra politik</u> (t.ex. genom gemensam lobbyverksamhet, koalitioner, gemensamma framställningar, lagförslag).",
            "ua": "Я часто <u>працюю з іншими організаціями для зміни політик</u> (наприклад, через спільне лобіювання, коаліції, петиції, законодавчі пропозиції)."}
         
        },
        {
          "value": "regionFacilitatesCollaborations",
          "text": { "en": "<u>Governance structures</u> in my region facilitate collaboration between actors effectively.",
            "fi": "<u>Alueeni hallintorakenteet</u> tukevat tehokkaasti toimijoiden välistä yhteistyötä.",
            "it": "Le <u>strutture di governance</u> nella mia regione facilitano efficacemente la collaborazione tra gli attori.",
            "es": "Las <u>estructuras de gobernanza</u> en mi región facilitan de manera efectiva la colaboración entre los actores.",
            "nl": "<u>Bestuursstructuren</u> in mijn regio vergemakkelijken de samenwerking tussen actoren effectief.",
            "pl": "<u>Struktury zarządzania</u> w moim regionie skutecznie ułatwiają współpracę między aktorami.",
            "sw": "<u>Styrningsstrukturer</u> i min region underlättar effektivt samarbetet mellan aktörer.",
            "ua": "<u>Структури управління</u> у моєму регіоні ефективно сприяють співпраці між акторами."}
         
        }
      ],
      "alternateRows": true,
      "isAllRowRequired": true,
      "isRequired": true
    }, {
      "type": "matrix",
      "name": "culturalValues",
      "title": { "en": "Cultural Values",
        "fi": "Kulttuuriset arvot",
        "it": "Valori cultural",
        "es": "Valores culturale",
        "nl": "Culturele waarden",
        "pl": "Wartości kulturow",
        "sw": "Kulturella värderinga",
        "ua": "Культурні цінності"}
     ,
      "columns": [{
        "value": -2,
        "text": { "en": "Strongly disagree",
                  "fi": "Jyrkästi eri mieltä",
                  "it": "Fortemente in disaccordo ",
                  "es": "Totalmente en desacuerdo",
                  "nl": "Helemaal niet mee eens",
                  "pl": "Zdecydowanie się nie zgadzam",
                  "sw": "Håller inte med starkt",
                  "ua": "Категорично не згоден"}
      }, {
        "value": -1,
        "text": { "en": "Somewhat disagree",
                  "fi": "Hieman eri mieltä",
                  "it": "Un po' in disaccordo",
                  "es": "Algo en desacuerdo",
                  "nl": "Iets oneens",
                  "pl": "Raczej się nie zgadzam",
                  "sw": "Håller lite med",
                  "ua": "Дещо не згоден"}
      }, {
        "value": 0,
        "text": { "en": "Neither agree nor disagree",
                  "fi": "Ei samaa eikä eri mieltä",
                  "it": "Né d'accordo né in disaccordo",
                  "es": "Ni de acuerdo ni en desacuerdo",
                  "nl": "Noch eens, noch oneens",
                  "pl": "Ani się zgadzam, ani nie zgadzam",
                  "sw": "Varken håller med eller håller med",
                  "ua": "Ні погоджуюсь, ні не погоджуюсь"}
      }, {
        "value": 1,
        "text": { "en": "Somewhat agree",
                  "fi": "Vähän samaa mieltä",
                  "it": "Abbastanza d'accordo",
                  "es": "Algo de acuerdo",
                  "nl": "Een beetje mee eens",
                  "pl": "Raczej się zgadzam",
                  "sw": "Håller lite med",
                  "ua": "Частково згоден"}
      }, {
        "value": 2,
        "text": { "en": "Strongly agree",
                  "fi": "Vahvasti samaa mieltä",
                  "it": "Sono assolutamente d'accordo",
                  "es": "Muy de acuerdo",
                  "nl": "Helemaal mee eens",
                  "pl": "Zdecydowanie się zgadzam",
                  "sw": "Håller starkt med",
                  "ua": "Повністю згоден"}
      }],
      "rows": [
        {
          "value": "networkSharesValuesSelf",
          "text": { "en": "I believe the network in <u>my region shares common values</u> regarding the actions needed to face climate change.",
            "fi": "Uskon, että <u>alueeni verkostolla on yhteiset arvot</u> liittyen toimiin, joita tarvitaan ilmastonmuutoksen kohtaamiseksi.",
            "it": "Credo che <u>la rete nella mia regione condivida valori comuni</u> riguardo alle azioni necessarie per affrontare il cambiamento climatico.",
            "es": "Creo que la red en mi <u>región comparte valores comunes</u> con respecto a las acciones necesarias para enfrentar el cambio climático.",
            "nl": "Ik geloof dat het netwerk in mijn <u>regio gemeenschappelijke waarden</u> deelt met betrekking tot de acties die nodig zijn om de klimaatverandering het hoofd te bieden.",
            "pl": "Uważam, że sieć w moim regionie <u>dzieli wspólne wartości</u> dotyczące działań, jakie należy podjąć, aby stawić czoła zmianom klimatycznym.",
            "sw": "Jag tror att nätverket i min <u>region delar gemensamma värderingar</u> angående de åtgärder som krävs för att möta klimatförändringar.",
            "ua": "Я вважаю, що мережа у моєму регіоні <u>поділяє спільні цінності</u> щодо дій, необхідних для протидії кліматичним змінам."}
         
        },
        {
          "value": "networkHasCollaborationCulture",
          "text": { "en": "There is a <u>collaboration culture among organizations</u> in my region to address climate challenges.",
            "fi": "Alueeni <u>organisaatioilla on yhteistyökulttuuri</u> ilmastohaasteiden käsittelemiseksi.",
            "it": "Nella mia regione esiste una <u>cultura di collaborazione</u> tra le organizzazioni per affrontare le sfide climatiche.",
            "es": "Existe una <u>cultura de colaboración entre las organizaciones</u> en mi región para abordar los desafíos climáticos.",
            "nl": "Er is een <u>cultuur van samenwerking</u> tussen organisaties in mijn regio om klimaatuitdagingen aan te pakken.",
            "pl": "W moim regionie istnieje <u>kultura współpracy między organizacjami</u>, aby stawić czoła wyzwaniom klimatycznym.",
            "sw": "Det finns en <u>samarbetskultur mellan organisationer</u> i min region för att hantera klimatutmaningar.",
            "ua": "У моєму регіоні існує <u>культура співпраці між організаціями</u> для вирішення кліматичних викликів."}
         
        },
        {
          "value": "valuesPromoteEcofriendlyPractices",
          "text": { "en": "The <u>cultural values</u> of the network in my region <u>support actions that promote agricultural and water management</u> practices that protect and enhance local ecosystems.",
            "fi": "<u>Verkoston kulttuuriarvot</u> alueellani tukevat toimia, jotka edistävät <u>maatalous- ja vesienhallintakäytäntöjä</u>, jotka suojelevat ja parantavat paikallisia ekosysteemejä.",
            "it": "I valori culturali della rete nella mia regione <u>supportano azioni che promuovono pratiche agricole e di gestione delle risorse idriche</u> che proteggono e migliorano gli ecosistemi locali.",
            "es": "Los <u>valores culturales de la red</u> en mi región apoyan las acciones que promueven prácticas agrícolas y de gestión del agua que protegen y mejoran los ecosistemas locales.",
            "nl": "De culturele waarden van het netwerk in <u>mijn regio ondersteunen acties die landbouw- en waterbeheerpraktijken</u> bevorderen en de lokale ecosystemen beschermen en verbeteren.",
            "pl": "<u>Wartości kulturowe sieci</u> w moim regionie wspierają działania promujące praktyki rolnicze i zarządzanie wodą, które chronią i wzmacniają lokalne ekosystemy.",
            "sw": "Nätverkets <u>kulturella värderingar</u> i min region stödjer åtgärder som främjar jordbruks- och vattenförvaltningspraxis som skyddar och förbättrar lokala ekosystem.",
            "ua": "<u>Культурні цінності мережі</u> у моєму регіоні підтримують дії, що сприяють сільськогосподарським та водогосподарським практикам, які захищають і покращують місцеві екосистеми."}
         
        },
        {
          "value": "networkExperimentsWithCollaborationWays",
          "text": { "en": "The network in my region is open to <u>experimenting with new ways of working together</u> (e.g., using new technologies, flexible governance models) to improve its resilience against climate change.",
            "fi": "<u>Alueeni verkosto on avoin kokeilemaan uusia yhteistyötapoja</u> (esim. uusien teknologioiden, joustavien hallintomallien avulla) lisätäkseen resilienssiään ilmastonmuutosta vastaan.",
            "it": "<u>La rete nella mia regione è aperta a sperimentare nuovi modi di collaborare</u> (ad es. l'uso di nuove tecnologie, modelli di governance flessibili) per migliorare la sua resilienza ai cambiamenti climatici.",
            "es": "La red en mi región está abierta a <u>experimentar con nuevas formas de colaboración</u> (por ejemplo, utilizando nuevas tecnologías, modelos de gobernanza flexibles) para mejorar su resiliencia frente al cambio climático.",
            "nl": "Het netwerk in mijn regio staat open voor <u>experimenten met nieuwe manieren van samenwerking</u> (bijvoorbeeld door gebruik te maken van nieuwe technologieën en flexibele bestuursmodellen) om de weerbaarheid tegen klimaatverandering te vergroten.",
            "pl": "Sieć w moim regionie jest otwarta na <u>eksperymentowanie z nowymi sposobami współpracy</u> (np. korzystanie z nowych technologii, elastycznych modeli zarządzania), aby zwiększyć swoją odporność na zmiany klimatyczne.",
            "sw": "Nätverket i min region är öppet för att <u>experimentera med nya sätt att samarbeta</u> (t.ex. använda ny teknik, flexibla styrningsmodeller) för att förbättra sin motståndskraft mot klimatförändringar.",
            "ua": "Мережа у моєму регіоні відкрита до <u>експериментів із новими способами співпраці</u> (наприклад, використання нових технологій, гнучких моделей управління), щоб підвищити свою стійкість до змін клімату."}
         
        }
      ],
      "alternateRows": true,
      "isAllRowRequired": true,
      "isRequired": true
    }]
  }, {
    name: "thankyou",
    title: "",
    elements: [{
      "name": "email",
      "title": { "en": "Thank you for your valuable collaboration. The information you have provided will contribute to a detailed visualization of the stakeholders and actors network in your territory (see preview in 'Network'). If you would like to receive a copy of this visualization once it has been processed, please feel free to share your email address with us. We will send it to you as soon as we have it.",
        "fi": "Kiitos arvokkaasta yhteistyöstä. Antamasi tiedot auttavat luomaan yksityiskohtaista visualisointia alueesi sidosryhmien ja toimijoiden verkostosta. Jos haluat saada kopion tästä visualisoinnista, kun se on käsitelty, voit jakaa sähköpostiosoitteesi kanssamme. Lähetämme sen sinulle heti, kun saamme sen.",
        "it": "Grazie per la vostra preziosa collaborazione. Le informazioni che ci avete fornito contribuiranno a creare una visualizzazione dettagliata della rete di attori e stakeholder del vostro territorio. Se desiderate ricevere una copia di questa visualizzazione una volta elaborata, non esitate a condividere il vostro indirizzo e-mail con noi. Ve la invieremo non appena sarà disponibile.",
        "es": "Gracias por su valiosa colaboración. La información que nos ha proporcionado contribuirá a una visualización detallada de la red de partes interesadas y actores en su territorio. Si desea recibir una copia de esta visualización una vez procesada, no dude en compartir con nosotros su dirección de correo electrónico. Se la enviaremos en cuanto la tengamos.",
        "nl": "Bedankt voor uw waardevolle medewerking. De informatie die u hebt verstrekt, draagt bij aan een gedetailleerde visualisatie van het netwerk van belanghebbenden en actoren in uw gebied. Als u een kopie van deze visualisatie wilt ontvangen nadat deze is verwerkt, kunt u uw e-mailadres met ons delen. We sturen het u zodra we het hebben.",
        "pl": "Dziękujemy za cenną współpracę. Podane przez Ciebie informacje przyczynią się do szczegółowej wizualizacji sieci interesariuszy i aktorów na Twoim terytorium. Jeśli chcesz otrzymać kopię tej wizualizacji po jej przetworzeniu, możesz nam podać swój adres e-mail. Wyślemy ją do Ciebie, gdy tylko ją otrzymamy.",
        "sw": "Tack för ditt värdefulla samarbete. Informationen du har tillhandahållit kommer att bidra till en detaljerad visualisering av nätverket av intressenter och aktörer i ditt territorium. Om du vill få en kopia av denna visualisering när den har bearbetats får du gärna dela din e-postadress med oss. Vi skickar den till dig så snart vi har den.",
        "ua": "Дякуємо за вашу цінну співпрацю.  Надана вами інформація сприятиме детальній візуалізації мережі зацікавлених сторін і акторів на вашій території. Якщо ви хочете отримати копію цієї візуалізації після її обробки, будь ласка, надішліть нам свою електронну адресу. Ми надішлемо його вам, щойно отримаємо."}
     ,
      "type": "text",
      "maxLength": 300,
      "isRequired": false
    }], 
  }],
  showQuestionNumbers: "off",
  fitToContainer: "true"
};